import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter,Route } from 'react-router-dom';
import './App.css';
import store from './redux/store';
import AppRoutes from './routes/AppRoutes';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
  <Provider store={store}>
  <div id="mainCont">
    <BrowserRouter>
    <AppRoutes />
    </BrowserRouter>
  </div>
  </Provider>
);

export default App;
