/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */

export const UPLOAD_INVOICE = 'UPLOAD_INVOICE'
export const ANALYSING_INVOICE = 'ANALYZING_INVOICE'
export const ANALYSING_COMPLETED_SUCCESS = 'ANALYZING_COMPLETED_SUCCESS'
export const ANALYSING_COMPLETED_FAILURE = 'ANALYZING_COMPLETED_FAILURE'
export const CHANGE_MODEL_TYPE = 'CHANGE_MODEL_TYPE'
export const CHANGE_FIELD_VALUE = 'CHANGE_FIELD_VALUE'
export const APPROVE_INVOICE = 'APPROVE_INVOICE'
export const APPROVE_PUSH = 'APPROVE_PUSH'
export const FETCH_INVOICE_REQUEST='FETCH_INVOICE_REQUEST'
export const FETCH_INVOICE_SUCCESS='FETCH_INVOICE_SUCCESS'
export const FETCH_XLS_SUCCESS='FETCH_XLS_SUCCESS'
export const ALLOCATE_SUCCESS='ALLOCATE_SUCCESS'
export const ALLOCATE_COUNT_SUCCESS='ALLOCATE_COUNT_SUCCESS'
export const ALLOCATE_FAILURE='ALLOCATE_FAILURE'
export const FETCH_INVOICE_FAILURE='FETCH_INVOICE_FAILURE'
export const FETCH_XLS_FAILURE='FETCH_XLS_FAILURE'
export const DOWNLOAD_FORMATE_TYPE='DOWNLOAD_FORMATE_TYPE'
export const FETCH_SINGLE_INVOICE_REQUEST='FETCH_SINGLE_INVOICE_REQUEST'
export const FETCH_SINGLE_INVOICE_SUCCESS='FETCH_SINGLE_INVOICE_SUCCESS'
export const FETCH_SINGLE_INVOICE_FAILURE='FETCH_SINGLE_INVOICE_FAILURE'
export const UPDATE_USER='UPDATE_USER'
export const FETCH_APPROVED_INVOICE='FETCH_APPROVED_INVOICE'
export const FETCH_UNAPPROVED_INVOICE='FETCH_UNAPPROVED_INVOICE'
export const FETCH_PUSHED_INVOICE='FETCH_PUSHED_INVOICE'
export const REMOVE_INVOICE = 'REMOVE_INVOICE';
export const PUSH_INVOICE = 'PUSH_INVOICE';
export const FETCH_INVOICE_COUNT='FETCH_INVOICE_COUNT';
export const BULK_PUSH_INVOICE_SUCCESS='BULK_PUSH_INVOICE_SUCCESS';
export const BULK_APPROVE_INVOICE_SUCCESS='BULK_APPROVE_INVOICE_SUCCESS';
export const APPROVE_INVOICE_COUNT_SUCCESS='APPROVE_INVOICE_COUNT_SUCCESS';
export const UNAPPROVE_INVOICE_COUNT_SUCCESS='UNAPPROVE_INVOICE_COUNT_SUCCESS';
export const APPROVE_INVOICE_COUNT_FAILURE='APPROVE_INVOICE_COUNT_FAILURE';
export const EMAIL_INVOICE_SUCCESS="EMAIL_INVOICE_SUCCESS";
export const EMAIL_INVOICE_FAILURE="EMAIL_INVOICE_FAILURE";
export const EMAIL_INVOICE_COUNT_SUCCESS="EMAIL_INVOICE_COUNT_SUCCESS"
export const EMAIL_INVOICE_COUNT_FAILURE="EMAIL_INVOICE_COUNT_FAILURE"
export const FILE_DISPLAY_WITH_ID_FAILURE="FILE_DISPLAY_WITH_ID_FAILURE"
export const FILE_DISPLAY_WITH_ID_SUCCESS="FILE_DISPLAY_WITH_ID_SUCCESS"
export const SPLIT_INVOICE="SPLIT_INVOICE"
export const UPLOADING_COMPLETED_SUCCESS="UPLOADING_COMPLETED_SUCCESS"
export const UPLOADING_COMPLETED_FAILURE="UPLOADING_COMPLETED_FAILURE"
export const LOCAL_INVOICE_COUNT_SUCCESS="LOCAL_INVOICE_COUNT_SUCCESS"
export const LOCAL_INVOICE_COUNT_FAILURE="LOCAL_INVOICE_COUNT_FAILURE"
export const LOCAL_INVOICE_SUCCESS="LOCAL_INVOICE_SUCCESS"
export const LOCAL_INVOICE_FAILURE="LOCAL_INVOICE_FAILURE"
export const LOCAL_FILE_DISPLAY_WITH_ID_FAILURE="LOCAL_FILE_DISPLAY_WITH_ID_FAILURE"
export const LOCAL_FILE_DISPLAY_WITH_ID_SUCCESS="LOCAL_FILE_DISPLAY_WITH_ID_SUCCESS"
export const DISAPPROVE_INVOICE="DISAPPROVE_INVOICE"
export const FETCH_INVOICE_REPORTS_SUCCESS="FETCH_INVOICE_REPORTS_SUCCESS"
export const FETCH_INVOICE_REPORTS_FAILURE="FETCH_INVOICE_REPORTS_FAILURE"
export const FETCH_USER_DETAILS_SUCCESS="FETCH_USER_DETAILS_SUCCESS"
export const FETCH_USER_DETAILS_FAILURE="FETCH_USER_DETAILS_FAILURE"
export const REMOVE_INVOICE_FROM_SPLIT="REMOVE_INVOICE_FROM_SPLIT"
export const UPLOAD_FETCH_SUCCESS="UPLOAD_FETCH_SUCCESS"


