/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */

import {
  CHANGE_FIELD_VALUE,
  ANALYSING_COMPLETED_SUCCESS,
  CHANGE_MODEL_TYPE,
  ANALYSING_COMPLETED_FAILURE,
  UPLOAD_INVOICE,
  ANALYSING_INVOICE,
  APPROVE_INVOICE,
  APPROVE_PUSH,
  FETCH_INVOICE_REQUEST,
  FETCH_XLS_SUCCESS,
  ALLOCATE_SUCCESS,
  ALLOCATE_COUNT_SUCCESS,
  UPLOAD_FETCH_SUCCESS,
  ALLOCATE_FAILURE,
  FETCH_INVOICE_SUCCESS,
  FETCH_INVOICE_FAILURE,
  FETCH_XLS_FAILURE,
  FETCH_SINGLE_INVOICE_REQUEST,
  FETCH_SINGLE_INVOICE_SUCCESS,
  FETCH_SINGLE_INVOICE_FAILURE,
  UPDATE_USER,
  FETCH_APPROVED_INVOICE,
  FETCH_UNAPPROVED_INVOICE,
  FETCH_PUSHED_INVOICE,
  REMOVE_INVOICE,
  PUSH_INVOICE,
  FETCH_INVOICE_COUNT,
  BULK_PUSH_INVOICE_SUCCESS,
  BULK_APPROVE_INVOICE_SUCCESS,
  APPROVE_INVOICE_COUNT_SUCCESS,
  UNAPPROVE_INVOICE_COUNT_SUCCESS,
  APPROVE_INVOICE_COUNT_FAILURE,
  EMAIL_INVOICE_SUCCESS,
  EMAIL_INVOICE_FAILURE,
  EMAIL_INVOICE_COUNT_SUCCESS,
  EMAIL_INVOICE_COUNT_FAILURE,
  FILE_DISPLAY_WITH_ID_SUCCESS,
  FILE_DISPLAY_WITH_ID_FAILURE,
  LOCAL_FILE_DISPLAY_WITH_ID_SUCCESS,
  LOCAL_FILE_DISPLAY_WITH_ID_FAILURE,
  SPLIT_INVOICE,
  UPLOADING_COMPLETED_SUCCESS,
  UPLOADING_COMPLETED_FAILURE,
  LOCAL_INVOICE_COUNT_SUCCESS,
  LOCAL_INVOICE_COUNT_FAILURE,
  LOCAL_INVOICE_SUCCESS,
  LOCAL_INVOICE_FAILURE,
  DISAPPROVE_INVOICE,
  FETCH_INVOICE_REPORTS_SUCCESS,
  FETCH_INVOICE_REPORTS_FAILURE,
  REMOVE_INVOICE_FROM_SPLIT,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  DOWNLOAD_FORMATE_TYPE
} from "./invoiceTypes";
import axios from "axios";
export const DownloadFormate = (Formate) => {
  return {
    type: DOWNLOAD_FORMATE_TYPE,
    payload: Formate,
  };
};
export const upload_invoice = (file, fileUrl, fType) => {
  return {
    type: UPLOAD_INVOICE,
    file: file,
    fileUrl: fileUrl,
    fType: fType,
  };
};

export const analyzing_invoice = () => {
  return {
    type: ANALYSING_INVOICE,
  };
};

export const analyzing_complete_success = (data) => {
  return {
    type: ANALYSING_COMPLETED_SUCCESS,
    payload: data,
  };
};

export const analyzing_complete_failure = (error) => {
  return {
    type: ANALYSING_COMPLETED_FAILURE,
    payload: error,
  };
};

export const change_model_type = (modelType) => {
  return {
    type: CHANGE_MODEL_TYPE,
    payload: modelType,
  };
};

export const change_field_value = (newData) => {
  return {
    type: CHANGE_FIELD_VALUE,
    payload: newData,
  };
};

export const approve_invoice = (is_approved) => {
  return {
    type: APPROVE_INVOICE,
    payload:is_approved
  };
};

export const push_invoice = is_pushed => {
	return {
		type: APPROVE_PUSH,
		payload: is_pushed,
	};
};

export const fetchinvoicerequest=()=>{
return {
  type:FETCH_INVOICE_REQUEST
}
}

export const fetchinvoicesuccess=(invoice) =>{
  return {
    type:FETCH_INVOICE_SUCCESS,
    payload:invoice
  }
}
export const fetchxlssuccess=(invoice) =>{
  return {
    type:FETCH_XLS_SUCCESS,
    payload:invoice
  }
}
export const allocatesuccess=(Allocate) =>{
  return {
    type:ALLOCATE_SUCCESS,
    payload:Allocate
  }
}
export const allocatecountsuccess=(AllocateCount) =>{
  return {
    type:ALLOCATE_COUNT_SUCCESS,
    payload:AllocateCount
  }
}
export const uplodedSuccess=(files) =>{
  return {
    type:UPLOAD_FETCH_SUCCESS,
    payload:files
  }
}


export const fetchinvoicecount=(count) =>{
  return {
    type:FETCH_INVOICE_COUNT,
    payload:count
  }
}
export const allocatefailure=(count) =>{
  return {
    type:ALLOCATE_FAILURE,
    payload:count
  }
}
export const fetchinvoicefailure=(error) =>{
  return {
    type:FETCH_INVOICE_FAILURE,
    payload:error
  }
}
export const fetchxlsfailure=(error) =>{
  return {
    type:FETCH_XLS_FAILURE,
    payload:error
  }
}
export const fetchfilefailure=(error) =>{
  return {
    type:FETCH_XLS_FAILURE,
    payload:error
  }
}
export const fetchsingleinvoicefailure=(error) =>{
  return {
    type:FETCH_SINGLE_INVOICE_FAILURE,
    payload:error
  }
}
export const fetchsingleinvoicerequest=()=>{
  return {
    type:FETCH_SINGLE_INVOICE_REQUEST
  }
  }
  
export const fetchsingleinvoicesuccess=(invdata) =>{
  return {
    type:FETCH_SINGLE_INVOICE_SUCCESS,
    payload:invdata
  }
}

export const userUpdated=(data) =>{
  return {
    type:UPDATE_USER,
    payload:data
    
  }
}

export const delete_invoice_success = (delData) => {
  return {
      type: REMOVE_INVOICE,
      payload: delData
  }
}

export const push_invoice_success = (PushData) => {
  return {
      type:PUSH_INVOICE,
      payload: PushData
  }
}



export const fetchApprovedinvoice=(approvedinvoice) =>{
  return {
    type:FETCH_APPROVED_INVOICE,
    payload:approvedinvoice
  }
}

export const fetchUnApprovedinvoice=(unapprovedinvoice) =>{
  return {
    type:FETCH_UNAPPROVED_INVOICE,
    payload:unapprovedinvoice
  }
}

export const fetchPushedinvoice=(pushedinvoice) =>{
  return {
    type:FETCH_PUSHED_INVOICE,
    payload:pushedinvoice
  }
}

export const Bulk_Push_invoice_success=(selectedIds) =>{
  return {
    type:BULK_PUSH_INVOICE_SUCCESS,
    payload:selectedIds
  }
}

export const Bulk_approve_invoice_success=(approvalarray)=>{
  return {
    type:BULK_APPROVE_INVOICE_SUCCESS,
    payload:approvalarray
  }
}

export const Approvedinvoicecount=(count)=>{
  return {
    type:APPROVE_INVOICE_COUNT_SUCCESS,
    payload:count
  }
}

export const UnApprovedinvoicecount=(count)=>{
  return {
    type:UNAPPROVE_INVOICE_COUNT_SUCCESS,
    payload:count
  }
}

export const Approvedinvoicecountfailure=(error) =>{
  return {
    type:APPROVE_INVOICE_COUNT_FAILURE,
    payload:error
  }
} 

export const Email_Invoice_Success=(EInvoice) =>{
  return {
    type:EMAIL_INVOICE_SUCCESS,
    payload:EInvoice
  }
} 

export const Email_Invoice_Failure=(error) =>{
  return {
    type:EMAIL_INVOICE_FAILURE,
    payload:error
  }
} 

export const Local_Invoice_Success=(LInvoice) =>{
  return {
    type:LOCAL_INVOICE_SUCCESS,
    payload:LInvoice
  }
} 

export const Local_Invoice_Failure=(error) =>{
  return {
    type:LOCAL_INVOICE_FAILURE,
    payload:error
  }
} 

export const localinvoicecountsuccess=(LInvoice) =>{
  return {
    type:LOCAL_INVOICE_COUNT_SUCCESS,
    payload:LInvoice
  }
} 

export const localinvoicecountfailure=(error) =>{
  return {
    type:LOCAL_INVOICE_COUNT_FAILURE,
    payload:error
  }
} 

export const emailinvoicecountsuccess=(EInvoicecount) =>{
  return {
    type:EMAIL_INVOICE_COUNT_SUCCESS,
    payload:EInvoicecount
  }
} 

export const emailinvoicecountfailure=(error) =>{
  return {
    type:EMAIL_INVOICE_COUNT_FAILURE,
    payload:error
  }
} 

export const filedisplaywithid=(filedata) =>{
  return {
    type:FILE_DISPLAY_WITH_ID_SUCCESS,
    payload:filedata
  }
} 

export const filedisplaywithidfailure=(error) =>{
  return {
    type:FILE_DISPLAY_WITH_ID_FAILURE,
    payload:error
  }
} 

export const localfiledisplaywithid=(file) =>{
  return {
    type:LOCAL_FILE_DISPLAY_WITH_ID_SUCCESS,
    payload:file
  }
} 

export const localfiledisplaywithidfailure=(error) =>{
  return {
    type:LOCAL_FILE_DISPLAY_WITH_ID_FAILURE,
    payload:error
  }
} 

export const Split_invoice = (is_splitted) => {
  return {
    type: SPLIT_INVOICE,
    payload:is_splitted
  };
};

export const uploading_complete_success=(data)=>{
  return {
    type: UPLOADING_COMPLETED_SUCCESS,
    payload: data,
  };
}

export const uploading_complete_failure=(err)=>{
  return {
    type: UPLOADING_COMPLETED_FAILURE,
    payload: err,
  };
}
export const Disapprove_invoice = (is_approved) => {
  return {
    type: DISAPPROVE_INVOICE,
    payload:is_approved
  };
};

export const fetchReportsSuccess=(reports) =>{
  return {
    type:FETCH_INVOICE_REPORTS_SUCCESS,
    payload:reports
  }
}

export const fetchReportsfailure=(error) =>{
  return {
    type:FETCH_INVOICE_REPORTS_FAILURE,
    payload:error
  }
}

export const fetchUserDetailSuccess=(userdetails) =>{
  return {
    type:FETCH_USER_DETAILS_SUCCESS,
    payload :userdetails
  }
}

export const fetchUserDetailfailure=(error) =>{
  return {
    type:FETCH_USER_DETAILS_FAILURE,
    payload:error
  }
}
export const delete_split_invoice_success=(delData)=>{
  return {
    type: REMOVE_INVOICE_FROM_SPLIT,
    payload: delData
}
}
export const sendForAnalyze = (fileName, file, fileUrl, token, isFromSplit=false, splitRecordId) => {
  return (dispatch) => {
    dispatch(analyzing_invoice);
    let formData = new FormData();
    formData.append("file", file, fileUrl);
    formData.append("name", file.name.split(".")[0]);
    formData.append("ext", file.name.split(".")[1]);
    formData.append("isFromSplit", isFromSplit);
    formData.append("splitRecordId", splitRecordId);
    formData.append("fileName", fileName);
    let url =  process.env.REACT_APP_BACKEND_API + "api/analyze/standard";
    if (localStorage.getItem("modelType") === "Enterprise") {
      url = process.env.REACT_APP_BACKEND_API + "api/analyze/enterprise";
    }

    axios   
      .post(url, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res)
        dispatch(analyzing_complete_success(res));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(analyzing_complete_failure(errorMsg));
      });
  };
};

export const sendForAnalyzeSplitPdf = (file, fileUrl, token, isFromSplit=false, splitRecordId, pages) => {
  return (dispatch) => {
    dispatch(analyzing_invoice);
    let formData = new FormData();
    formData.append("file", file, fileUrl);
    formData.append("name", file.name.split(".")[0]);
    formData.append("ext", file.name.split(".")[1]);
    formData.append("isFromSplit", isFromSplit);
    formData.append("splitRecordId", splitRecordId);
    formData.append("pages", pages);
    let url =  process.env.REACT_APP_BACKEND_API + "api/analyze/splitpdfprocess";
    if (localStorage.getItem("modelType") === "Enterprise") {
      url = process.env.REACT_APP_BACKEND_API + "api/analyze/enterprise";
    }

    axios   
      .post(url, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res,'After ANALYZING')
        dispatch(analyzing_complete_success(res));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(analyzing_complete_failure(errorMsg));
      });
  };
};

export const getAutoModelType = (dispatch) => {
  var modelType = localStorage.getItem("modelType");

  if (!modelType) {
    modelType = "Standard";
  }

  dispatch(change_model_type(modelType));
}; 

export const fetchInvoice=(page, limit, vendorName, invoiceNo, startDate, endDate , status)=>{
  const token = window.localStorage.getItem("token");
  return (dispatch)=>{
  //axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/paginate"
  axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/fetchInvoices",{
    params: {
      page: page,
      limit:limit,
      "invoiceId" : invoiceNo,
      "startDate" : startDate,  
      "endDate" : endDate,  
      "vendorName" : vendorName, 
      "status" : status, 
      "isApproved" : null
    }, 
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
  .then(response=>{
    // const invoice=response.data
    console.log(response.data)
    dispatch(fetchinvoicesuccess(response.data))
    dispatch(fetchinvoicecount(response.data.totalRecords))
  })
  .catch(error=>{
    const errorMsg=error.message
    dispatch(fetchinvoicefailure(errorMsg))
  })
}
}

export const fetchsingleInvoice=(id)=>{
  console.log("msg 179",id)
  const token = window.localStorage.getItem("token");
  return(dispatch)=>{
    dispatch(fetchsingleinvoicerequest)
    axios.get( process.env.REACT_APP_BACKEND_API + `api/invoice/invoicedetails/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res=>{
      console.log(res.data)
      dispatch(fetchsingleinvoicesuccess(res.data))
    })
    .catch(error=>{
      const errorMsg=error.message
      dispatch(fetchsingleinvoicefailure(errorMsg))
    })
  }
}

export const updateInvoice=(id,data)=>{
  const token = window.localStorage.getItem("token");
  return (dispatch)=>{
    axios.put(process.env.REACT_APP_BACKEND_API + `api/invoice/update/${id}`,data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res=>{
      console.log(res.data)
      dispatch(userUpdated(res.data))
    })
    .catch(error=>{
      console.log(error)
    })
  }
}


export const updateApproval=(id,data)=>{
  console.log("msg 239",id)
  const token = window.localStorage.getItem("token");
  return (dispatch)=>{
    axios.put( process.env.REACT_APP_BACKEND_API + `api/invoice/updateapproved/${id}`,data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    
    })
    .then(res=>{
      console.log(res.data)
      dispatch(approve_invoice(res.data))
      dispatch(fetchsingleInvoice(id))
    })
    .catch(error=>{
      console.log(error)
    })
  }
}


export const approvedInvoice=(page ,limit, vendorName, invoiceNo, startDate, endDate, status)=>{
  const token = window.localStorage.getItem("token");
  console.log(page,limit)
  return (dispatch)=>{
    //axios.get(  process.env.REACT_APP_BACKEND_API + "api/invoice/approvedinvoice"
    axios.get(  process.env.REACT_APP_BACKEND_API + "api/invoice/fetchInvoices", {
      params: {
        page: page,
        limit:limit,
        "invoiceId" : invoiceNo,
        "startDate" : startDate,  
        "endDate" : endDate,  
        "vendorName" : vendorName,
        "status" : status,  
        "isApproved" : true
      },
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response=>{
      // const invoice=response.data
      console.log(response.data,'Approved Invoice From Actions')
      dispatch(fetchApprovedinvoice(response.data))
      dispatch(Approvedinvoicecount(response.data.totalRecords))
    })
    .catch(error=>{
      const errorMsg=error.message
      dispatch(fetchinvoicefailure(errorMsg))
    })
}
}

export const unapprovedInvoice=(page ,limit, vendorName, invoiceNo, startDate, endDate, status)=>{
  const token = window.localStorage.getItem("token");
  console.log(page,limit)
  return (dispatch)=>{
    //axios.get(  process.env.REACT_APP_BACKEND_API + "api/invoice/approvedinvoice"
    axios.get(  process.env.REACT_APP_BACKEND_API + "api/invoice/fetchInvoices", {
      params: {
        page: page,
        limit:limit,
        "invoiceId" : invoiceNo,
        "startDate" : startDate,  
        "endDate" : endDate,  
        "vendorName" : vendorName,
        "status" : status,  
        "isApproved" : false
      },
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response=>{
      // const invoice=response.data
      console.log(response.data,'Approved Invoice From Actions')
      dispatch(fetchUnApprovedinvoice(response.data))
      dispatch(UnApprovedinvoicecount(response.data.totalRecords))
    })
    .catch(error=>{
      const errorMsg=error.message
      dispatch(fetchinvoicefailure(errorMsg))
    })
}
}


//To set is_pushed to true
export const updatePush = (id, data) => {
	console.log('msg 239', id);
	const token = window.localStorage.getItem('token');
	return dispatch => {
		axios
			.put(
				process.env.REACT_APP_BACKEND_API + `api/invoice/updatepushed/${id}`,
				data,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(res => {
				console.log(res.data);
				dispatch(push_invoice(res.data));
				dispatch(fetchsingleInvoice(id));
			})
			.catch(error => {
				console.log(error);
			});
	};
};


export const deleteInvoice=(selectedarray,page,limit)=>{
  console.log(selectedarray)
  const token = window.localStorage.getItem("token");
return (dispatch)=>{
  axios.post(process.env.REACT_APP_BACKEND_API + "api/invoice/all/bulkdelete",selectedarray, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data : selectedarray
  })
  .then(response=>{
    // const invoice=response.data
    console.log(response.data)
    dispatch(delete_invoice_success(response.data))
    dispatch(fetchInvoice(page,limit))
  })
  .catch(error=>{
    console.log(error)
  })
}
}

export const PushInvoice=(id,pushdata)=>{
  const token = window.localStorage.getItem("token");
  return (dispatch)=>{
    axios.post(process.env.REACT_APP_BACKEND_API + `api/invoice/pushInvoice/${id}`,pushdata, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response=>{
      // const invoice=response.data
      console.log(response.data)
      dispatch(push_invoice_success(response.data))
    })
    .catch(error=>{
      const errorMsg=error.message
      dispatch(fetchinvoicefailure(errorMsg))
    })
  }
  }
// export const InvoiceCount=()=>{
//   const token = window.localStorage.getItem("token");
// return (dispatch)=>{
//   axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/company/"
//   // axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/fetchInvoices"
//   ,{
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   })
//   .then(response=>{
//     // const invoice=response.data
//     console.log(response.data,'Total All Invoice Records')
//     dispatch(fetchinvoicecount(response.data))
//   })
//   .catch(error=>{
//     const errorMsg=error.message
//     dispatch(fetchinvoicefailure(errorMsg))
//   })
// }

// }

export const pushedInvoice=(page,limit)=>{
  const token = window.localStorage.getItem("token");
  console.log(page,limit)
return (dispatch)=>{
  axios.get(  process.env.REACT_APP_BACKEND_API + "api/invoice/pushedinvoice", {
    params: {
      page: page,
      limit:limit,
    }, 
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  .then(response=>{
    // const invoice=response.data
    console.log(response.data)
    dispatch(fetchPushedinvoice(response.data))
  })
  .catch(error=>{
    const errorMsg=error.message
    dispatch(fetchinvoicefailure(errorMsg))
  })
}
}

export const BulkUpdatePushFinal=(selectarray,page,limit)=>{
  console.log(selectarray)
  const token = window.localStorage.getItem("token");
return (dispatch)=>{
  axios.put(process.env.REACT_APP_BACKEND_API + "api/invoice/all/bulkpushfinal",selectarray, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data : selectarray
  })
  .then(response=>{
    // const invoice=response.data
    console.log(response.data)
    dispatch(Bulk_Push_invoice_success(response.data))
    dispatch(fetchInvoice(page,limit))
    dispatch(pushedInvoice())
  })
  .catch(error=>{
    console.log(error)
  })
}
}

export const BulkPushInvoice=(selectarray,page,limit)=>{
  console.log(selectarray,'1111')
  const token = window.localStorage.getItem("token");
return (dispatch)=>{
  axios.post(process.env.REACT_APP_BACKEND_API + "api/invoice/all/bulkpushInvoice",selectarray, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data : selectarray
  })
  .then(response=>{
    // const invoice=response.data
    console.log(response.data,'Bulk Push Data')
    dispatch(Bulk_Push_invoice_success(response.data))
    dispatch(fetchInvoice(page,limit))
    //dispatch(pushedInvoice())
  })
  .catch(error=>{
    console.log(error)
  })
}  
}



export const BulkUpdateApproval=(approvearray,page,limit)=>{
  console.log(approvearray)
  const token = window.localStorage.getItem("token");
return (dispatch)=>{
  axios.put(process.env.REACT_APP_BACKEND_API + "api/invoice/all/bulkapproval",approvearray, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data : approvearray
  })
  .then(response=>{
    // const invoice=response.data
    console.log(response.data)
    dispatch(Bulk_approve_invoice_success(response.data))
    dispatch(fetchInvoice(page,limit))
    dispatch(approvedInvoice())
  })
  .catch(error=>{
    console.log(error)
  })
}
} 

// export const approvedinvoicecount=()=>{
//   const token = window.localStorage.getItem("token");
//   return (dispatch)=>{
//     axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/approvedcount/"
//     // axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/fetchInvoices"
//     ,{
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       // body:{
//       //   "invoiceId" : null,
//       //   "startDate" : null,  
//       //   "endDate" : null,  
//       //   "vendorName" : null,  
//       //   "isApproved" : true
//       // }
//     })
//     .then(response=>{
//       // const invoice=response.data
//       console.log(response.data)
//       dispatch(Approvedinvoicecount(response.data))
//     })
//     .catch(error=>{
//       const errorMsg=error.message
//       dispatch(Approvedinvoicecountfailure(errorMsg))
//     })
//   }
// }



export const allEmailInvoice=(page, limit, startDate, endDate , status)=>{
  const token = window.localStorage.getItem("token");
  console.log(page,limit)
return (dispatch)=>{
  //axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/emailinvoice/", {
  axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/fetchemailinvoice/", {
    params: {
      page: page,
      limit:limit,
      "startDate" : startDate,  
      "endDate" : endDate,
      "status":status,
    }, 
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  .then(response=>{
    // const invoice=response.data
    console.log(response.data)
    dispatch(Email_Invoice_Success(response.data.data))
    dispatch(emailinvoicecountsuccess(response.data.totalRecords))
  })
  .catch(error=>{
    const errorMsg=error.message
    dispatch(Email_Invoice_Failure(errorMsg))
  })
}
}

export const emailinvoicecount=()=>{
  const token = window.localStorage.getItem("token");
  return (dispatch)=>{
    axios.get( process.env.REACT_APP_BACKEND_API + "api/invoice/emailinvoicecount/",{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response=>{
      // const invoice=response.data
      console.log(response.data)
      dispatch(emailinvoicecountsuccess(response.data))
    })
    .catch(error=>{
      const errorMsg=error.message
      dispatch(emailinvoicecountfailure(errorMsg))
    })
  }
}

export const singleIdFileDisplay=(id)=>{
  console.log("msg 179",id)
  const token = window.localStorage.getItem("token");
  return(dispatch)=>{
    axios.get( process.env.REACT_APP_BACKEND_API + `api/invoice/splitfile/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res=>{
      console.log(res.data)
      dispatch(filedisplaywithid(res.data))
    })
    .catch(error=>{
      const errorMsg=error.message
      dispatch(filedisplaywithidfailure(errorMsg))
    })
  }
}

export const singleIdLocalFileDisplay=(id)=>{
  console.log("msg 179",id)
  const token = window.localStorage.getItem("token");
  return(dispatch)=>{
    axios.get(process.env.REACT_APP_BACKEND_API + `api/invoice/splitlocalfile/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res=>{
      console.log(res.data)
      dispatch(localfiledisplaywithid(res.data))
    })
    .catch(error=>{
      const errorMsg=error.message
      dispatch(localfiledisplaywithidfailure(errorMsg))
    })
  }
}


export const updateSplitting=(id,data)=>{
  console.log("msg 239",id)
  const token = window.localStorage.getItem("token");
  return (dispatch)=>{
    axios.put(process.env.REACT_APP_BACKEND_API + `api/invoice/updatesplitted/${id}`,data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    
    })
    .then(res=>{
      console.log(res.data)
      dispatch(Split_invoice(res.data))
    })
    .catch(error=>{
     console.log(error)
    })
  }
}

export const LocalInvoiceCount=()=>{
  const token = window.localStorage.getItem("token");
  return (dispatch)=>{
    axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/localinvoiceCount/",{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response=>{
      // const invoice=response.data
      console.log(response.data)
      dispatch(localinvoicecountsuccess(response.data))
    })
    .catch(error=>{
      const errorMsg=error.message
      dispatch(localinvoicecountfailure(errorMsg))
    })
  }
}

export const allLocalInvoice=(page, limit, startDate, endDate , status)=>{
  const token = window.localStorage.getItem("token");
  console.log(page,limit)
return (dispatch)=>{
  //axios.get( process.env.REACT_APP_BACKEND_API + "api/invoice/localinvoice/", {
  axios.get( process.env.REACT_APP_BACKEND_API + "api/invoice/fetchlocalinvoice/", {
    params: {
      page: page,
      limit:limit,
      "startDate" : startDate,  
      "endDate" : endDate,
      "status":status,
    }, 
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  .then(response=>{
    // const invoice=response.data
    console.log(response.data)
    dispatch(Local_Invoice_Success(response.data.data))
    dispatch(localinvoicecountsuccess(response.data.totalRecords))
  })
  .catch(error=>{
    const errorMsg=error.message
    dispatch(Local_Invoice_Failure(errorMsg))
  })
}
}

export const uploadInvoice = (file, fileUrl, token) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("file", file, fileUrl);
    formData.append("name", file.name.split(".")[0]);
    formData.append("ext", file.name.split(".")[1]);
    let url = process.env.REACT_APP_BACKEND_API + "api/invoice/fileupload";

    console.log("File Uploading")
    console.log(url);

    axios   
      .post(url, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data)
        dispatch(uploading_complete_success(res));
      })
      .catch((error) => {
        const errorMsg = error.message;
        console.log(errorMsg);
        dispatch(uploading_complete_failure(errorMsg));
      });
  };
};

export const updateDisApproval=(id,data)=>{
  console.log("msg 239",id)
  const token = window.localStorage.getItem("token");
  return (dispatch)=>{
    axios.put(process.env.REACT_APP_BACKEND_API + `api/invoice/disapprove/${id}`,data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    
    })
    .then(res=>{
      console.log(res.data)
      dispatch(Disapprove_invoice(res.data))
      dispatch(fetchsingleInvoice(id))
    })
    .catch(error=>{
     console.log(error)
    })
  }
}

export const fetchReportsInvoice=(startDate,endDate)=>{
  const token = window.localStorage.getItem("token");
return (dispatch)=>{
  axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/reports/",{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  .then(response=>{
    // const invoice=response.data
    console.log(response.data)
    dispatch(fetchReportsSuccess(response.data))
  })
  .catch(error=>{
    const errorMsg=error.message
    dispatch(fetchReportsfailure(errorMsg))
  })
}
}

export const fetchUserDetails=(startDate,endDate)=>{
  const token = window.localStorage.getItem("token");
return (dispatch)=>{
  axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/usersReport",{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  .then(response=>{
    // const invoice=response.data
    dispatch(fetchUserDetailSuccess(response.data))
  })
  .catch(error=>{
    const errorMsg=error.message
    dispatch(fetchUserDetailfailure(errorMsg))
  })
}
}

export const deleteInvoiceForSplit=(selectedarray,page,limit)=>{
  console.log(selectedarray)
  const token = window.localStorage.getItem("token");
return (dispatch)=>{
  axios.post(process.env.REACT_APP_BACKEND_API + "api/invoice/deletefromsplit",selectedarray, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data : selectedarray
  })
  .then(response=>{
    // const invoice=response.data
    console.log(response.data)
    dispatch(delete_split_invoice_success(response.data))
    dispatch(allLocalInvoice(page,limit))
    dispatch(allEmailInvoice(page,limit))
  })
  .catch(error=>{
    console.log(error)
  })
}
}

;

export const xlsDownload=(startDate, endDate , companyName , status)=>{
  const token = window.localStorage.getItem("token");
  return (dispatch)=>{
  axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/xlsDownload",{
    params: {
      "companyName": companyName,
      "startDate" : startDate,  
      "endDate" : endDate,
      "status" : status
    }, 
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
  .then(response=>{
    console.log(response.data)
    dispatch(fetchxlssuccess(response.data))
  })
  .catch(error=>{
    const errorMsg=error.message
    dispatch(fetchxlsfailure(errorMsg))
  })
}
}

export const Allocate =(requestCount, reviewerName , reviewer)=>{
  const token = window.localStorage.getItem("token");
  return (dispatch)=>{
  axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/Allocate",{
    params: {
      "requestCount" : requestCount,  
      "reviewerName" : reviewerName,  
      "reviewer": reviewer
    }, 
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
  .then(response=>{
    dispatch(allocatesuccess(response.data))
  })
  .catch(error=>{
    const errorMsg=error.message
    dispatch(fetchxlsfailure(errorMsg))
  })
}
}
export const AllocateCount =(userNames , userIds)=>{
  const token = window.localStorage.getItem("token");
  return (dispatch)=>{
  axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/Allocatecount",{
    params:{userNames , userIds}, 
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
  .then(response=>{
    dispatch(allocatecountsuccess(response.data))
  })
  .catch(error=>{
    const errorMsg=error.message
    dispatch(fetchxlsfailure(errorMsg))
  })
}
}

export const UploadedFile =(page, limit)=>{
  const token = window.localStorage.getItem("token");
  return (dispatch)=>{
  axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/uploaded",{
    params: {
      page: page,
      limit:limit,
    }, 
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
  .then(response=>{
    dispatch(uplodedSuccess(response.data))

  })
  .catch(error=>{
    const errorMsg=error.message
    dispatch(fetchfilefailure(errorMsg))
  })
}
}