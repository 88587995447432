/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */

import React, { Component } from "react";
import { Button } from "@material-ui/core";
import {connect} from 'react-redux'
import {sendForAnalyze, upload_invoice} from '../../redux'
import "./DocUploadBtn.css";
import { useHistory } from "react-router-dom";


class UploadDoc extends Component {

  fileHandler = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.props.upload(reader.result, e.target.files[0], e.target.files[0].name.split(".")[1]);
        this.props.sentToAnalyze(e.target.files[0], reader.result, this.props.state.user.token);    
      }
    };
  };

  render() {
    return (
      <div>
          <Button id="btnUpload" variant="contained" component="label">
            Upload New Document
            <input
              type="file"
              name="file-upload"
              id="input"
              onChange={this.fileHandler}
              hidden
            />
          </Button>
        </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    sentToAnalyze : (file, fileUrl, token) => dispatch(sendForAnalyze(file, fileUrl, token)),
    upload : (file, fileUrl, fType) => dispatch(upload_invoice(file, fileUrl, fType))
  }
}

const mapStateToProps = state => {
  return {
    state : state
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadDoc)