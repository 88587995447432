/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */

import React, { useEffect } from "react";
import logo from "../../../assets/SigixtractLogo.png";
import "./NavBar.css";
import { Button } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { user_logout } from "../../../redux/user/userAuthActions";
import { useHistory, Redirect } from "react-router";
import { sendForAnalyze, upload_invoice } from "../../../redux";
import HeroSection from "../../../modules/HomeModules/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Menu from "@mui/material/Menu";
import { Link } from "react-router-dom";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DescriptionIcon from '@mui/icons-material/Description';

function NavBar(props) {
  console.log(props);
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [role, setRole] = React.useState("");
  const [hideHome, sethideHome] = React.useState(false);
  const companyName = localStorage.getItem("companyName");

  let headerButtons = <></>;

  

  const fileHandler = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    let fileName = e.target.files[0].name
    reader.onload = () => {
      if (reader.readyState === 2) {
        dispatch(
          upload_invoice(
            reader.result,
            e.target.files[0],
            e.target.files[0].name.split(".")[1]
          )
        );
        dispatch(
          sendForAnalyze(
            fileName,
            e.target.files[0],
            reader.result,
            props.state.user.token
          )
        );
      }
    };
    history.push("/");
  };
  useEffect(() => {
    setRole(localStorage.getItem("UserRole"));
  }, []);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    sethideHome(true);
  };

  console.log(role);
  const refreshClick = () => {
    window.location.reload(true);
  };
  const logoutClick = (e) => {
    dispatch(user_logout(history));
  };

  const homeClick = () => {
    history.push("/");
    sethideHome(false);
  };
  if (props.state.user.token) {
    headerButtons = (
      <Button
        id="logout"
        onClick={logoutClick}
        variant="outlined"
        color="secondary"
        className="allButtons"
      >
        Logout
      </Button>
    );
  }

  if (props.state.user.token) {
    headerButtons = (
      <div className="allButtons">
        {role === "Admin" ? (
          <Button
            id="settingsIcon"
            variant="text"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <SettingsIcon />
          </Button>
        ) : null}

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <DropdownItem onClick={handleClose}>
            <Link
              to="/usermanagement"
              style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}
            >
             <AdminPanelSettingsIcon sx={{ fontSize: "20px" }} />User Management
            </Link>
          </DropdownItem>
          {/* <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
        <DropdownItem onClick={handleClose}>
            <Link
              to="/Settings"
              style={{
                textDecoration: "none",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              <ManageAccountsIcon sx={{ fontSize: "20px" }} /> Settings
            </Link>
          </DropdownItem>
          {companyName == "TestDevelopers" || companyName === "CEAT" ? <DropdownItem onClick={handleClose}>
            <Link
              to="/AllocateInvoice"
              style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}
            >
             <DescriptionIcon sx={{ fontSize: "20px" }}/> Allocate Invoice
            </Link>
          </DropdownItem> : null}
        </Menu>
        {(role === "Viewer") || (role === 'Client') || (role === "Reviewer") || (role === "ReviewerW") ? 
          <>            
          </> : 
          <>
          {/* <Button
              id={role === "Admin" ? "uploadHeaderBtn" : "uploadHeaderBtn1"}
              variant="outlined"
              color="primary"
              component="label"
              disabled={role === "Client" ? "disabled" : ""}
            >
              Upload
              <input
                type="file"
                name="file-upl  oad"
                id="input"
                onChange={fileHandler}
                hidden
              />
            </Button>             */}
          </>
        }

        <Button
              id="homeBtn"
              onClick={homeClick}
              variant="outlined"
              color="primary"
              disabled={role === "Viewer" ? "disabled" : ""}
            >
              Home
          </Button>

        <Button
          id="logoutBtn"
          onClick={logoutClick}
          variant="outlined"
          color="secondary"
        >
          Logout
        </Button>
        <Button
          id="refreshBtn"
          onClick={refreshClick}
          variant="outlined"
          color="primary"
        >
          Refresh
        </Button>
      </div>
    );
  }

  const handleHomeClick = () => {
    history.push("/");
    localStorage.removeItem("pageNumAllInvoices");
    localStorage.removeItem("rowsPerPageAllInvoices");
    sethideHome(false);
  };

  return (
    <div className="navContainer">
      <img
        id="imglogo"
        src={logo}
        alt="Sigitek Logo"
        onClick={role !== "Viewer" ? handleHomeClick : null}
      ></img>
      {headerButtons}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (file, fileUrl, fType, state) =>
      dispatch(upload_invoice(file, fileUrl, fType, state)),
    sentToAnalyze: (file, fileUrl, token, state) =>
      dispatch(sendForAnalyze(file, fileUrl, token, state)),
  };
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
