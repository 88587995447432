/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */
 
import React, { Component } from "react";
import { connect } from "react-redux";
import Signin from "../../components/SignIn/signIn";
import {useLocation} from 'react-router-dom';

function Login(props) { 
  let location = useLocation();
  const { from } = localStorage.getItem("lastPage") || { from: { pathname: "/" } };
  return (<Signin location = {from}></Signin>);
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
