/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */
import { combineReducers } from 'redux'
import {invoiceReducer} from './invoice/invoiceReducer'
import {userReducer} from './user/userAuthReducer'

const rootReducer = combineReducers({
    invoice : invoiceReducer,
    user : userReducer
})

export default rootReducer