import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import './rowheader.css';
import Tableheader from '../Tableheader/tableheader';

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
      
        color: 'black',
        p: 1,
        m: 1,
        borderRadius: 1,
        textAlign: 'center',
        fontSize: '1rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default function FlexDirection() {
    const handleClick=()=>{
    }
  return (
    <div style={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
        }}
      >
        <Tableheader/>
      </Box>
    </div>
  );
}