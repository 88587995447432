/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */

 import React, {Fragment,useState, useEffect } from "react";
 import { useParams } from "react-router-dom";
 import axios from "axios";
 import PropTypes from "prop-types";
 import { makeStyles } from "@material-ui/core/styles";
 import AppBar from "@material-ui/core/AppBar";
 import Tabs from "@material-ui/core/Tabs";
 import TextField from "@material-ui/core/TextField";
 import Tab from "@material-ui/core/Tab";
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 import Typography from "@material-ui/core/Typography";
 import InputLabel from '@mui/material/InputLabel';
 import FormControl from '@mui/material/FormControl';
 import NativeSelect from '@mui/material/NativeSelect';
 import Box from "@material-ui/core/Box";
 import Table from "../LineItems/LineItems";
 import Table2 from "../LineItems2/LineItems2";
 import Grid from "@material-ui/core/Grid";
 import CustomTables from "../Tables/Tables";
 import "./ResultDisplay.css";
 import { connect, useDispatch } from "react-redux";
 import { change_field_value, fetchsingleInvoice, updateInvoice } from "../../redux";
 import { Button } from "@material-ui/core";
 import CropIcon from '@mui/icons-material/Crop';
 import ScreenCapture from "../ScreenCapture/ScreenCapture";
 // import Mainscreen from '../ScreenCapture/ScreenCapture';
 import Select from '@mui/material/Select';
 import MenuItem from '@mui/material/MenuItem';
 import CheckCircleIcon from '@mui/icons-material/CheckCircle';
 import CancelIcon from '@mui/icons-material/Cancel';

 //get the companyName from localStorage
 //Remove this code later
 const companyName = localStorage.getItem("companyName");
 const isCompanyCEAT = companyName === "CEAT" || companyName === "Ceat";
 const isCompanySaint= companyName === 'SAINT' || companyName === 'SAINT' || companyName === 'BDO' || companyName === 'Bdo'
 console.log("this is the saint filter",isCompanySaint)
 
 function TabPanel(props) {
   const { children, value, index, ...other } = props;
 
   return (
     <div
       role="tabpanel"
       hidden={value !== index}
       id={`scrollable-auto-tabpanel-${index}`}
       aria-labelledby={`scrollable-auto-tab-${index}`}
       {...other}
     >
       {value === index && (
         <Box p={3}>
           <Typography>{children}</Typography>
         </Box>
       )}
     </div>
   );
 }
 
 TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.any.isRequired,
   value: PropTypes.any.isRequired,
 };
 
 function a11yProps(index) {
   return {
     id: `scrollable-auto-tab-${index}`,
     "aria-controls": `scrollable-auto-tabpanel-${index}`,
   };
 }
 
 const useStyles = makeStyles((theme) => ({
   root: {
     flexGrow: 1,
     width: "100%",
     backgroundColor: theme.palette.background.paper,
   },
   myIcon:{
     paddingLeft:"8px",
     order:999
   }
 }));
 
 function LineItems({ state }) {
   console.log(state)
   const classes = useStyles();
   const [value, setValue] = useState(0);
   const [screenCapture,setscreenCapture]=useState("");
   const [customerGSTN,setCustomerGSTN] = useState('');
   const [supplierGSTN,setSupplierGSTN] = useState('');
   const [role, setRole] = React.useState("");
   const [gstn, setGstn] = useState('verify')
   const [CustGstn, setCustGstn] =  useState('verify')


    React.useEffect(() => {
        setRole(localStorage.getItem("UserRole"));
    }, []);
   const dispatch = useDispatch()

   const valueChangeHandler = (e) => {
      var fieldName = e.target.id;
      state.invoice.invdata.invoice.extractedData[fieldName] = e.target.value;

      dispatch(change_field_value(state));
    };
    
    const [supplier, setSupplier] = React.useState('');
    const [customer, setCustomer] = React.useState('');
 
    const handleChangeSupplier = (event) => {
      const fieldName = 'Supplier_GSTN'
      console.log(supplier, 'CustomerSupplier')
      const number = event.target.value
      setSupplier(number);
      state.invoice.invdata.invoice.extractedData[fieldName] = number;
      dispatch(change_field_value(state));
  };
  const qrvalueChangeHandler = (e) => {
    var fieldName = e.target.id;
    state.invoice.invdata.invoice.extractedData.Package[fieldName] = e.target.value;
    dispatch(change_field_value(state));

  }
 
  const handleChangeCustomer = (event) => {
    const fieldName = 'Customer_GSTN'
    const number = event.target.value
    setCustomer(number);
    state.invoice.invdata.invoice.extractedData[fieldName] = number;
    dispatch(change_field_value(state));
  };
 
   console.log(supplier, customer, 'Supplier / Customer')

    const supplierGSTNChangeHandler = (e) => {
      setSupplier(e.target.value);
      const number = e.target.value
      var fieldName = e.target.id;
      state.invoice.invdata.invoice.extractedData[fieldName] = e.target.value;

      if( number.length < 15 ){
        setSupplierGSTN("GSTN Must be 15 Characters")
      }else if(number.length > 15){
        setSupplierGSTN("GSTN Must be 15 Characters")
      }else{
        setSupplierGSTN("")
      }

      dispatch(change_field_value(state));
    };

    const customerGSTNChangeHandler = (e) => {
      const number = e.target.value
      var fieldName = e.target.id;
      setCustomer(e.target.value);
      state.invoice.invdata.invoice.extractedData[fieldName] = e.target.value;

      
      if( number.length < 15 ){
        setCustomerGSTN("GSTN Must be 15 Characters")
      }else if(number.length > 15){
        setCustomerGSTN("GSTN Must be 15 Characters")
      }else{
        setCustomerGSTN("")
      }

      dispatch(change_field_value(state));
    };

  const handleScreenCapture = (screenCapture) => {
     setscreenCapture(screenCapture)
   }

   
   const handleClickSupplier = async(event) => {
    const token = window.localStorage.getItem("token");

      let data = {
        "gstin" : event
    }
        const req = {
          params: data, 
          headers: {
            Authorization: `Bearer ${token}`,
            }
      }
          await axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/gstn", req).then(response=> {
              if(response.data.status_cd === '1'){
                setGstn(<CheckCircleIcon style={{color:'green'}}/>) 
              }else{
                setGstn(<CancelIcon style={{color:'red'}}/>) 
              }
          })
   }

   const handleClickCust = async(event) => {
    const token = window.localStorage.getItem("token");

      let data = {
        "gstin" : event
    }
        const req = {
          params: data, 
          headers: {
            Authorization: `Bearer ${token}`,
            }
      }
          await axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/gstn", req).then(response=> {
        
              if(response.data.status_cd === '1'){
                setCustGstn(<CheckCircleIcon style={{color:'green'}}/>) 
              }else{
                setCustGstn(<CancelIcon style={{color:'red'}}/>) 
              }
          })
   }
 
   const handleChange = (event, newValue) => {
     setValue(newValue);
   };
 
   const { id } = useParams();
   useEffect(() => {
     fetchsingleInvoice(id)
 
   }, [id])
   const invData = state.invoice
   const data = invData && invData.invdata && invData.invdata.invoice
   console.log(data)
   const companyname=invData && invData.invdata && invData.invdata.companyName
   
   const handleSubmit = (e) => {
     e.preventDefault(); 
     dispatch(updateInvoice(id, data))
   }

   let inv = invData?.invdata?.invoice?.extractedData;
   if (!inv) inv = {}
   //console.log("this is the data for the qr code",inv.Package)
   
   return (
     <div className={classes.root}>
       <AppBar position="static" color="default">
         <Tabs
           value={value}
           onChange={handleChange}
           indicatorColor="primary"
           textColor="primary"
           variant="scrollable"
           scrollButtons="auto"
           aria-label="scrollable auto tabs example"
         >
           <Tab label="General" {...a11yProps(0)} />
         {(companyname === "Dahnay") ?<Tab label="Additional" {...a11yProps(1)}/>: null}
         {(companyname === "Titan") ?<Tab label="Additional" {...a11yProps(1)}/>: null}  
           <Tab label="Supplier" {...a11yProps(2)} />
           <Tab label="Customer" {...a11yProps(3)} />
           <Tab label="Tax" {...a11yProps(4)} />
           <Tab label="Line Items" {...a11yProps(5)} />
         {(companyname === "Titan") ?<Tab label="Pkg List" {...a11yProps(6)}/>: null}  
           {(role !== 'Client') ? <Tab label="Tables" {...a11yProps(7)} /> : null}
           
           {isCompanySaint && <Tab label="QR Code" {...a11yProps(7)} />}           
         </Tabs>
       </AppBar>
       {companyName === "Titan" ? 
        <>
        <TabPanel value={value} index={0}>
        {
          //  const invdate=inv.InvoiceDate
          //  let text=invdate.toString().length
          //  console.log(text)
          //  if(text!==10){
          //    toast.error("update date to dd-mm-yyyy format")
          //  }
          (<><Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField 
              id="VendorName"
              value={inv.VendorName || ""}
              onChange={valueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              // label={ <ScreenCapture>{({ onStartCapture }) => (<Fragment>Supplier Name<CropIcon onClick={onStartCapture} className={classes.myIcon}></CropIcon></Fragment> )}</ScreenCapture>}
              label="Supplier Name"
            /> 
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="InvoiceId"
              onChange={valueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              value={inv.InvoiceId || ""}
              // label={<Fragment>Invoice Number<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="Invoice Number"
            />   
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="InvoiceDate"
              value={inv.InvoiceDate || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              // label={<Fragment>Invoice Date<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="Invoice Date"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="PurchaseOrder"
              value={inv.PurchaseOrder || ""}
              onChange={valueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              // label={<Fragment>PO Ref Number<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="PO Ref Number"
            />
          </Grid>
          {/* <Grid item xs={4}>
            <TextField
              id="InvoiceRefNumber"
              value={inv.InvoiceRefNumber}
              onChange={valueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              // label={<Fragment>PO Ref Number<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="Invoice Ref Number"
            />
          </Grid> */}
          <Grid item xs={4}>
            <TextField
              id="InvoiceTotal"
              value={inv.InvoiceTotal || ""}
              onChange={valueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              // label={<Fragment>Total Amount<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="Total Amount"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="SubTotal"
              value={inv.SubTotal || ""}
              onChange={valueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              // label={<Fragment>Sub Total<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="Sub Total"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="DueDate"
              value={inv.DueDate || ""}
              onChange={valueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              // label={<Fragment>Due Date<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="Due Date"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="GRN_Number"
              value={inv.GRN_Number || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              // label={<Fragment>GRN Number<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="GRN Number"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="SES_Number"
              value={inv.SES_Number || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              // label={<Fragment>SES Number<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="SES Number"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              id="Freight"
              value={inv.Freight || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              label="Freight Amount"
              // label={<Fragment>Freight<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              id="FreightTaxValue"
              value={inv.FreightTaxValue || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              label="Freight Tax"
              // label={<Fragment>Freight<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              id="FreightPercentage"
              value={inv.FreightPercentage || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              label="Freight Percentage"
              // label={<Fragment>Freight<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="Insurance"
              value={inv.Insurance || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              // label={<Fragment>Insurance<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="DCA No."
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="Package"
              value={isCompanySaint ? "" : inv.Package || "" }
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              // label={<Fragment>Package<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label={isCompanyCEAT ? "Barcode" : "Package"}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
          <TextField
              id="InvoiceType"
              value={inv.InvoiceType || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              label="Invoice Type"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
          <TextField
              id="Currency"
              value={inv.Currency || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              label="Currency"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="LoadingCharges"
              value={inv.LoadingCharges || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              // label={<Fragment>Loading Charges<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="Loading Charges"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              id="VendorCode"
              value={inv.VendorCode || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              label="VendorCode"
              // label={<Fragment>Freight<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              id="SACCode"
              value={inv.SACCode || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              label="SAC Code"
              // label={<Fragment>Freight<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              defaultValue=""
            />
          </Grid>
        </Grid>
        </>)   
         }
 
       </TabPanel>
       <TabPanel value={value} index={1}>{
          (
            <>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                    id="TransporterName"
                    value={inv.TransporterName}
                    label="Transporter Name"
                    onChange={valueChangeHandler}
                    disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                  />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                    id="LRNo"
                    value={inv.LRNo}
                    label="LR No"
                    onChange={valueChangeHandler}
                    disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                  />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                    id="LRDate"
                    value={inv.LRDate}
                    label="LR Date"
                    onChange={valueChangeHandler}
                    disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                  />
                  </Grid>
                </Grid>
            </>
          )
        }
        </TabPanel>
       
       <TabPanel value={value} index={2}>
        {
           //invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.data && invData.invdata.invoice.data.map(inv =>
          (<>
            <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <TextField
                            id="VendorName"
                            value={inv.VendorName}
                            label="Supplier Name"
                            onChange={valueChangeHandler}
                            disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                          // disabled={state.invoice.isApproved ? "disabled" : ""}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="VendorAddress"
                            value={inv.VendorAddress}
                            label="Supplier Address"
                            onChange={valueChangeHandler}
                            disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                          // disabled={state.invoice.isApproved ? "disabled" : ""}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="Supplier_Email"
                            value={inv.Supplier_Email}
                            onChange={valueChangeHandler}
                            disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                            // disabled={state.invoice.isApproved ? "disabled" : ""}
                            label="Mail Address"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="Supplier_GSTN"
                            value={ supplier || inv.Supplier_GSTN}
                            onChange={supplierGSTNChangeHandler}
                            disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                            // disabled={state.invoice.isApproved ? "disabled" : ""}
                            label="Supplier GSTN"
                            inputProps={{maxLength :15}}
                            helperText={supplierGSTN}
                            error={!!supplierGSTN}
                            InputProps={{endAdornment: <button style={{backgroundColor:'white', border:'none', cursor:'pointer'}} onClick={(event) => handleClickSupplier(supplier || inv.Supplier_GSTN)}> {gstn} </button>}}
                            
                          />
                          <select
                            className="select-tag"
                            onChange={handleChangeSupplier}
                          >                           
                            <option 
                            // value={inv.Supplier_GSTN}
                            >{inv.Supplier_GSTN || "NA"}</option>
                            <option 
                            // value={inv.Customer_GSTN}
                            >{inv.Customer_GSTN || "NA"}</option>
                          </select>  
                          </Grid>
                                                
                        <Grid item xs={6}>
                          <TextField
                            id="Supplier_VAT_Number"
                            value={inv.Supplier_VAT_Number}
                            disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                            // disabled={state.invoice.isApproved ? "disabled" : ""}
                            onChange={valueChangeHandler}
                            label="VAT Number"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="Supplier_CIN_Number"
                            value={inv.Supplier_CIN_Number}
                            disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                            // disabled={state.invoice.isApproved ? "disabled" : ""}
                            onChange={valueChangeHandler}
                            label="CIN Number"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="PanNumber"
                            value={inv.PANNumber}
                            disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                            onChange={valueChangeHandler}
                            label="PAN Number"
                          />
                        </Grid>
                        <Grid item xs={6}>
                        <TextField
                          id="IRNNo"
                          value={inv.IRNNo}
                          label="IRN No"
                          onChange={valueChangeHandler}
                          disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                        />
                      </Grid>
                      </Grid>
            </>)
         }
       </TabPanel>
 
       <TabPanel value={value} index={3}>
         {
           //invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.data && invData.invdata.invoice.data.map(inv =>
             (<>
              <Grid container spacing={3}>
               <Grid item xs={6}>
                 <TextField
                   id="CustomerName"
                   value={inv.CustomerName}
                   onChange={valueChangeHandler}
                   label="Customer Name"
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                 // disabled={state.invoice.isApproved ? "disabled" : ""}
                 />
               </Grid>
               <Grid item xs={6}>
                 <TextField
                   id="Customer_GSTN"
                   value={customer || inv.Customer_GSTN}
                   onChange={customerGSTNChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                  //  disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="Customer GSTN"
                   inputProps={{maxLength :15}}
                   helperText={customerGSTN}
                   error={!!customerGSTN}
                   InputProps={{endAdornment: <button style={{backgroundColor:'white', border:'none', cursor:'pointer'}} onClick={(event) => handleClickCust(customer || inv.Customer_GSTN)}> {CustGstn} </button>}}
                            
                 />
                 <select
                    className="select-tag"
                    // value={customer}
                    onChange={handleChangeCustomer}
                  >                            
                    <option 
                    // value={inv.Supplier_GSTN}
                    >{inv.Supplier_GSTN || "NA"}</option>
                    <option 
                    // value={inv.Customer_GSTN}
                    >{inv.Customer_GSTN || "NA"}</option>
                  </select>
                 </Grid>
               <Grid item xs={6}>
                 <TextField
                   id="Customer_VAT"
                   value={inv.Customer_VAT}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="Customer VAT"
                 />
               </Grid>
               <Grid item xs={6}>
                 <TextField
                   id="Customer_Email"
                   value={inv.Customer_Email}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="Customer Email"
                 />
               </Grid>
               <Grid item xs={6}>
                 <TextField
                   id="Customer_Address"
                   value={inv.Customer_Address}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="Customer Address"
                 />
               </Grid>
             </Grid>
             </>)
           
         }
 
       </TabPanel>
       <TabPanel value={value} index={4}>
         {
           //invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.data && invData.invdata.invoice.data.map(inv =>
             (<>
              <Grid container spacing={3}>
               <Grid item xs={4}>
                 <TextField
                   id="CGST_P"
                   value={inv.CGST_P}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="CGST%"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="IGST_P"
                   value={inv.IGST_P}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="IGST%"
                 />
               </Grid>
               
               <Grid item xs={4}>
                 <TextField
                   id="SGST_P"
                   value={inv.SGST_P}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="SGST%"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField 
                   id="CGST_Value"
                   value={inv.CGST_Value}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="CGST Value"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="IGST_Value"
                   value={inv.IGST_Value}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="IGST Value"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="SGST_Value"
                   value={inv.SGST_Value}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="SGST Value"
                 />
               </Grid>
               
               
               <Grid item xs={4}>
                 <TextField
                   id="UGST_P"
                   value={inv.UGST_P}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="UGST%"
                 />
               </Grid>
               
               <Grid item xs={4}>
                 <TextField
                   id="VAT_P"
                   value={inv.VAT_P}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="VAT%"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="CESS_P"
                   value={inv.CESS_P}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="CESS%"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="UGST_Value"
                   value={inv.UGST_Value}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="UGST value"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="VAT_Value"
                   value={inv.VAT_Value}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="VAT Value"
                 />
               </Grid>
               
               <Grid item xs={4}>
                 <TextField
                   id="CESS_Value"
                   value={inv.CESS_Value}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="CESS value"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="TCS_P"
                   value={inv.TCS_P}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="TCS%"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="TCS_Value"
                   value={inv.TCS_Value}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="TCS Value"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="TotalTax"
                   value={inv.TotalTax}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="Total Tax"
                 />
               </Grid>
             </Grid>
             </>)
         }
         {/* <h1 align="center">This Feature is not Available in Trial version</h1> */}
       </TabPanel>
       <TabPanel value={value} index={5}>
         <Table></Table>
       </TabPanel> 
       <TabPanel value={value} index={6}>
         <Table2></Table2>
       </TabPanel>
       <TabPanel value={value} index={7}>
         <CustomTables></CustomTables>
       </TabPanel>
        </> : 
        <>
        <TabPanel value={value} index={0}>
        {
          //  const invdate=inv.InvoiceDate
          //  let text=invdate.toString().length
          //  console.log(text)
          //  if(text!==10){
          //    toast.error("update date to dd-mm-yyyy format")
          //  }
          (<><Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField 
              id="VendorName"
              value={inv.VendorName || ""}
              onChange={valueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              // label={ <ScreenCapture>{({ onStartCapture }) => (<Fragment>Supplier Name<CropIcon onClick={onStartCapture} className={classes.myIcon}></CropIcon></Fragment> )}</ScreenCapture>}
              label="Supplier Name"
            /> 
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="InvoiceId"
              onChange={valueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              value={inv.InvoiceId || ""}
              // label={<Fragment>Invoice Number<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="Invoice Number"
            />   
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="InvoiceDate"
              value={inv.InvoiceDate || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              // label={<Fragment>Invoice Date<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="Invoice Date"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="PurchaseOrder"
              value={inv.PurchaseOrder || ""}
              onChange={valueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              // label={<Fragment>PO Ref Number<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="PO Ref Number"
            />
          </Grid>
          {/* <Grid item xs={4}>
            <TextField
              id="InvoiceRefNumber"
              value={inv.InvoiceRefNumber}
              onChange={valueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              // label={<Fragment>PO Ref Number<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="Invoice Ref Number"
            />
          </Grid> */}
          <Grid item xs={4}>
            <TextField
              id="InvoiceTotal"
              value={inv.InvoiceTotal || ""}
              onChange={valueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              // label={<Fragment>Total Amount<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="Total Amount"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="SubTotal"
              value={inv.SubTotal || ""}
              onChange={valueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              // label={<Fragment>Sub Total<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="Sub Total"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="DueDate"
              value={inv.DueDate || ""}
              onChange={valueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              // label={<Fragment>Due Date<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="Due Date"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="GRN_Number"
              value={inv.GRN_Number || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              // label={<Fragment>GRN Number<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="GRN Number"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="SES_Number"
              value={inv.SES_Number || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              // label={<Fragment>SES Number<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="SES Number"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              id="Freight"
              value={inv.Freight || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              label="Freight Amount"
              // label={<Fragment>Freight<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              id="FreightTaxValue"
              value={inv.FreightTaxValue || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              label="Freight Tax"
              // label={<Fragment>Freight<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              id="FreightPercentage"
              value={inv.FreightPercentage || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              label="Freight Percentage"
              // label={<Fragment>Freight<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="Insurance"
              value={inv.Insurance || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              // label={<Fragment>Insurance<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="DCA No."
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="Package"
              value={isCompanySaint ? "" : inv.Package || "" }
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              // label={<Fragment>Package<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label={isCompanyCEAT ? "Barcode" : "Package"}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
          <TextField
              id="InvoiceType"
              value={inv.InvoiceType || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              label="Invoice Type"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
          <TextField
              id="Currency"
              value={inv.Currency || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              label="Currency"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="LoadingCharges"
              value={inv.LoadingCharges || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              // label={<Fragment>Loading Charges<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              label="Loading Charges"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              id="VendorCode"
              value={inv.VendorCode || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              label="VendorCode"
              // label={<Fragment>Freight<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              id="SACCode"
              value={inv.SACCode || ""}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              onChange={valueChangeHandler}
              label="SAC Code"
              // label={<Fragment>Freight<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
              defaultValue=""
            />
          </Grid>
        </Grid>
        </>)   
         }
 
       </TabPanel>
       {(companyname==="Dahnay" ) ? <TabPanel value={value} index={1}>
       {
           //invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.data && invData.invdata.invoice.data.map(inv =>
            (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                    id="ReferenceNo"
                    value={inv.ReferenceNo}
                    label="Reference No"
                    onChange={valueChangeHandler}
                    disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="BillofLading"
                    value={inv.BillofLading}
                    label="Bill of Lading"
                    onChange={valueChangeHandler}
                  disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="DeliveryNote"
                      value={inv.DeliveryNote}
                    label="Delivery Note"
                    onChange={valueChangeHandler}
                    disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                      id="CreditMemoNumber"
                      value={inv.CreditMemoNumber}
                      onChange={valueChangeHandler}
                      disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                      // label={<Fragment>PO Ref Number<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
                      label="Credit Memo No"
                    />
                  </Grid> 
                  <Grid item xs={6}>
                    <TextField
                      id="DateofCreditMemo"
                      value={inv.DateofCreditMemo}
                      onChange={valueChangeHandler}
                      disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                      // label={<Fragment>PO Ref Number<CropIcon className={classes.myIcon}></CropIcon></Fragment>}
                      label="Date of Credit Memo"
                    />
                  </Grid> 
                <Grid item xs={6}>
                  <TextField
                    id="MB/LNo"
                    value={inv.MBLNo}
                    label="MB/L No"
                    onChange={valueChangeHandler}
                  disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="BLNo"
                    value={inv.BLNo}
                    label="BL No"
                    onChange={valueChangeHandler}
                  disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="Vessel"
                    value={inv.Vessel}
                    label="Vessel"
                    onChange={valueChangeHandler}
                  disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="Voyage"
                    value={inv.Voyage}
                    label="Voyage"
                    onChange={valueChangeHandler}
                  disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="ContainerNo"
                    value={inv.ContainerNo}
                    label="Container No"
                    onChange={valueChangeHandler}
                    disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                  />
                </Grid>
                </Grid>
            </>
            )
           }
       </TabPanel>:null}
       
       <TabPanel value={value} index={companyname!=="Dahnay" ? 1 : 2}>
        {
           //invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.data && invData.invdata.invoice.data.map(inv =>
          (<>
            <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <TextField
                            id="VendorName"
                            value={inv.VendorName}
                            label="Supplier Name"
                            onChange={valueChangeHandler}
                            disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                          // disabled={state.invoice.isApproved ? "disabled" : ""}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="VendorAddress"
                            value={inv.VendorAddress}
                            label="Supplier Address"
                            onChange={valueChangeHandler}
                            disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                          // disabled={state.invoice.isApproved ? "disabled" : ""}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="Supplier_Email"
                            value={inv.Supplier_Email}
                            onChange={valueChangeHandler}
                            disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                            // disabled={state.invoice.isApproved ? "disabled" : ""}
                            label="Mail Address"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="Supplier_GSTN"
                            value={ supplier || inv.Supplier_GSTN}
                            onChange={supplierGSTNChangeHandler}
                            disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                            // disabled={state.invoice.isApproved ? "disabled" : ""}
                            label="Supplier GSTN"
                            inputProps={{maxLength :15}}
                            helperText={supplierGSTN}
                            error={!!supplierGSTN}
                            InputProps={{endAdornment: <button style={{backgroundColor:'white', border:'none', cursor:'pointer'}} onClick={(event) => handleClickSupplier(supplier || inv.Supplier_GSTN)}> {gstn} </button>}}
                            
                          />
                          <select
                            className="select-tag"
                            // labelId="demo-simple-select-label"
                            // id="demo-simple-select"
                            // value={age}
                            onChange={handleChangeSupplier}
                          >                           
                            <option 
                            // value={inv.Supplier_GSTN}
                            >{inv.Supplier_GSTN || "NA"}</option>
                            <option 
                            // value={inv.Customer_GSTN}
                            >{inv.Customer_GSTN || "NA"}</option>
                          </select>  
                          </Grid>
                                                
                        <Grid item xs={6}>
                          <TextField
                            id="Supplier_VAT_Number"
                            value={inv.Supplier_VAT_Number}
                            disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                            // disabled={state.invoice.isApproved ? "disabled" : ""}
                            onChange={valueChangeHandler}
                            label="VAT Number"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="Supplier_CIN_Number"
                            value={inv.Supplier_CIN_Number}
                            disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                            // disabled={state.invoice.isApproved ? "disabled" : ""}
                            onChange={valueChangeHandler}
                            label="CIN Number"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="PanNumber"
                            value={inv.PANNumber}
                            disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                            onChange={valueChangeHandler}
                            label="PAN Number"
                          />
                        </Grid>
                        <Grid item xs={6}>
                        <TextField
                          id="IRNNo"
                          value={inv.IRNNo}
                          label="IRN No"
                          onChange={valueChangeHandler}
                          disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                        />
                      </Grid>
                      </Grid>
            </>)
         }
       </TabPanel>
 
       <TabPanel value={value} index={(companyname!=="Dahnay") ? 2:3}>
         {
           //invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.data && invData.invdata.invoice.data.map(inv =>
             (<>
              <Grid container spacing={3}>
               <Grid item xs={6}>
                 <TextField
                   id="CustomerName"
                   value={inv.CustomerName}
                   onChange={valueChangeHandler}
                   label="Customer Name"
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                 // disabled={state.invoice.isApproved ? "disabled" : ""}
                 />
               </Grid>
               <Grid item xs={6}>
                 <TextField
                   id="Customer_GSTN"
                   value={customer || inv.Customer_GSTN}
                   onChange={customerGSTNChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                  //  disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="Customer GSTN"
                   inputProps={{maxLength :15}}
                   helperText={customerGSTN}
                   error={!!customerGSTN}
                   InputProps={{endAdornment: <button style={{backgroundColor:'white', border:'none', cursor:'pointer'}} onClick={(event) => handleClickCust(customer || inv.Customer_GSTN)}> {CustGstn} </button>}}
                            
                 />
                 <select
                    className="select-tag"
                    // value={customer}
                    onChange={handleChangeCustomer}
                  >                            
                    <option value={inv.Supplier_GSTN}>{inv.Supplier_GSTN || "NA"}</option>
                    <option value={inv.Customer_GSTN}>{inv.Customer_GSTN || "NA"}</option>
                  </select>
                 </Grid>
               <Grid item xs={6}>
                 <TextField
                   id="Customer_VAT"
                   value={inv.Customer_VAT}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="Customer VAT"
                 />
               </Grid>
               <Grid item xs={6}>
                 <TextField
                   id="Customer_Email"
                   value={inv.Customer_Email}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="Customer Email"
                 />
               </Grid>
               <Grid item xs={6}>
                 <TextField
                   id="Customer_Address"
                   value={inv.Customer_Address}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="Customer Address"
                 />
               </Grid>
             </Grid>
             </>)
           
         }
 
       </TabPanel>
       <TabPanel value={value} index={(companyname!=="Dahnay") ? 3:4}>
         {
           //invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.data && invData.invdata.invoice.data.map(inv =>
             (<>
              <Grid container spacing={3}>
               <Grid item xs={4}>
                 <TextField
                   id="CGST_P"
                   value={inv.CGST_P}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="CGST%"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="SGST_P"
                   value={inv.SGST_P}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="SGST%"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="IGST_P"
                   value={inv.IGST_P}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="IGST%"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField 
                   id="CGST_Value"
                   value={inv.CGST_Value}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="CGST Value"
                 />
               </Grid>
               
               <Grid item xs={4}>
                 <TextField
                   id="SGST_Value"
                   value={inv.SGST_Value}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="SGST Value"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="IGST_Value"
                   value={inv.IGST_Value}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="IGST Value"
                 />
               </Grid>
               
               <Grid item xs={4}>
                 <TextField
                   id="UGST_P"
                   value={inv.UGST_P}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="UGST%"
                 />
               </Grid>
               
               <Grid item xs={4}>
                 <TextField
                   id="VAT_P"
                   value={inv.VAT_P}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="VAT%"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="CESS_P"
                   value={inv.CESS_P}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="CESS%"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="UGST_Value"
                   value={inv.UGST_Value}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="UGST value"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="VAT_Value"
                   value={inv.VAT_Value}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="VAT Value"
                 />
               </Grid>
               
               <Grid item xs={4}>
                 <TextField
                   id="CESS_Value"
                   value={inv.CESS_Value}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="CESS value"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="TCS_P"
                   value={inv.TCS_P}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="TCS%"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="TCS_Value"
                   value={inv.TCS_Value}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="TCS Value"
                 />
               </Grid>
               <Grid item xs={4}>
                 <TextField
                   id="TotalTax"
                   value={inv.TotalTax}
                   onChange={valueChangeHandler}
                   disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
                   // disabled={state.invoice.isApproved ? "disabled" : ""}
                   label="Total Tax"
                 />
               </Grid>
             </Grid>
             </>)
         }
         {/* <h1 align="center">This Feature is not Available Trial version</h1> */}
       </TabPanel>
       <TabPanel value={value} index={(companyname!=="Dahnay") ? 4:5}>
         <Table></Table>
       </TabPanel>
       <TabPanel value={value} index={(companyname!=="Dahnay") && (role !== 'Client') ? 5:6}>
         <CustomTables></CustomTables>
       </TabPanel>
       <TabPanel value={value} index={(companyname!=="Dahnay") && isCompanySaint && (role !== 'Client')? 6:7  || isCompanySaint && (role === 'Client') ? 5 : 6}>
       <Grid container spacing={5}>
          <Grid item xs={6}>
            <TextField 
               id="SellerGstin"
               value={inv && inv.Package && inv.Package.SellerGstin?inv.Package.SellerGstin:""}
               onChange={qrvalueChangeHandler}
               disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
               label="Supplier GSTIN"
            /> 
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="BuyerGstin"
              onChange={qrvalueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              value={inv && inv.Package && inv.Package.BuyerGstin?inv.Package.BuyerGstin:""}
              label="Recipient GSTIN"
            />
            </Grid>
            <Grid item xs={6}>
            <TextField
              id="DocNo"
              onChange={qrvalueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              value={inv && inv.Package &&  inv.Package.DocNo?inv.Package.DocNo:""}  
              label="Document Number"
            />
            </Grid>
            <Grid item xs={6}>
            <TextField
              id="DocTyp"
              onChange={qrvalueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              value={inv && inv.Package &&  inv.Package.DocTyp?inv.Package.DocTyp:""} 
              label="Document Type"
            />
            </Grid>
            <Grid item xs={6}>
            <TextField
              id="DocDt"
              onChange={qrvalueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              value={inv && inv.Package && inv.Package.DocDt?inv.Package.DocDt:""} 
              label="Document Date"
            />
            </Grid>
            <Grid item xs={6}>
            <TextField
              id="TotInvVal"
              onChange={qrvalueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              value={inv && inv.Package && inv.Package.TotInvVal?inv.Package.TotInvVal:""} 
              label="Total Invoice Value"
            />
            </Grid>
            <Grid item xs={6}>
            <TextField
              id="ItemCnt"
              onChange={qrvalueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              value={inv && inv.Package && inv.Package.ItemCnt?inv.Package.ItemCnt:""} 
              label="No. Of Line Items"
            />
            </Grid>
            <Grid item xs={6}>
            <TextField
              id="MainHsnCode"
              onChange={qrvalueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
               value={inv && inv.Package && inv.Package.MainHsnCode?inv.Package.MainHsnCode:""} 
              label="Main HSN Code"
            />
            </Grid>
            <Grid item xs={6}>
            <TextField
              id="Irn"
              onChange={qrvalueChangeHandler}
              disabled={invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_approved ? "disabled" : ""}
              value={inv && inv.Package && inv.Package.Irn?inv.Package.Irn:""}
              label="IRN"
            />
            </Grid>
            
        </Grid>
       </TabPanel>
        </>
       }
       
     </div>
 
   );
 }
 
 const mapDispatchToProps = (dispatch) => {
   return {
     changeValues: (state) => dispatch(change_field_value(state)),
     fetchsingleInvoice: (id) => dispatch(fetchsingleInvoice(id)),
     updateInvoice: (id, state) => dispatch(updateInvoice(id, state))
   };
 };
 
 const mapStateToProps = (state) => {
   return {
     state: state,
   };
 };
 
 export default connect(
   mapStateToProps,
   mapDispatchToProps
 )(LineItems);
 