import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {
	singleIdFileDisplay,
	sendForAnalyze,
	updateSplitting,
} from '../../redux';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import './splitdisplay.css';
import { PDFDocument } from 'pdf-lib';
import { Button } from '@material-ui/core';

function Test({ invData, singleIdFileDisplay }) {
	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	const [numPages, setNumPages] = useState(null);
	const [selected, setSelected] = useState([]);
	const [isprocessed, setisprocessed] = useState(false);
	const [isSingleprocessed,setisSingleprocessed]=useState(false)
	const history = useHistory();
	const { id } = useParams();
	const dispatch = useDispatch();

	// console.log(id)
	useEffect(() => {
		singleIdFileDisplay(id);
	}, [id]);

	const fileUrl = invData && invData.filewithId && invData.filewithId.fileUrl;
	const fileName =
		invData &&
		invData.filewithId &&
		invData.filewithId.invoice &&
		invData.filewithId.invoice.file_Name;
	console.log(fileName);
	const url = fileUrl;
	const status =
		invData && invData.data && invData.data.data && invData.data.data.status;
	console.log(status);
	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		console.log(numPages);
	}
	const handleChange = e => {
		var item = e.target.id;
		console.log(item);
		let newArray = [...selected, e.target.id];
		if (selected.includes(e.target.id)) {
			newArray = newArray.filter(pageno => pageno !== e.target.id);
		}

		setSelected(newArray);
	};
	console.log(selected);
	async function copyPages() {
		setisprocessed(true);
		const url1 = fileUrl;
		const firstDonorPdfBytes = await fetch(url1).then(res => res.arrayBuffer());
		const firstDonorPdfDoc = await PDFDocument.load(firstDonorPdfBytes);
		const pdfDoc = await PDFDocument.create();
		for (let i = 0; i < selected.length; i++) {
			console.log(selected[i]);
			const [firstDonorPage] = await pdfDoc.copyPages(firstDonorPdfDoc, [
				selected[i] - 1,
			]);
			pdfDoc.addPage(firstDonorPage);
		}
		const pdfBytes = await pdfDoc.save();
		console.log(pdfBytes);

		const blob = new Blob([pdfBytes], { type: 'application/pdf' });
		// const file=new File([blob],fileName)
		blob.lastModifiedDate = new Date();
		blob.name = fileName;
		console.log(blob);
		const blobUrl = URL.createObjectURL(blob);
		console.log(blobUrl);
		// window.open(blobUrl)
		const token = window.localStorage.getItem('token');
		dispatch(sendForAnalyze(blob, blobUrl, token, true, id));
		dispatch(updateSplitting(id));
		const img = document.createElement('img');
		img.src = blobUrl;
		document.body.appendChild(img);
	}

	const handleShowDialog = async index => {
		console.log('cliked');
		console.log(selected);
		const url1 = fileUrl;
		const firstDonorPdfBytes = await fetch(url1).then(res => res.arrayBuffer());
		const firstDonorPdfDoc = await PDFDocument.load(firstDonorPdfBytes);
		const pdfDoc = await PDFDocument.create();
		const [firstDonorPage] = await pdfDoc.copyPages(firstDonorPdfDoc, [
			selected - 1,
		]);
		pdfDoc.addPage(firstDonorPage);

		const pdfBytes = await pdfDoc.save();
		console.log(pdfBytes);

		const blob = new Blob([pdfBytes], { type: 'application/pdf' });
		// const file=new File([blob],fileName)
		blob.lastModifiedDate = new Date();
		blob.name = fileName;
		console.log(blob);
		const blobUrl = URL.createObjectURL(blob);
		window.open(blobUrl);
	};
	const backSubmit = () => {
		history.push('/');
	};

	async function singleProcess() {
        setisSingleprocessed(true)
        const url1 = fileUrl
        const firstDonorPdfBytes = await fetch(url1).then(res => res.arrayBuffer())
        const firstDonorPdfDoc = await PDFDocument.load(firstDonorPdfBytes)
        for (let i = 0; i < selected.length; i++) {
            const pdfDoc = await PDFDocument.create();
            console.log(selected[i])
            const [firstDonorPage] = await pdfDoc.copyPages(firstDonorPdfDoc, [selected[i] - 1])
            pdfDoc.addPage(firstDonorPage)
            const pdfBytes = await pdfDoc.save()
            const blob = new Blob([pdfBytes], { type: "application/pdf" })
        // const file=new File([sblob],fileName)
        blob.lastModifiedDate = new Date();
        blob.name = fileName
        console.log(blob)
        const blobUrl = URL.createObjectURL(blob)
        console.log(blobUrl)
        window.open(blobUrl)
                 const token = window.localStorage.getItem("token")
        dispatch(sendForAnalyze(
            blob,
            blobUrl,
            token,
            true,
            id
        ));
        dispatch(updateSplitting(id));
        const img = document.createElement('img');
        img.src = blobUrl;
        document.body.appendChild(img)
        }

    }


	return (
		<>
			<div className="containerDisplay">
				<div className="container">
					<div className="btn">
						<Button
							className="processBtn"
							variant="contained"
							color="primary"
							onClick={copyPages}
						>
							{isprocessed
								? status === undefined
									? 'Processing'
									: status
								: 'process'}
						</Button>
						<Button
							className="gobackBtn"
							variant="contained"
							color="primary"
							onClick={backSubmit}
						>
							Go Back
						</Button>
						<Button className="gobackBtn" variant="contained" color="primary" onClick={singleProcess}>{isSingleprocessed?(status===undefined)?"Processing":status:"single file process"}</Button>
					</div>

					<Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
						{Array.apply(null, Array(numPages))
							.map((x, i) => i + 1)
							.map((page, index) => {
								// console.log(page)
								// console.log(index)
								return (
									<>
										<div className="imgcontainer">
											<Page pageNumber={page} onClick={handleShowDialog} />
											<input
												type="checkbox"
												className="checkbox"
												id={page}
												onChange={handleChange}
											/>
										</div>
									</>
								);
							})}

						{/* <Page pageNumber={pageNumber} /> */}
					</Document>
				</div>
			</div>
		</>
	);
}
const mapStateToProps = state => {
	return {
		invData: state.invoice,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		singleIdFileDisplay: (id, state) =>
			dispatch(singleIdFileDisplay(id, state)),
		sentToAnalyze: (file, fileUrl, token, state) =>
			dispatch(sendForAnalyze(file, fileUrl, token, state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Test);
