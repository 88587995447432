/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */
 
export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const REGISTRATION_SUCCESS='REGISTRATION_SUCCESS'
export const REGISTRATION_FAILURE='REGISTRATION_FAILURE'
export const FETCH_USERS_SUCCESS='FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE='FETCH_USERS_FAILURE'
export const UPDATED_SUCCESS = "UPDATED_SUCCESS";