 /**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */
 
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import HeroSection from "../../modules/HomeModules/Home";
import Results from "../../modules/AnalyzedResults/Results/Results"
import Analyzing from "../../modules/AnalyzedResults/IsAnalyzing/Analyzing";

// function Hometable(){
//   return ( <>
//    <HeroSection/>
//   </>)
// }

function IsAnalyzing(props) {
  console.log(props)
  if (props.file !== "" && props.dataF === "") {
    return <Analyzing></Analyzing>;
  } else {
    return <HeroSection></HeroSection>;
  }
}

class Home extends Component {
  render() {
    return(
    <IsAnalyzing file={this.props.state.invoice.fileUrl}  dataF ={this.props.state.invoice.data}></IsAnalyzing>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
// export default Hometable
