import * as React from 'react';
import { useEffect,useState} from "react";
import './header.css';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Rowheader from '../Rowheader/rowheader';
import EmailHeader from '../EmailHeader/EmailHeader';
import { analyzing_complete_success, analyzing_complete_failure , analyzing_invoice , fetchInvoice , UploadedFile ,approvedInvoice,unapprovedInvoice ,allEmailInvoice , allLocalInvoice} from "../../redux";
// import { useHistory } from "react-router";
import Reports from '../Reports/reports'
import UserDetails from "../UserDetails/userdetails";
import UploadStatus from "../UploadStatus/UploadStatus";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import { Button } from "@material-ui/core";
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import axios from 'axios';
import './header.css';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{  }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function BasicTabs({state}) {
  const [value, setValue] = React.useState(0);
  const [valueTab2, setValueTab2] = React.useState(null);
  const [company,setCompany]=React.useState("");
  const [role, setRole] = React.useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    window.currentTab = newValue;
  };
  const handleChangeTab2 = (event, newValue) => {
    setValue(newValue + 4);
  };
  React.useEffect(() => {
    setRole(localStorage.getItem("UserRole"));
  }, []);

  React.useEffect(()=>{
    setCompany(localStorage.getItem("companyName"))
    if(window.currentTab){
      setValue(window.currentTab);
    }
  },[])
  useEffect(() => {
    setRole(localStorage.getItem("UserRole"));
  }, []);
  const fileHandler = (e) => {
    toast.info('Your uploaded Document is in Queue!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
      });
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    console.log(e.target.files[0].name,"filenamme");
    let fileName = e.target.files[0].name
    reader.onload = () => {

      if (reader.readyState === 2) {
        
          sendForAnalyze(
            fileName,
            e.target.files[0],
            reader.result,
            state.user.token,
          
        );
      }
    };
  };
  //new data appended
  const RoleApi = localStorage.getItem("UserRole");
  const [fromDate, setFromDate] = useState('2021-01-01')
    const [toDate, setToDate] = useState(new Date())
   // 
    useEffect(async () => {
       // if (RoleApi !== 'Client') {
        dispatch(approvedInvoice(0, 10,"", "", fromDate, toDate))
        dispatch(unapprovedInvoice(0, 10,"", "", fromDate, toDate))
       // dispatch(fetchInvoice(0,10,"", "", fromDate, toDate))
        dispatch(allEmailInvoice(0,10, fromDate, toDate))
        dispatch(allLocalInvoice(0,10, fromDate, toDate))
        localStorage.setItem('rowsPerPageLocalInvoices', 10);
        localStorage.setItem('rowsPerPageEmailInvoices', 10);
        localStorage.setItem('rowsPerPageAllInvoices', 10);
        localStorage.setItem('rowsPerPageApprovedInvoices', 10);
        localStorage.setItem('rowsPerPageUnApprovedInvoices', 10);
       // }
    }, [])
    //ended
  const sendForAnalyze = async(fileName, file, fileUrl, token, isFromSplit=false, splitRecordId) => {
    dispatch(analyzing_invoice());
    let formData = new FormData();
    formData.append("file", file, fileUrl);
    formData.append("name", file.name.split(".")[0]);
    formData.append("ext", file.name.split(".")[1]);
    formData.append("isFromSplit", isFromSplit);
    formData.append("splitRecordId", splitRecordId);
    formData.append("fileName", fileName);
    let url =  process.env.REACT_APP_BACKEND_API + "api/analyze/standard";
    if (localStorage.getItem("modelType") === "Enterprise") {
      url = process.env.REACT_APP_BACKEND_API + "api/analyze/enterprise";
    }
     axios.post(url, formData, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(analyzing_complete_success(res));
      let toDate = new Date()
      dispatch(fetchInvoice(0,10,"", "", '2021-01-01', toDate))
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(analyzing_complete_failure(errorMsg));
      toast.error('Failed to Upload Document', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
        });
    });
    dispatch(UploadedFile(0, 10));
}

  return (
    <Box sx={{ width: '100%' }}>
      {
        role === 'Client' ?
        <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs indicatorColor="primary" value={value} onChange={handleChange}  aria-label="basic tabs example">
          <Tab style={{ fontSize: '20px' }} label="Home" {...a11yProps(0)} />
          <Tab style={{ fontSize: '20px' }} label="Reports" {...a11yProps(1)} />
          {/* <Tab style={{ fontSize: '20px' }} label="Billing" {...a11yProps(3)} /> */}
          {role === "Admin" ? (
            <Tab
              style={{ fontSize: "20px" }}
              label="User Details"
              {...a11yProps(3)}
            />
          ) : null}
        </Tabs>
      </Box>
        </>:
        <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', display:'flex' }}>
        <Tabs indicatorColor="primary" value={value} onChange={handleChange}  aria-label="basic tabs example" >
          <Tab style={{ fontSize: '20px' }} label="Home" {...a11yProps(0)} />
          <Tab style={{ fontSize: '20px' }} label="Reports" {...a11yProps(1)} />
          <Tab style={{ fontSize: '20px' }} label="Split PDF" {...a11yProps(2)}/>
          {/* <Tab style={{ fontSize: '20px' }} label="Billing" {...a11yProps(3)} /> */}
          {role === "Admin" ? (
            <Tab
              style={{ fontSize: "20px" }}
              label="User Details"
              {...a11yProps(3)}
            />
          ) : null}
        </Tabs>
        <Box>
        <Tabs indicatorColor="primary"  aria-label="basic tabs example" style={{position:'absolute', right:0}} value={value - 4}  onChange={handleChangeTab2}>
        <Tab style={{ fontSize: '15px' }} label="Upload Status" {...a11yProps(4)}/>
           {(role === "Viewer") || (role === 'Client') || (role === "Reviewer") || (role === "ReviewerW") ? 
          <>            
          </> : 
          <>
          <Button
          id='btnadd'
          style={{border:'none',fontSize: '13px'}}
              disabled={role === "Client" ? "disabled" : ""}
              variant="outlined"
              color="primary"
              component="label"
            >
             <AddIcon/> Add New
              <input
                type="file"
                name="file-upload"
                id="input"
                onChange={fileHandler}
                hidden
              />
            </Button>                
          </>
        }
        </Tabs>
        </Box>
      </Box>
        </>
      }
      
      <div >
      <TabPanel value={value} index={0}>
       <div className="tabheader">
        {/* home */}
       <Rowheader/>
      </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
      {/* <h1 align="center" id="indevelopmentText">This Feature is Under Development</h1>
      <img src={indevelopmentImg} id="indevelopmentImg" alt="In Development"></img> */}
       <div><Reports/></div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* <div className="splitpdftab"><SplitPdf/></div>
        */}
         <div className="splitpdftab">
          {/* splitpdf */}
         <EmailHeader/>
        </div> 
      </TabPanel>
      <TabPanel value={value} index={3}>
          <div>
            <UserDetails />
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div>
            <UploadStatus />
          </div>
        </TabPanel>
      </div>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(BasicTabs);