import React,{useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { Link,useHistory } from "react-router-dom"
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import loader from '../../assets/loadingImage.gif';
import Snackbar from '@material-ui/core/Snackbar';
import {ToastContainer,toast} from 'react-toastify';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import resetpassword from '../Resetpassword/resetpassword';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '91.5vh',
    
  },
  image: {
    backgroundImage: 'url(./imgLoginPage.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    marginTop:'20vh',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Forgotpassword() {
  const classes = useStyles();
  let errorsObj = { email: ''};
  const [email, setemail] = useState('');
  const [otpForm,showForm]=useState(true)
  
  let history = useHistory();

  // const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    // setOpen(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  

  const SendOtp=async (e)=> {
    
    e.preventDefault();
    let error = false;
   
    const errorObj = { ...errorsObj };
    if (email === '') {
        errorObj.email = 'email is Required';
        error = true;
    }

    // if (error){
    //   setOpen(true);
    //    return
    // }
    
    try {
      let url= process.env.REACT_APP_BACKEND_API + 'api/users/emailsend'
      let options={
        method:'POST',
        url:url,
        data:{email}
      }
      let response=await axios(options);
      let record=response.data;
      if(record.statusText == 'Success'){
         toast.success(record.message);
      }else{
        toast.error(record.message);
      }
    } catch (error) {
      toast.error('Something went wrong')
    }
    console.log(otpForm)
    // dispatch(authenticateUser(email,history))
}
  return (
    <Grid container spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center" component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={5} square>
        <div className={classes.paper}>
        <ToastContainer/>
          <Typography component="h1" variant="h5">
            Enter your email
          </Typography>
    {
      otpForm ? <form className={classes.form} onSubmit={SendOtp}  >
      <TextField
        variant="filled"
        margin="normal"
        required
        fullWidth
        id="email"
        label="email"
        name="email"
        onChange={(e) =>
          setemail(e.target.value)
      }
        autoComplete="email"
        autoFocus
      />
     
      <Button
        fullWidth
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        >
       send Otp
      </Button>
    </form>
    : <Typography component="h1" variant="h5">
    Enter your email
  </Typography>
    }    
        </div>
      </Grid>
    </Grid>
    
  );
}



export default Forgotpassword;