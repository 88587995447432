import { React, useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import { Button } from "@material-ui/core";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { fetchUserDetails , fetchUserDetailSuccess , fetchUserDetailfailure } from "../../redux";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Box from '@mui/material/Box';
import CircularProgress from "@material-ui/core/CircularProgress";
import { GetUsers} from '../../redux';
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';
import "./userdetails.css";
const useStyles = makeStyles((theme) => ({
  maint:{
    display:"flex"
    },
    paper: {
      width: '100%',
      marginLeft: '-5px',
      marginBottom: '-5px',
  },
  search: {
    position: 'relative',
    marginLeft: '40px',
    marginTop: '10px',
    marginBottom: "10px",
    width: '50%',
    display: 'flex',
},
dropdown:{
  position: 'relative',
  marginLeft: '40px',
  marginTop: '10px',
  marginBottom: "10px",
  width: '50%',
  display: 'flex',
  justifyContent:'right'
}
}));


function UserDetails({ props, state }) {
  console.log(state);
  console.log(props);
  const classes = useStyles();
  
  const [fromDate, setFromDate] = useState("09/21/2021");
  const [toDate, setToDate] = useState(new Date());
  const dispatch = useDispatch();
  const history = useHistory();
  const userReportData = state.invoice.userDetails;
  const [tableEntries, setTableEntries] = useState([])
  const [userid, setUserid] = useState('')
  const [load, setLoad]= useState(true)
  const [loading, setLoading]= useState(false)

  const tableRef = useRef(null);
  const userData = state.user
  const rows = userData && userData.users
  console.log("rows", rows);
  
  
  if(rows && rows.length > 0 ){
    const filtered = rows.filter((element) =>{
       if(element.role === 'ReviewerW' || element.role === 'Reviewer' ){  
        return element.userId 
       }
    });
    if(filtered.length >0){

      var InitialValue = filtered[0].userId  
    }else{
      toast.warning('No User Found', {
        toastId: 'success1',
    })
    }
  }

  useEffect( async() => { 
    setLoad(true)
     dispatch(GetUsers())
      setUserid(InitialValue)
      // dispatch(fetchUserDetails(userid)) 
      const token = window.localStorage.getItem("token");
       await axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/usersReport",{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(response=>{
          setLoad(false)
          dispatch(fetchUserDetailSuccess(response.data))
          console.log(response.data,"response.data");
          if(response.data.data.length === 0){
            setLoading(true)
          }
        })
        .catch(error=>{
          const errorMsg=error.message
          dispatch(fetchUserDetailfailure(errorMsg))
        })
  },[InitialValue]);
 
  useEffect( () => {
    if(userReportData && userReportData.length > 0 ) {
      const filteredValue = userReportData.filter((element,index) =>{
       return element.userName === userid
      });
      if(filteredValue.length){
        setTableEntries(filteredValue[0].data)
      }
    }
  }, [userReportData]);
  
  const handleUserDetails=async(event) =>{
    const getuserid = event.target.value;
    setUserid(getuserid)
      dispatch(fetchUserDetails(getuserid));
      
  }

  const handleFromDate = (e) => {
    var myPastDate = new Date(e.target.value);
    myPastDate.setDate(myPastDate.getDate() - 1);
    setFromDate(myPastDate);
  };
  const min = Date.parse(fromDate);

  const max = Date.parse(toDate);

  const backBtn = () => {
    history.push("/analysing");
  };
  const today = new Date();
  const formDate = () => {};

 
  return (
    <>
     <ToastContainer />
     {
                loading && (<div style={{
                    position: 'absolute',
                    top: '400px',
                    left: '40vw',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <div className="loader">No Data Found </div>
                </div>)
            }
    <Paper className={classes.paper}>
      <div className={classes.maint}>
      <div className={classes.search}>         
        <input
          type="date"
          style={{
            backgroundColor: "white",
            padding: "3px 1px",
            borderRadius: "3px",
            border: "2px solid black",
            width: "140px",
            margin: "10px 10px",
            textTransform: "uppercase",
            fontSize: "15px",
          }}
          onChange={handleFromDate}
          // onChange={e => setFromDate(e.target.value)}
        />
        <Typography
          style={{ margin: "10px" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          To
        </Typography>
        {/* <TextField type="date" variant="outlined" size="small" onChange={e => setToDate(e.target.value)} /> */}
        <input
          type="date"
          style={{
            backgroundColor: "white",
            padding: "3px 1px",
            borderRadius: "3px",
            border: "2px solid black",
            margin: "10px 10px",
            width: "140px",
            textTransform: "uppercase",
            fontSize: "15px",
          }}
          onChange={(e) => setToDate(e.target.value)}
        />
        {/* <Button variant="contained" color="primary" style={{
        padding: '3px 1px',
        borderRadius: '3px',
        border: '2px solid black',
        width: '140px',
        margin: '10px 10px',
        textTransform: 'uppercase',
        fontSize: '15px'
      }}> Export excel </Button> */}

        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="Sigixtract"
          sheet="tablexls"
          buttonText="XLS Download"
        />
        </div>
        <div className={classes.dropdown}>
        <select id="dropdown" onChange={(e) => handleUserDetails(e)}>
          {rows.map((val, key) => (
             <>{val.role === 'ReviewerW' || val.role === 'Reviewer' ?  <option value={val.userId}>{val.userId}</option> : null
                   }</>
        
        ))}
        </select>
        {/* <Button variant="contained" className="goButton" color="primary" onClick={backBtn}>Back</Button>  */}
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          ref={tableRef}
          id="table-to-xls"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Processed Invoices</TableCell>
              <TableCell align="center">Pushed Invoices</TableCell>
              <TableCell align="center">Balance</TableCell>
            </TableRow>
          </TableHead>
          {load ? (
         <Box sx={{ display:'flex', position:'absolute', width:'90px', marginLeft:'50vw',marginTop:'10vh' }}>
            <CircularProgress color="secondary" size={ 70}/>
         </Box>
         ) : null}
          <TableBody>
            {tableEntries
              .filter((daterange) => {
                const uploadedDate = moment(daterange.date)
                  .utc()
                  .local()
                  .format("MM/DD/YYYY");
                console.log(
                  uploadedDate,
                  "this is uploaded date",
                  Date.parse(uploadedDate),
                  min,
                  max
                );
                return (
                  Date.parse(uploadedDate) >= min &&
                  Date.parse(uploadedDate) <= max
                );
              })
              .map((row) => {
                const timestamp = row.date;
                const uploadedtime = moment(timestamp)
                  .utc()
                  .local()
                  .format("DD-MM-YYYY");
                console.log(uploadedtime);
                let Balance = row.assignedCount - row.pushedCount
                if (Balance < 0){
                  Balance = 0
                }
                return (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{uploadedtime}</TableCell>
                    <TableCell align="center">
                      {row.assignedCount}
                    </TableCell>
                    <TableCell align="center">
                      {row.pushedCount}
                    </TableCell>
                    <TableCell align="center">
                      {Balance}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      </Paper>
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(UserDetails);