/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */
 
import React,{Component} from 'react'
import mlImage from '../../../assets/mlanalyzing.gif'
import './Analyzing.css'

export default class Analyzing extends Component {
  render() {
    return (
      <div id="anlyContainer">
      <img  id="mlImg" src={mlImage} alt=""></img>  
    </div>
    )
  }
}

