import { React, useEffect, useState, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@material-ui/core/Grid';
import './usersdisplay.css'
import { GetUsers, deleteUserManagement } from '../../redux';
import{ deleteUser } from '../../redux/user/userAuthActions'
import { connect, useDispatch } from 'react-redux'
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import Dialog from "../Dialog/dialog";
import EditForm from "./updateuser";
import { ToastContainer, toast } from "react-toastify";
import Box from '@mui/material/Box';
import CircularProgress from "@material-ui/core/CircularProgress";


function Users({ state }) {
  console.log(state)

  const [edit, setedit] = useState()
  const [deletes, setdeletes] = useState()



  const dispatch = useDispatch()
  const userData = state.user
  const rows = userData && userData.users
  console.log(rows)


  useEffect(async () => {
    dispatch(GetUsers())
  }, [])

  const [update, setupdate] = useState(false);
  const [currentEditUser, setCurrentEdit] = useState(null);
  const edits = (newId) => {
    console.log("inside edits:", newId);
    setCurrentEdit(newId);
    setupdate(true);
  }
  
  const [dialog, setDialog] = useState({
    message: "",
    isLoading: false,
  });
  const idProductRef = useRef();

  const handleDialog = (message, isLoading) => {
    setDialog({
      message,
      isLoading,
    });
  };

  const handleDelete = (id) => {
    handleDialog("Are you sure you want to delete?", true);
    idProductRef.current = id;
  };

  const areUSureDelete = (choose) => {
    if (choose) {
      toast.success("Deleted Successfully!");
      dispatch(deleteUser(idProductRef.current));
      dispatch(GetUsers());
      handleDialog("", false);
    } else {
      handleDialog("", false);
    }
  };
  const activeHandle = () => {

  }
  const disableHandle = () => {

  }




  return (<>
  <div>
        <h1 class="title"> User Management </h1>
      </div>
    <Grid item xs={15}  component={Paper} square>
      <div className="Userstable">
      <ToastContainer />
      <TableContainer>
      <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              id="table-to-xls"
            >
           <TableHead>
                <TableRow className="border">
                  <TableCell></TableCell>
                  <TableCell align="left">Username</TableCell>
                  <TableCell align="left">Role</TableCell>
                  <TableCell align="left">Current Status</TableCell>
                  <TableCell align="left">Delete User</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {rows <= 0 ? (
                  <Box sx={{ display:'flex', position:'absolute', width:'90px', marginLeft:'39vw',marginTop:'20vh' }}>
                     <CircularProgress color="secondary" size={ 70}/>
                  </Box>
                  ) : null}
          {rows.map((val, key) => {
            return (
              <TableRow key={key}>
                 <TableCell align="right">
                        <Button
                          className="ebtn"
                          variant="info"
                          color="primary"
                          onClick={() => {
                            edits(val);
                          }}
                        >
                          <EditIcon />
                        </Button>
                      </TableCell>
                      <TableCell align="left">{val.userId}</TableCell>
                      <TableCell align="left">{val.role}</TableCell>
                      <TableCell align="left">

                  <FormControl variant="standard" sx={{ m: 1, minWidth: 70 }}>

                    <NativeSelect
                    // onChange={(e) =>
                    //   setRole(e.target.value)
                    // }
                    // inputProps={{
                    //   name: 'Role',
                    //   id: 'role',
                    // }}
                    >
                      <option value="Admin" onClick={activeHandle}>Active</option>
                      <option value="Approver" onClick={disableHandle}>Inactive</option>
                      {/* <option value="Reviewer">ResetPassword</option> */}

                    </NativeSelect>
                  </FormControl>
                </TableCell>
                <TableCell align="left">
                  <>{val.role === 'Admin' ? null : <Button variant="text" color="primary"  onClick={() => {
                                handleDelete(val._id);
                              }} >
                    <DeleteIcon />
                    <span>DELETE</span>
                  </Button>}</>



                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
        </Table>
          </TableContainer>
          <EditForm
            trigger={update}
            setTrigger={setupdate}
            currentUser={currentEditUser}
          ></EditForm>

          {dialog.isLoading && (
            <Dialog onDialog={areUSureDelete} message={dialog.message} />
          )}
      </div>
    </Grid>
  </>);
}

const mapDispatchToProps = (dispatch) => {
  return {
    getusers: () => dispatch(GetUsers()),
    // deleteUserManagement: (_id) => dispatch(deleteUserManagement(_id))
  };
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);