import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddIcon from '@mui/icons-material/Add';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { useSelector, useDispatch } from 'react-redux'
import { DownloadFormate } from "../../redux";
import Button from '@mui/material/Button';
import './Setting.css'



function Setting() {
  const [type , setType]= useState('')
    const dispatch = useDispatch();
    const handleRadioChange = (event) => {
        setType(event.target.value)
      };
      const handleSubmit = (event) => {
        localStorage.setItem("Formate", type);
         dispatch(DownloadFormate(type))
       
       
      };

    return (  <>
    <div style={{paddingTop:'90px', height:'94vh', backgroundColor:'#fafafa'}}>
    <Button  type="submit" variant="outlined" style={{float:'right', marginRight:60, marginBottom:30}} onClick={handleSubmit}>Save</Button>
    <ExpansionPanel >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          id="additional-actions1-header"
        >
          <FormControlLabel
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            control={<AddIcon style={{visibility:'hidden' }}/>}
          />
          <p id='label' style={{fontSize:20}}> <AddIcon style={{color:'black', fontSize:15,marginRight:10}}/>Download Format</p>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <form onSubmit={handleSubmit}>
        <FormControl style={{marginLeft:80}}>
      <RadioGroup
      
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        onChange={handleRadioChange}
      >
        <FormControlLabel style={{marginBottom:10}} value="WorkBook" control={<Radio /> } label="Excel - WorkBook" />
        <FormControlLabel style={{marginBottom:10}} value="Sheet" control={<Radio />} label="Excel - Single Sheet" />
        <FormControlLabel value="JSON" control={<Radio />} label="JSON File" />
      </RadioGroup>
      
    </FormControl>
    </form>
        </ExpansionPanelDetails>
     </ExpansionPanel>
    </div>
     </>)
}

export default Setting