/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */
 
import React,  { useEffect } from 'react';
import { connect,useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import './Tables.css'
import {
  fetchsingleInvoice
} from "../../redux";


const createTables = (tables) => {
  var dat = "<div>";

  for (var tb = 0; tb < tables.length; tb++) {
    dat +=
      "<table style='text-align:center;border: 1px solid #858585; border-collapse: collapse;'>";
    var lTag = "<tbody>";
    var currentRow = -1;
    var bodyDone = false;

    for (var c = 0; c < tables[tb].cells.length; c++) {
      var cell = tables[tb].cells[c];
      if (currentRow !== cell.rowIndex) {
        if (cell.rowIndex !== 0) {
          if (!bodyDone) {
            dat += "</tr>" + lTag;
          } else {
            dat += "</tr>";
          }

        }
        currentRow = cell.rowIndex;
        if (cell.kind === "columnHeader") {
          lTag = "</thead>";
          dat += "<thead><tr>";
        } else {
          if (bodyDone) {
            dat += "<tr>";
          } else {
            lTag = "</tbody>";
            dat += "<tbody><tr>";
            bodyDone = true;
          }
        }
      }
      if (cell.kind === "columnHeader") {
        dat +=
          "<th  style='font-family: Roboto, sans-serif;text-align:center;border: 1px solid #858585; font-size:12px; padding: 8px; text-align: center; background-color: #006eff; color: white;' colspan='" +
          cell.columnSpan +
          "' rowspan = '" +
          cell.rowSpan +
          "'>" +
          cell.content +
          "</th>";
      } else {
        dat +=
          "<td style='font-family: Roboto, sans-serif;text-align:center;font-size:12px;border: 1px solid #858585' colspan='" +
          cell.columnSpan +
          "' rowspan = '" +
          cell.rowSpan +
          "'>" +
          cell.content +
          "</td>";
      }
    }
    dat += "</tr>" + lTag;
    dat += "</table><br><br>";
  }

  dat += "</div>";
  return dat;
}

function CustomTables({state}){
  const allTables = state.invoice.invdata.invoice.all_tables;
  console.log(createTables(allTables)); 
    return (
      
        <div id='rawTablesContainer' dangerouslySetInnerHTML={{ __html: createTables(allTables) }} />
    
    )
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchsingleInvoice: (id) => dispatch(fetchsingleInvoice(id))
  };
};
const mapStateToProps = (state) => {
    return {
      state: state,
    };
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(CustomTables);