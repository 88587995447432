import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Dialog from "../Dialog/dialog";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import { connect, useDispatch } from 'react-redux';
import { Button } from "@material-ui/core";
import { allEmailInvoice, emailinvoicecount, singleIdFileDisplay, deleteInvoiceForSplit, emailinvoicecountsuccess, Email_Invoice_Success, analyzing_complete_failure, localfiledisplaywithid, localfiledisplaywithidfailure, analyzing_complete_success, Split_invoice, analyzing_invoice, Email_Invoice_Failure } from "../../redux"
import { Link } from 'react-router-dom';
import moment from "moment";
import Badge from 'react-bootstrap/Badge';
import Box from '@mui/material/Box';
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from 'axios';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { PDFDocument } from 'pdf-lib'
import Alert from "@mui/material/Alert";
import { Select, FormControl, InputLabel } from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import DangerousIcon from '@mui/icons-material/Dangerous';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import exportFromJSON from 'export-from-json'
import { fetchxlssuccess } from '../../redux'

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    // const location = document.location
    // if(array === undefined){
    //     location.reload()
    // }else{
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
    // }
}



const headCells = [
    { id: 'FileName', numeric: true, disablePadding: false, label: 'File Name' },
    { id: 'UploadDate', numeric: true, disablePadding: false, label: 'UploadDate' },
    { id: 'UploadTime', numeric: true, disablePadding: false, label: 'UploadTime' },
    // { id: 'Processed', numeric: true, disablePadding: false, label: 'Processed' },
    { id: 'NoOfInvoices', numeric: false, disablePadding: false, label: 'No of Invoices' },
    { id: 'NoOfPages', numeric: false, disablePadding: false, label: 'No of file Pages ' },
    { id: 'ProcessedStatus', numeric: false, disablePadding: false, label: 'Processed Status' },
    { id: 'FailCount', numeric: false, disablePadding: false, label: 'Fail Count' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Overall Status' },
    { id: 'Reviewer', numeric: true, disablePadding: false, label: 'Reviewer' }
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };


    return (
        <TableHead>
            <TableRow>
            </TableRow>
            <TableRow>
                <StyledTableCell padding="checkbox">
                    <Checkbox
                        style={{ color: 'white' }}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </StyledTableCell>
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        minHeight: 0
        // paddingLeft: theme.spacing(2),
        // paddingcenter: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },

}));
const userRole = localStorage.getItem("UserRole");


const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, handleDeleteRow, selectedId, handleProcessRow, numberfiles, prevNum } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">

                </Typography>
            )}
            {numSelected > 0 ? (
                <IconButton>
                    {userRole !== 'Admin' ? ''
                        :
                        <Button variant="text" color="primary" style={{ height: "5px", margin: '0 5px' }} className={classes.deletebutton} onClick={() => { handleDeleteRow(selectedId) }}>
                            <DeleteIcon></DeleteIcon>Delete
                        </Button>
                    }
                    {/* {numSelected <= 10 && numberfiles === 1 && prevNum <= 1? <Button variant="text" color="primary" className={classes.Processbutton} style={{height:"3px" , margin:'0 5px'}} onClick={() => {handleProcessRow(selectedId) }}>
                        <CheckCircleOutlineIcon />Process
                    </Button> : null }  */}
                </IconButton>
            ) : (
                <div></div>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    handleDelete: PropTypes.func.isRequired,
    selectedId: PropTypes.array.isRequired,
    handleProcess: PropTypes.func.isRequired,
    setNumberOfFile: PropTypes.number.isRequired,
    setPrevNumber: PropTypes.number.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginLeft: '-5px',
        marginBottom: '-5px',
        // marginBottom: theme.spacing(2),
        // marginLeft: theme.spacing(2),
    },
    table: {
        minWidth: 450
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    searchInput: {
        width: '30%',
        marginTop: '40px',
        marginLeft: 10
    },
    button: {
        float: 'center',
        background: '#0d6efd',
        marginTop: '40px',
        margincenter: 20
    }
}));

function EmailInvoiceTable({ state }) {
    useEffect(() => {
        document.title = "Split PDF"
    }, [])
    console.log(state)
    const classes = useStyles();
    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('PurchaseOrder');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        nameProduct: ""
    });
    const [fromDate, setFromDate] = useState('2021-01-01')
    const [toDate, setToDate] = useState(new Date())
    const [numberOfFile, setNumberOfFile] = useState(0)
    const [prevNumber, setPrevNumber] = useState(0)
    const [loadingDown, setLoadingDown] = useState(false);
    const [selectedDropDown, setSelectedDropDown] = useState('');
    const [status, setStatus] = useState("")
    const [loadingInvoice, setLoadingInvoice] = React.useState(false)
    const [loading, setLoading] = useState(false)
    const [load, setLoad] = useState(true)
    const [msg, setMsg] = useState('')
    const [stateUpdate, setStateUpdate] = useState(false);

    const selectionChangeHandler = (event) => {
        setSelectedDropDown(event.target.value);
    };
    const invoiceData = state.invoice
    const emaildata = invoiceData && invoiceData.emaildata
    const invdata = invoiceData && invoiceData.emailInvoiceCount
    console.log(invdata)
    let p=localStorage.getItem("pageNumEmailInvoices");
    let rowPer = localStorage.getItem("rowsPerPageEmailInvoices");

    window.onbeforeunload = function(event) {
        if (event.currentTarget.performance.navigation.type === 1) {
            // Code to execute when the page is being reloaded
            localStorage.setItem('pageNumEmailInvoices', 0);
            localStorage.setItem('rowsPerPageEmailInvoices', 10);
            console.log("Page is being reloaded");
        }
    };

    useEffect(async () => {
        if (stateUpdate) {
            if (emaildata.length > 0) {
                setLoadingInvoice(true)
            }
            setLoad(true)
            setMsg('Loading Page . . .')
            const token = window.localStorage.getItem("token");
            await axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/fetchemailinvoice/", {
                params: {
                    page: page,
                    limit: rowsPerPage,
                    "startDate": fromDate,
                    "endDate": toDate,
                    "status": status,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    dispatch(Email_Invoice_Success(response.data.data))
                    dispatch(emailinvoicecountsuccess(response.data.totalRecords))
                    setLoadingInvoice(false)
                    setLoad(false)
                    if (response.data.data.length === 0) {
                        setLoading(true)
                    }
                })
                .catch(error => {
                    const errorMsg = error.message
                    dispatch(Email_Invoice_Failure(errorMsg))
                    setLoadingInvoice(false)
                    setLoad(false)
                })
        }else{
            setPage(p);
            setRowsPerPage(rowPer)
         }
    }, [page, rowsPerPage])

    // useEffect(async () => {
    //     // const RemoveSelected = () => {
    //     //     setSelected([])
    //     //   };
    //     dispatch(emailinvoicecount())
    //     // window.addEventListener('blur', RemoveSelected);
    // }, [])

    const prevCountRef = useRef()
    useEffect(async () => {
        prevCountRef.current = numberOfFile

    }, [numberOfFile])
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = emaildata.map((n) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name, num) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        setNumberOfFile(num)
        if (prevCountRef.current > 0) {
            setPrevNumber(prevCountRef.current)
        }
    };

    const handleChangePage = (event, newPage) => {
        setStateUpdate(true)
        let num = parseInt(newPage);
        localStorage.setItem('pageNumEmailInvoices', num);
        setPage(num);
    };

    const handleChangeRowsPerPage = (event) => {
        setStateUpdate(true);
        let roP=parseInt(event.target.value, 10);
        localStorage.setItem('rowsPerPageEmailInvoices', event.target.value);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDialog = (message, isLoading, nameProduct) => {
        setDialog({
            message,
            isLoading,
            nameProduct
        });
    };

    const handleDelete = (selected) => {
        handleDialog("Are you sure you want to delete?", true);

    }

    const handleXlsDownload = async () => {
        setLoadingInvoice(true)
        setMsg('Please wait download in progress . . .')
        console.log(fromDate, toDate, 'Final Dates')
        const token = window.localStorage.getItem("token");
        const companyName = localStorage.getItem("companyName");
        let data = {
            "startDate": fromDate,
            "endDate": toDate,
            "companyName": companyName,
            "status": status,
            "target": 'email',
        }
        const req = {
            params: data,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
        console.log(req)
        await axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/xlsDownload", req).then(response => {
            console.log(response.data.data, 'down successful')
            const data = response.data.data
            const fileName = `Invoice Report${new Date().toDateString()}`
            const exportType = 'xls'
            setTimeout(() => {
                exportFromJSON({ data, fileName, exportType })
                setLoadingInvoice(false)
            }, 2000);
            dispatch(fetchxlssuccess(response.data))
        })
    }

    const areUSureDelete = (choose) => {
        if (choose) {
            dispatch(deleteInvoiceForSplit(selected, page, rowsPerPage))
            setSelected([])
            toast.success("Deleted Successfully!");
            handleDialog("", false);
        } else {
            handleDialog("", false);
            setSelected([])
        }
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, invdata - page * rowsPerPage);

    const handleFromDate = async (e) => {
        let myPastDate = new Date(e.target.value);
        function convert(myPastDate) {
            var date = new Date(myPastDate),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join("-");
        }
        var startDate = convert(myPastDate)
        var stringFromdate = 'fromDate'
        setFromDate(startDate)
        // handleDateSearch(startDate,stringFromdate)
        // var myPastDate = new Date(e.target.value);
        // myPastDate.setDate(myPastDate.getDate() - 1);
        // setFromDate(myPastDate)
    }

    const handleToDate = async (e) => {
        let myCurrentDate = new Date(e.target.value);
        function convert(myCurrentDate) {
            var date = new Date(myCurrentDate),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join("-");
        }
        var endDate = convert(myCurrentDate)
        var stringToDate = 'toDate'
        setToDate(endDate)
        // handleDateSearch(endDate, stringToDate)
    }

    const handleDateSearch = async (date, string) => {
        if (string === 'fromDate') {
            var startDate = date
        }
        if (string === 'toDate') {
            var endDate = date
        }
        console.log(fromDate, toDate, 'Final Dates')
        const token = window.localStorage.getItem("token");
        let data = {
            "startDate": startDate || fromDate,
            "endDate": endDate || toDate,
            page: 0,
            limit: rowsPerPage,
        }
        const req = {
            params: data,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
        console.log(req)
        await axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/fetchemailinvoice/", req).then(response => {
            console.log(response.data, 'Search successful Date Filter')
            dispatch(Email_Invoice_Success(response.data.data))
            dispatch(emailinvoicecountsuccess(response.data.totalRecords))
        })
    }

    // const handleProcess = async() => {
    //     for(let i =0; i< selected.length; i++){
    //     setLoadingDown(true);
    //     let id = selected[i]
    //     let selectedNumber = i
    //     const token = window.localStorage.getItem("token");
    //     axios.get(process.env.REACT_APP_BACKEND_API + `api/invoice/splitlocalfile/${selected[i]}`, {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //       }).then(response=> {
    //         dispatch(localfiledisplaywithid(response.data))
    //        processfile(response.data.fileUrl, response.data.invoice.file_Name, id ,selectedNumber )
    //         })
    //         .catch(error=>{
    //             setLoadingDown(false);
    //             const errorMsg=error.message
    //             dispatch(localfiledisplaywithidfailure(errorMsg))
    //             toast.error(errorMsg)
    //           })
    //     }
    // }
    const handleProcess = async () => {

        for (let i = 0; i < selected.length; i++) {
            setLoadingDown(true);
            setMsg('Please wait processing file in progress . . .')
            let id = selected[i]
            let selectedNumber = i
            const token = window.localStorage.getItem("token");
            await axios.get(process.env.REACT_APP_BACKEND_API + `api/invoice/splitlocalfile/${selected[i]}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(response => {
                dispatch(localfiledisplaywithid(response.data))
                processfile(response.data.fileUrl, response.data.invoice.file_Name, id, selectedNumber)
            })
                .catch(error => {
                    const errorMsg = error.message
                    dispatch(localfiledisplaywithidfailure(errorMsg))
                    toast.error(errorMsg)
                })
        }
    }

    const processfile = async (fileUrl, fileName, id, selectedNumber) => {
        try {
            const selectedNumber = ['1']
            const url1 = fileUrl
            const firstDonorPdfBytes = await fetch(url1).then(res => res.arrayBuffer())
            const firstDonorPdfDoc = await PDFDocument.load(firstDonorPdfBytes)
            const pdfDoc = await PDFDocument.create();
            for (let i = 0; i < selectedNumber.length; i++) {
                const [firstDonorPage] = await pdfDoc.copyPages(firstDonorPdfDoc, [selectedNumber[i] - 1])
                pdfDoc.addPage(firstDonorPage)
            }
            const pdfBytes = await pdfDoc.save()
            const blob = new Blob([pdfBytes], { type: "application/pdf" })
            blob.lastModifiedDate = new Date();
            blob.name = fileName
            const blobUrl = URL.createObjectURL(blob)
            const token = window.localStorage.getItem("token")
            const pages = "1"
            sendForAnalyzeSplitPdf(blob, blobUrl, token, true, id, pages)
            updateSplitting(id)

            const img = document.createElement('img');
            img.src = blobUrl;
            document.body.appendChild(img)
        } catch {
            alert(`${selectedNumber + 1}th selected Pdf is Encrypted Please try to downlaod first and then Process it`)
        }
    }
    const sendForAnalyzeSplitPdf = async (file, fileUrl, token, isFromSplit = false, splitRecordId, pages) => {
        setLoadingDown(true);
        dispatch(analyzing_invoice);
        let formData = new FormData();
        formData.append("file", file, fileUrl);
        formData.append("name", file.name.split(".")[0]);
        formData.append("ext", file.name.split(".")[1]);
        formData.append("isFromSplit", isFromSplit);
        formData.append("splitRecordId", splitRecordId);
        formData.append("pages", pages);
        let url = process.env.REACT_APP_BACKEND_API + "api/analyze/splitpdfprocess";
        if (localStorage.getItem("modelType") === "Enterprise") {
            url = process.env.REACT_APP_BACKEND_API + "api/analyze/enterprise";
        }
        await axios
            .post(url, formData, {
                headers: {
                    "content-type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                dispatch(analyzing_complete_success(res));
                setLoadingDown(false);
            })
            .catch((error) => {
                const errorMsg = error.message;
                dispatch(analyzing_complete_failure(errorMsg));
            });
    }

    const updateSplitting = async (id, data) => {
        const token = window.localStorage.getItem("token");
        await axios.put(process.env.REACT_APP_BACKEND_API + `api/invoice/updatesplitted/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },

        })
            .then(res => {
                dispatch(Split_invoice(res.data))
            })
            .catch(error => {
                console.log(error)
            })

    }

    const cancelSearch = () => {
        document.getElementById("fromDateSearch").value = "";
        document.getElementById("toDateSearch").value = "";
        setFromDate('2021-01-01')
        setToDate(new Date())
        setPage(0)
        setStatus("")
        setSelectedDropDown('')
        setRowsPerPage(10)
        setSelected([])
        // dispatch(allEmailInvoice(page, rowsPerPage, '2021-01-01', toDate))
        setLoadingDown(true)
        setMsg('Please wait removing filters . . .')
        const token = window.localStorage.getItem("token");
        axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/fetchemailinvoice/", {
            params: {
                page: page,
                limit: rowsPerPage,
                "startDate": '2021-01-01',
                "endDate": toDate,
                "status": "",
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                dispatch(Email_Invoice_Success(response.data.data))
                dispatch(emailinvoicecountsuccess(response.data.totalRecords))
                if (response.data.totalRecords > 0) {
                    setLoading(false)
                }
                setLoadingDown(false)

            })
            .catch(error => {
                const errorMsg = error.message
                dispatch(Email_Invoice_Failure(errorMsg))
                setLoadingDown(false)
            })
    };
    const handleIconSearch = async () => {
        setLoadingDown(true)
        setMsg('Please wait Fetching invoices in progress . . .')
        const token = window.localStorage.getItem("token");
        let data = {
            "startDate": fromDate,
            "endDate": toDate,
            "status": status,
            page: 0,
            limit: rowsPerPage,
        }
        const req = {
            params: data,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
        await axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/fetchemailinvoice/", req).then(response => {
            dispatch(Email_Invoice_Success(response.data.data))
            dispatch(emailinvoicecountsuccess(response.data.totalRecords))
            if (response.data.totalRecords == 0) {
                setLoading(true)
            }
            setLoadingDown(false)
        })
        setPage(0)
    }

    const min = Date.parse(fromDate);

    const max = Date.parse(toDate);

    const handleProcessed = () => {
        setStatus("Processed")
    };
    const handleFail = () => {
        setStatus("Failed")
    };
    const handleNotProcessed = () => {
        setStatus("NotProcessed")
    };
    return (
        <div className={classes.root}>
            {loadingDown && (
                <div
                    style={{
                        position: "absolute",
                        top: "100px",
                        left: "40vw",
                        display: "flex",
                        justifyContent: "center",
                        zIndex: 22,
                    }}
                >
                    <Alert severity="info">
                        {msg}
                    </Alert>
                </div>
            )}
            {
                loadingInvoice && (<div style={{
                    position: 'absolute',
                    top: '83vh',
                    left: '40vw',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Alert severity="info">{msg}</Alert>
                </div>)
            }
            {
                loading && (<div style={{
                    position: 'absolute',
                    top: '400px',
                    left: '40vw',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <div className="loader">No Data Found </div>
                </div>)
            }
            <div style={{ display: 'flex', 'margin-top': '-54px', 'margin-left': '16rem', position: 'absolute' }}>
                <FormControl style={{
                    backgroundColor: 'white',
                    padding: '1px 1px',
                    borderRadius: '3px',
                    // border: '1px solid black',
                    margin: '0px 15px 5px 15px',
                    width: '160px',
                    textTransform: 'uppercase'
                }} >
                    <InputLabel>Status</InputLabel>
                    <Select value={selectedDropDown} onChange={selectionChangeHandler} style={{ fontSize: "15px" }} id='status'>
                        <MenuItem value={1} onClick={handleProcessed} ><DownloadDoneIcon style={{ fontSize: 20, padding: "2px", color: "green" }} />Processed</MenuItem>
                        <MenuItem value={2} onClick={handleFail}><DangerousIcon style={{ fontSize: 20, padding: "2px", color: "red" }} />Failed</MenuItem>
                        <MenuItem value={3} onClick={handleNotProcessed}><RemoveCircleOutlineIcon style={{ fontSize: 20, padding: "2px", color: "grey" }} />Not Processed</MenuItem>
                    </Select>
                </FormControl>

                <input type='date' style={{
                    backgroundColor: 'white',
                    padding: '3px 1px',
                    borderRadius: '3px',
                    border: '2px solid black',
                    width: '140px',
                    margin: '10px 10px',
                    textTransform: 'uppercase',
                    fontSize: '15px'
                }}
                    onChange={handleFromDate}
                    id='fromDateSearch'
                // onChange={e => setFromDate(e.target.value)}
                />
                <Typography style={{ margin: '10px' }} variant="h6" id="tableTitle" component="div" >
                    To
                </Typography>
                <input type='date' style={{
                    backgroundColor: 'white',
                    padding: '3px 1px',
                    borderRadius: '3px',
                    border: '2px solid black',
                    margin: '10px 10px',
                    width: '140px',
                    textTransform: 'uppercase',
                    fontSize: '15px'
                }}

                    // onChange={e => setToDate(e.target.value)}
                    onChange={handleToDate}
                    id='toDateSearch'
                />
                <div className='search-icon' style={{ position: 'relative', display: 'flex', 'margin-right': '3px', 'margin-left': '5px' }}><Button><SearchIcon onClick={handleIconSearch} /></Button></div>
                <div className='search-icon' style={{ position: 'relative', display: 'flex' }}><Button><ClearIcon onClick={cancelSearch} /></Button></div>
                <button id="test-table-xls-button" style={{ padding: 0 }} type="button" onClick={handleXlsDownload}>XLS Download</button>

            </div>


            <ToastContainer />
            <Paper className={classes.paper}>
                <EnhancedTableToolbar numSelected={selected.length} selectedId={selected} handleDeleteRow={handleDelete} handleProcessRow={handleProcess} numberfiles={numberOfFile} prevNum={prevNumber} />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='small'
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={invdata}
                            numberfiles={numberOfFile}
                            prevNum={prevNumber}
                        />
                        {emaildata <= 0 && !loading ? (
                            <Box sx={{ display: 'flex', position: 'absolute', width: '90px', marginLeft: '50vw', marginTop: '6vh' }}>
                                <CircularProgress color="secondary" size={70} />
                            </Box>
                        ) : null}
                        <TableBody>
                            {emaildata !== undefined ? stableSort(emaildata, getComparator(order, orderBy))
                                //.filter(daterange => {
                                // const uploadedDate = moment(daterange.createdAt).utc().local().format('MM/DD/YYYY');
                                //console.log(uploadedDate, "this is uploaded date", Date.parse(uploadedDate), min, max)
                                //return (Date.parse(uploadedDate) >= min) && ((Date.parse(uploadedDate)) <= max)
                                // })
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row._id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const timestamp = row.createdAt;
                                    const uploadedDate = moment(timestamp).utc().local().format('DD-MM-YYYY');
                                    const uploadedTime = moment(timestamp).utc().local().format('HH:mm:ss');
                                    console.log(row.is_splitted)
                                    //const processed=row.is_splitted?<DoneRoundedIcon style={{color:"green"}}/>:<CancelIcon style={{color:"orange"}}/>
                                    const extension = row.file_Name.split(".").pop()
                                    const noOfInvoices = row.splittedInvoices.length
                                    const noOfPages = row.numPages
                                    const processedStatus = row.splittedInvoices.map((row) =>
                                        row.status
                                    )
                                    let processedCount = 0
                                    let failCount = 0
                                    let processingCount = 0
                                    let status = 'Not Processed Yet'
                                    processedStatus.filter((name) => {
                                        if (name === 'Processed') {
                                            processedCount++;
                                        }
                                    })
                                    processedStatus.filter((name) => {
                                        if (name === 'Failed') {
                                            failCount++;
                                        }
                                    })
                                    processedStatus.filter((name) => {
                                        if (name === 'Processing') {
                                            processingCount++;
                                        }
                                    })
                                    {/* if(failCount === 0 && processedCount > 0 && processingCount === 0){
                                        status = 'Processed'
                                    }else if(failCount > 0){
                                        status = 'Failed'
                                    }else if(processingCount > 0){
                                        status = 'Processing'
                                    }else{
                                        status = 'Not Processed Yet'
                                    } */}
                                    if (failCount === 0 && processedCount > 0 && processingCount === 0) {
                                        status = 'Processed'
                                    } else if (processedCount > 0 && failCount > 0) {
                                        status = 'Processed'
                                    }
                                    else if (failCount > 0 && processedCount === 0) {
                                        status = 'Failed'
                                    } else if (processingCount > 0) {
                                        status = 'Processing'
                                    } else {
                                        status = 'Not Processed Yet'
                                    }
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row._id, row.numPages)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row._id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" align="center" padding="none">
                                                <Link target="_blank" to={(extension === "pdf" || extension === "PDF" || extension === "Pdf" || extension.length>4
                                                ) ? `/splitlocalpdf/${row._id}` : `/imageProcessing/${row._id}`}> {row.file_Name} </Link>

                                            </TableCell>
                                            <TableCell align="center">{uploadedDate}</TableCell>
                                            <TableCell align="center">{uploadedTime}</TableCell>
                                            <TableCell align="center">{noOfInvoices}</TableCell>
                                            <TableCell align="center">{noOfPages}</TableCell>
                                            <TableCell align="center">{processedCount}/{noOfInvoices}</TableCell>
                                            <TableCell align="center">{failCount}</TableCell>
                                            <TableCell align="center">{
                                                status === "Processing" ? <Badge pill bg="primary">
                                                    {status}
                                                </Badge> :
                                                    status === "Processed" ? <Badge pill bg="success" >{status}</Badge> :
                                                        status === "Failed" ? <Badge pill bg="danger">{status}</Badge> :
                                                            <Badge pill bg="light" text="dark">{status}</Badge>

                                            }</TableCell>
                                            <TableCell align="center">{row.reviewerName}</TableCell>


                                        </TableRow>
                                    );
                                }) : (<></>)}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 30]}
                    component="div"
                    count={invdata}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {dialog.isLoading && (
                <Dialog
                    onDialog={areUSureDelete}
                    message={dialog.message}
                />
            )}
        </div>

    );
}

const mapStateToProps = (state) => {
    return {
        state: state,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        allEmailInvoice: (page, rowsPerPage) => dispatch(allEmailInvoice(page, rowsPerPage)),
        emailinvoicecount: () => dispatch(emailinvoicecount()),
        singleIdFileDisplay: (id, state) => dispatch(singleIdFileDisplay(id, state))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailInvoiceTable)