import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { ToastContainer, toast } from 'react-toastify';
import { IconButton } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { unapprovedInvoice, fetchxlssuccess, BulkPushInvoice, fetchUnApprovedinvoice, UnApprovedinvoicecount, fetchinvoicefailure } from "../../redux"
import { Link } from 'react-router-dom';
import ForwardIcon from '@mui/icons-material/Forward';
import moment from "moment";
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import Badge from 'react-bootstrap/Badge';
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Alert from '@mui/material/Alert';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from '@mui/material/MenuItem';
import { Select, FormControl, InputLabel } from '@material-ui/core';
import exportFromJSON from 'export-from-json'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DangerousIcon from '@mui/icons-material/Dangerous';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import * as XLSX from 'xlsx/xlsx.mjs';
import FileDownloadIcon from '@mui/icons-material/FileDownload';


const companyName = localStorage.getItem("companyName");

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    if (array === undefined) {
        window.location.reload()
    } else {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
}



let headCells = [
    { id: 'InvoiceID', numeric: true, disablePadding: true, label: 'Invoice ID' },
    { id: 'PurchaseOrder', numeric: true, disablePadding: true, label: 'Purchase Order' },
    { id: 'SupplierName', numeric: true, disablePadding: false, label: 'Supplier Name' },
    { id: 'InvoiceDate', numeric: true, disablePadding: false, label: 'Invoice Date' },
    { id: 'InvoiceTotal ', numeric: false, disablePadding: false, label: 'Invoice Total' },
    { id: 'Upload Date/Time', numeric: false, disablePadding: false, label: 'Date & Time' },
    { id: 'Pushed', numeric: true, disablePadding: false, label: 'Pushed Status' },
    { id: 'Reviewer', numeric: true, disablePadding: false, label: 'Reviewer' },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };


    return (
        <TableHead>
            <TableRow>
            </TableRow>
            <TableRow>
                <StyledTableCell padding="checkbox">
                    <Checkbox
                        style={{ color: 'white' }}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </StyledTableCell>
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        minHeight: 0
        // paddingLeft: theme.spacing(2),
        // paddingcenter: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    deletebutton: {
        marginRight: 20
    }

}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, selectedId, handleBulkPushInvoice, isPushed, handleDownloadxls } = props;
    let FormateType = null
    if (localStorage.getItem("Formate")) {
        FormateType = localStorage.getItem("Formate")
    }
    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">

                </Typography>
            )}
            {numSelected > 0 ? (
                <IconButton >
                    {numSelected < 11 ?
                        <Button variant="text" color="primary" className={classes.deletebutton} disabled={isPushed} onClick={() => { handleBulkPushInvoice(selectedId) }}>
                            <ForwardIcon></ForwardIcon>Push invoice
                        </Button> : null}
                    {numSelected < 11 ?
                        <Button variant="text" color="primary" className={classes.deletebutton} onClick={() => { handleDownloadxls(selectedId) }} >
                            <FileDownloadIcon></FileDownloadIcon>download {FormateType}
                        </Button> : null}
                </IconButton>
            ) : (
                <div></div>
            )}

        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    selectedId: PropTypes.array.isRequired,
    handleBulkPush: PropTypes.func.isRequired,
    handleDownloadxls: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginLeft: '-5px',
        marginBottom: '-5px',
        // marginBottom: theme.spacing(2),
        // marginLeft: theme.spacing(2),
    },
    table: {
        minWidth: 450
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    searchInput: {
        width: '30%',
        marginTop: '40px',
        marginLeft: 10
    },
    button: {
        float: 'center',
        background: '#0d6efd',
        marginTop: '40px',
        margincenter: 20
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        marginLeft: '50px',
        marginTop: '10px',
        marginBottom: "10px",
        width: '320px',
        display: 'flex',
        border: '1px solid #757575'
    },
    maint: {
        display: "flex"
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        right: '0',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        backgroundColor: '#1266f1',
        color: 'white',
        cursor: "pointer",
        '&:hover': {
            background: "#f00",
        }
    }

}));

function UnApprovedInvoiceTable({ state }) {
    console.log(state)
    const classes = useStyles();
    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('PurchaseOrder');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [role, setRole] = React.useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [vendorName, setVendorName] = useState(null);
    const [reviewer, setReviewer] = useState("");
    const [invoiceNo, setInvoiceNo] = useState(null);
    const [rows, setRows] = useState([]);
    const [isPushed, setisPushed] = useState(false)
    const [fromDate, setFromDate] = useState('2021-01-01')
    const [toDate, setToDate] = useState(new Date())
    const [allSelected, setAllSelected] = useState(false)
    const [loadingDown, setLoadingDown] = React.useState(false)
    const [loadingInvoice, setLoadingInvoice] = React.useState(false)
    const [status, setStatus] = useState("")
    const [msg, setMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [selectedDropDown, setSelectedDropDown] = useState('');
    const [stateUpdate, setStateUpdate] = useState(false);
    const invoiceData = state.invoice
    let FormateType = invoiceData && invoiceData.DownloadType
    const approveddata = invoiceData && invoiceData.unapprovedinvoice && invoiceData.unapprovedinvoice.data
    console.log(approveddata, 'Fetched Approved Data')
    const invdata = invoiceData && invoiceData.UnApproveInvoiceCount
    console.log(invdata, 'invdata')

    React.useEffect(() => {
        setRole(localStorage.getItem("UserRole"));
    }, []);
    const rolApi = localStorage.getItem("UserRole");
    let p = localStorage.getItem("pageNumUnAppInvoices");
    let rowPer = localStorage.getItem("rowsPerPageUnApprovedInvoices");
    window.onbeforeunload = function(event) {
        if (event.currentTarget.performance.navigation.type === 1) {
            // Code to execute when the page is being reloaded
            localStorage.setItem('pageNumUnAppInvoices', 0);
            localStorage.setItem('rowsPerPageUnApprovedInvoices', 10);
            console.log("Page is being reloaded");
        }
    };
    useEffect(async () => {
        // dispatch(approvedInvoice(page, rowsPerPage, vendorName, invoiceNo, fromDate, toDate, status))
        if (stateUpdate) {
            if (approveddata !== undefined) {
                setLoadingDown(true)
            }
            setMsg('Loading Page . . .')
            const token = window.localStorage.getItem("token");
            await axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/fetchInvoices", {
                params: {
                    page: page,
                    limit: rowsPerPage,
                    "invoiceId": invoiceNo,
                    "startDate": fromDate,
                    "endDate": toDate,
                    "vendorName": vendorName,
                    "status": rolApi == "Client" ? "Pushed" : status,
                    "isApproved": false,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
                .then(response => {
                    dispatch(fetchUnApprovedinvoice(response.data))
                    dispatch(UnApprovedinvoicecount(response.data.totalRecords))
                    setLoadingDown(false)
                })
                .catch(error => {
                    const errorMsg = error.message
                    dispatch(fetchinvoicefailure(errorMsg))
                    setLoadingDown(false)
                })
        } else {
            setPage(p);
            setRowsPerPage(rowPer)
        }
    }, [page, rowsPerPage])



    // useEffect(async () => {
    //     dispatch(approvedinvoicecount())
    // }, [])

    if (role === 'Client') {
        headCells = [
            { id: 'InvoiceNo.', numeric: true, disablePadding: true, label: 'InvoiceNo.' },
            { id: 'PurchaseOrder', numeric: true, disablePadding: true, label: 'PurchaseOrder' },
            { id: 'SupplierName', numeric: true, disablePadding: false, label: 'Supplier Name' },
            { id: 'InvoiceTotal ', numeric: false, disablePadding: false, label: 'InvoiceTotal' },
            { id: 'Upload Date/Time', numeric: false, disablePadding: false, label: 'Date & Time' },
            { id: 'Pushed', numeric: true, disablePadding: false, label: 'Status' },
        ];
    }


    console.log(invoiceData, 'Approved Updated Rows')

    const selectionChangeHandler = (event) => {
        setSelectedDropDown(event.target.value);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        console.log(event.target.checked, 'hey')
        if (!allSelected) {
            const newSelecteds = approveddata.map((n) => n._id);
            setSelected(newSelecteds.concat(selected));
            setAllSelected(true)
            console.log(newSelecteds)
        } else {
            setSelected([]);
            setAllSelected(false)
        }
    };

    const handleBulkPush = (selected) => {
        console.log("bulkpushApproved")
        console.log(selected, 'Approved Bulk Push')
        dispatch(BulkPushInvoice(selected, page, rowsPerPage))
        setSelected([])
        // setisPushed(true)
        toast.success("Pushed Successfully!");
    }

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    console.log(selected, 'Selected Approved Invoices')

    const handleChangePage = (event, newPage) => {
        setStateUpdate(true);
        let num = parseInt(newPage);
        localStorage.setItem('pageNumUnAppInvoices', num);
        setPage(num);
        //setPage(newPage);
        setAllSelected(false)
    };

    const handleChangeRowsPerPage = (event) => {
        setStateUpdate(true)
        localStorage.setItem('rowsPerPageUnApprovedInvoices', event.target.value);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleDownloadxls = (selected) => {
        if (localStorage.getItem("Formate")) {
            FormateType = localStorage.getItem("Formate")
        }
        if (FormateType === 'WorkBook') {
            var wb = XLSX.utils.book_new();
            var xlsData = []

            for (let i = 0; i < selected.length; i++) {

                let filteredValue = approveddata.filter((element, index) => {
                    return element._id === selected[i]
                });
                let dataLength = Object.keys(filteredValue[0].extractedData.Line_Items.data).length
                for (let j = 1; j <= dataLength; j++) {
                    var UnitVal = ""
                    var Product_CodeValue = ""
                    var Description = ""
                    var Quantity = ""
                    var Unit_Price = ""
                    var Amount = ""
                    if (filteredValue[0].extractedData.Line_Items.data[j].properties.Unit) {
                        UnitVal = filteredValue[0].extractedData.Line_Items.data[j].properties.Unit.value
                    }
                    if (filteredValue[0].extractedData.Line_Items.data[j].properties.ProductCode) {
                        Product_CodeValue = filteredValue[0].extractedData.Line_Items.data[j].properties.ProductCode.value
                    }
                    if (filteredValue[0].extractedData.Line_Items.data[j].properties.Description) {
                        Description = filteredValue[0].extractedData.Line_Items.data[j].properties.Description.value
                    }
                    if (filteredValue[0].extractedData.Line_Items.data[j].properties.Quantity) {
                        Quantity = filteredValue[0].extractedData.Line_Items.data[j].properties.Quantity.value
                    }
                    if (filteredValue[0].extractedData.Line_Items.data[j].properties.UnitPrice) {
                        Unit_Price = filteredValue[0].extractedData.Line_Items.data[j].properties.UnitPrice.value
                    }
                    if (filteredValue[0].extractedData.Line_Items.data[j].properties.Amount) {
                        Amount = filteredValue[0].extractedData.Line_Items.data[j].properties.Amount.value
                    }
                    var newObj = {
                        Invoice_No: filteredValue[0].extractedData.InvoiceId,
                        PO_Number: filteredValue[0].extractedData.PurchaseOrder,
                        Invoice_Date: filteredValue[0].extractedData.InvoiceDate,
                        Supplier_Name: filteredValue[0].extractedData.VendorName,
                        Supplier_GSTN: filteredValue[0].extractedData.Supplier_GSTN,
                        Invoice_Total: filteredValue[0].extractedData.InvoiceTotal,
                        Invoice_Type: filteredValue[0].extractedData.InvoiceType,
                        Currency: filteredValue[0].extractedData.Currency,
                        Product_Code: Product_CodeValue,
                        Description: Description,
                        Quantity: Quantity,
                        Unit: UnitVal,
                        Unit_Price: Unit_Price,
                        Amount: Amount,
                        CGST_P: filteredValue[0].extractedData.CGST_P,
                        IGST_P: filteredValue[0].extractedData.IGST_P,
                        SGST_P: filteredValue[0].extractedData.SGST_P,
                        CGST_Value: filteredValue[0].extractedData.CGST_Value,
                        IGST_Value: filteredValue[0].extractedData.IGST_Value,
                        SGST_Value: filteredValue[0].extractedData.SGST_Value,
                        UGST_P: filteredValue[0].extractedData.UGST_P,
                        VAT_P: filteredValue[0].extractedData.VAT_P,
                        CESS_P: filteredValue[0].extractedData.CESS_P,
                        UGST_Value: filteredValue[0].extractedData.UGST_Value,
                        VAT_Value: filteredValue[0].extractedData.VAT_Value,
                        CESS_Value: filteredValue[0].extractedData.CESS_Value,
                        TCS_P: filteredValue[0].extractedData.TCS_P,
                        TCS_Value: filteredValue[0].extractedData.TCS_Value,
                        TotalTax: filteredValue[0].extractedData.TotalTax,
                        ...(companyName == "BDO" && { Supplier_Address: filteredValue[0].extractedData.VendorAddress }),
                        ...(companyName == "BDO" && { Customer_Name: filteredValue[0].extractedData.CustomerName }),
                        ...(companyName == "BDO" && { Customer_GSTN: filteredValue[0].extractedData.Customer_GSTN }),
                        ...(companyName == "BDO" && { Customer_Address: filteredValue[0].extractedData.Customer_Address }),
                    }
                    xlsData.push(newObj)
                }

            }
            const data = xlsData
            var ws = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(wb, ws, ' Sheet');
            const filename = `Report${new Date().toDateString()}.xls`;
            XLSX.writeFile(wb, filename);
        } else if (FormateType === 'Sheet') {
            var wb = XLSX.utils.book_new();

            for (let i = 0; i < selected.length; i++) {

                let filteredValue = approveddata.filter((element, index) => {
                    return element._id === selected[i]
                });
                let dataLength = Object.keys(filteredValue[0].extractedData.Line_Items.data).length


                let xlsData = []
                for (let j = 1; j <= dataLength; j++) {
                    var UnitVal = ""
                    var Product_CodeValue = ""
                    var Description = ""
                    var Quantity = ""
                    var Unit_Price = ""
                    var Amount = ""
                    if (filteredValue[0].extractedData.Line_Items.data[j].properties.Unit) {
                        UnitVal = filteredValue[0].extractedData.Line_Items.data[j].properties.Unit.value
                    }
                    if (filteredValue[0].extractedData.Line_Items.data[j].properties.ProductCode) {
                        Product_CodeValue = filteredValue[0].extractedData.Line_Items.data[j].properties.ProductCode.value
                    }
                    if (filteredValue[0].extractedData.Line_Items.data[j].properties.Description) {
                        Description = filteredValue[0].extractedData.Line_Items.data[j].properties.Description.value
                    }
                    if (filteredValue[0].extractedData.Line_Items.data[j].properties.Quantity) {
                        Quantity = filteredValue[0].extractedData.Line_Items.data[j].properties.Quantity.value
                    }
                    if (filteredValue[0].extractedData.Line_Items.data[j].properties.UnitPrice) {
                        Unit_Price = filteredValue[0].extractedData.Line_Items.data[j].properties.UnitPrice.value
                    }
                    if (filteredValue[0].extractedData.Line_Items.data[j].properties.Amount) {
                        Amount = filteredValue[0].extractedData.Line_Items.data[j].properties.Amount.value
                    }
                    var newObj = {
                        Invoice_No: filteredValue[0].extractedData.InvoiceId,
                        PO_Number: filteredValue[0].extractedData.PurchaseOrder,
                        Invoice_Date: filteredValue[0].extractedData.InvoiceDate,
                        Supplier_Name: filteredValue[0].extractedData.VendorName,
                        Supplier_GSTN: filteredValue[0].extractedData.Supplier_GSTN,
                        Invoice_Total: filteredValue[0].extractedData.InvoiceTotal,
                        Invoice_Type: filteredValue[0].extractedData.InvoiceType,
                        Currency: filteredValue[0].extractedData.Currency,
                        Product_Code: Product_CodeValue,
                        Description: Description,
                        Quantity: Quantity,
                        Unit: UnitVal,
                        Unit_Price: Unit_Price,
                        Amount: Amount,
                        CGST_P: filteredValue[0].extractedData.CGST_P,
                        IGST_P: filteredValue[0].extractedData.IGST_P,
                        SGST_P: filteredValue[0].extractedData.SGST_P,
                        CGST_Value: filteredValue[0].extractedData.CGST_Value,
                        IGST_Value: filteredValue[0].extractedData.IGST_Value,
                        SGST_Value: filteredValue[0].extractedData.SGST_Value,
                        UGST_P: filteredValue[0].extractedData.UGST_P,
                        VAT_P: filteredValue[0].extractedData.VAT_P,
                        CESS_P: filteredValue[0].extractedData.CESS_P,
                        UGST_Value: filteredValue[0].extractedData.UGST_Value,
                        VAT_Value: filteredValue[0].extractedData.VAT_Value,
                        CESS_Value: filteredValue[0].extractedData.CESS_Value,
                        TCS_P: filteredValue[0].extractedData.TCS_P,
                        TCS_Value: filteredValue[0].extractedData.TCS_Value,
                        TotalTax: filteredValue[0].extractedData.TotalTax,
                        ...(companyName == "BDO" && { Supplier_Address: filteredValue[0].extractedData.VendorAddress }),
                        ...(companyName == "BDO" && { Customer_Name: filteredValue[0].extractedData.CustomerName }),
                        ...(companyName == "BDO" && { Customer_GSTN: filteredValue[0].extractedData.Customer_GSTN }),
                        ...(companyName == "BDO" && { Customer_Address: filteredValue[0].extractedData.Customer_Address }),
                    }
                    xlsData.push(newObj)
                }
                const data = xlsData
                var ws = XLSX.utils.json_to_sheet(data);
                XLSX.utils.book_append_sheet(wb, ws, `Sheet ${i}`);
            }
            const filename = `Report${new Date().toDateString()}.xls`;
            XLSX.writeFile(wb, filename);
        } else {
            for (var i = 0; i < selected.length; i++) {

                // eslint-disable-next-line no-loop-func
                approveddata.map((inv) => {
                    if (inv._id === selected[i]) {
                        var data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(inv.extractedData));
                        //   console.log(singleinvoice&&  singleinvoice.invdata && singleinvoice.invdata.invoice && singleinvoice.invdata.invoice.data)
                        var downl = "data:" + data;
                        var dFileName = inv.file_Name[0].split(".")[0] + " - SigiXtract.json";
                        console.log(dFileName)
                        const link = document.createElement('a');
                        link.href = downl;
                        link.setAttribute(
                            'download',
                            dFileName
                        )
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link)


                    }
                })
            }
        }
    }
    // const handleDateSearch = async(e)=> {
    //     const todate = e.target.value
    //     console.log(todate, 'Final')
    //     setToDate(todate)
    //     handleFromDate();
    // }

    // const handleFromDate = async(e) => {
    //     const token = window.localStorage.getItem("token");
    //     let myPastDate = new Date(e.target.value);
    //     function convert(myPastDate) {
    //         var date = new Date(myPastDate),
    //         mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //         day = ("0" + date.getDate()).slice(-2);
    //         return [date.getFullYear(), mnth, day].join("-");
    //     }
    //     let startDate = convert(myPastDate)
    //     let endDate = toDate
    //     console.log(startDate, endDate, 'Finaldate')

    //     let data = {
    //         "invoiceId" : null,
    //         "startDate" : startDate,  
    //         "endDate" : endDate,  
    //         "vendorName" : null,  
    //         "isApproved" : true,
    //         page: page,
    //         limit:rowsPerPage,
    //     }
    //     const req = {
    //         params: data, 
    //         headers: {
    //         Authorization: `Bearer ${token}`,
    //         }
    //     }
    // console.log(req)
    // await axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/fetchInvoices", req).then(response=> {
    //     console.log(response,'Search successful')
    //     dispatch(fetchUnApprovedinvoice(response.data))
    // })
    // }

    const handleFromDate = async (e) => {
        let myPastDate = new Date(e.target.value);
        function convert(myPastDate) {
            var date = new Date(myPastDate),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join("-");
        }
        var startDate = convert(myPastDate)
        var stringFromdate = 'fromDate'
        setFromDate(startDate)
        // handleDateSearch(startDate,stringFromdate)
    }

    const handleToDate = async (e) => {
        let myCurrentDate = new Date(e.target.value);
        function convert(myCurrentDate) {
            var date = new Date(myCurrentDate),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join("-");
        }
        var endDate = convert(myCurrentDate)
        var stringToDate = 'toDate'
        setToDate(endDate)
        // handleDateSearch(endDate, stringToDate)
    }

    const handleDateSearch = async (date, string) => {
        if (string === 'fromDate') {
            var startDate = date
        }
        if (string === 'toDate') {
            var endDate = date
        }
        console.log(fromDate, toDate, 'Final Dates')
        setLoadingInvoice(true)
        setMsg('Please wait Fetching invoices in progress . . .')
        const token = window.localStorage.getItem("token");
        let data = {
            "invoiceId": invoiceNo,
            "startDate": startDate || fromDate,
            "endDate": endDate || toDate,
            "vendorName": vendorName,
            "status": rolApi == "Client" ? "Pushed" : status,
            "isApproved": false,
            page: page,
            limit: rowsPerPage,
        }
        const req = {
            params: data,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
        console.log(req)
        await axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/fetchInvoices", req).then(response => {
            console.log(response, 'Search successful')
            dispatch(fetchUnApprovedinvoice(response.data))
            dispatch(UnApprovedinvoicecount(response.data.totalRecords))
            setLoadingInvoice(false)
        })
    }
    const handlePushed = () => {
        setStatus("Pushed")
    };
    const handlePushing = () => {
        setStatus("Pushing")
    };
    const handleUnPushed = () => {
        setStatus("not pushed")
    };
    const companyName = localStorage.getItem("companyName");

    const handleXlsDownload = async () => {
        setLoadingDown(true)
        setMsg('Please wait download in progress . . .')
        console.log(fromDate, toDate, 'Final Dates')
        const token = window.localStorage.getItem("token");
        let data = {
            "startDate": fromDate,
            "endDate": toDate,
            "companyName": companyName,
            "status": rolApi == "Client" ? "Pushed" : status,
            "target": "home",
        }
        const req = {
            params: data,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
        console.log(req)
        await axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/xlsDownload", req).then(response => {
            console.log(response.data.data, 'down successful')
            const data = response.data.data
            const fileName = `Invoice Report${new Date().toDateString()}`
            const exportType = 'xls'
            setTimeout(() => {
                exportFromJSON({ data, fileName, exportType })
                setLoadingDown(false)
            }, 3000);
            dispatch(fetchxlssuccess(response.data))
        })
    }
    //const min = Date.parse(fromDate);

    // const max = Date.parse(toDate);

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, invdata - page * rowsPerPage);

    const cancelSearch = () => {
        document.getElementById("vendorSearch").value = "";
        document.getElementById("invSearch").value = "";
        // document.getElementById("revSearch").value = "";
        document.getElementById("fromDateSearch").value = "";
        document.getElementById("toDateSearch").value = "";
        // setReviewer("")
        setInvoiceNo("")
        setVendorName("")
        setSelectedDropDown('')
        setStatus('')
        setFromDate('2021-01-01')
        setToDate(new Date())
        setPage(0)
        setRowsPerPage(10)
        // dispatch(approvedInvoice(page, rowsPerPage, "", "", '2021-01-01' , toDate))
        const token = window.localStorage.getItem("token");
        setLoadingInvoice(true)
        setMsg('Please wait removing filters . . .')
        axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/fetchInvoices", {
            params: {
                page: page,
                limit: rowsPerPage,
                "invoiceId": "",
                "startDate": '2021-01-01',
                "endDate": toDate,
                "vendorName": "",
                "status": rolApi == "Client" ? "Pushed" : "",
                "isApproved": false
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(response => {
                dispatch(fetchUnApprovedinvoice(response.data))
                dispatch(UnApprovedinvoicecount(response.data.totalRecords))
                if (response.data.totalRecords > 0) {
                    setLoading(false)
                }
                setLoadingInvoice(false)
            })
            .catch(error => {
                const errorMsg = error.message
                dispatch(fetchinvoicefailure(errorMsg))
                setLoadingInvoice(false)
            })
    };

    const handleSearch = async (e) => {
        const token = window.localStorage.getItem("token");
        if (e.key === 'Enter') {
            setLoadingInvoice(true)
            setMsg('Please wait Fetching invoices in progress . . .')
            let data = {
                "invoiceId": invoiceNo,
                "startDate": fromDate,
                "endDate": toDate,
                "vendorName": vendorName,
                "status": rolApi == "Client" ? "Pushed" : status,
                "isApproved": false,
                page: 0,
                limit: rowsPerPage,
            }
            const req = {
                params: data,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
            console.log(req)
            await axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/fetchInvoices", req).then(response => {
                console.log(response, 'Search successful')
                dispatch(fetchUnApprovedinvoice(response.data))
                dispatch(UnApprovedinvoicecount(response.data.totalRecords))
                setLoadingInvoice(false)
            })
        }
        setPage(0)
    }


    const downloadExcel = () => {
        setLoadingDown(true)
        setTimeout(() => {
            setLoadingDown(false)
        }, 2000);
    }

    const handleIconSearch = async () => {
        const token = window.localStorage.getItem("token");
        console.log('Clicked Icon');
        setLoadingInvoice(true)
        setMsg('Please wait Fetching invoices in progress . . .')
        let data = {
            "invoiceId": invoiceNo,
            "startDate": fromDate,
            "endDate": toDate,
            "vendorName": vendorName,
            "status": rolApi == "Client" ? "Pushed" : status,
            "isApproved": false,
            page: 0,
            limit: rowsPerPage,
        }
        const req = {
            params: data,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
        console.log(req)
        await axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/fetchInvoices", req).then(response => {
            console.log(response, 'Search successful')
            dispatch(fetchUnApprovedinvoice(response.data))
            dispatch(UnApprovedinvoicecount(response.data.totalRecords))
            if (response.data.totalRecords == 0) {
                setLoading(true)
            }
            setLoadingInvoice(false)
        })
        setPage(0)
    }

    const Time = new Date()

    var TimeStamp = Time.getHours() + ":" + Time.getMinutes() + ":" + Time.getSeconds();
    return (
        <div className={classes.root}>
            <ToastContainer />
            {
                loadingInvoice && (<div style={{
                    position: 'absolute',
                    top: '100px',
                    left: '40vw',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Alert severity="info">{msg}</Alert>
                </div>)
            }
            {
                loadingDown && (<div style={{
                    position: 'absolute',
                    top: '83vh',
                    left: '40vw',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Alert severity="info">{msg}</Alert>
                </div>)
            }
            {
                loading && (<div style={{
                    position: 'absolute',
                    top: '400px',
                    left: '40vw',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <div className="loader">No Data Found </div>
                </div>)
            }
            <Paper className={classes.paper}>
                {role === 'Client' ?
                    <>
                        <div className={classes.maint}>

                            <div className={classes.search} style={{ 'width': '18%', 'margin-left': '10px', 'position': 'relative', 'float': 'left', 'border-radius': '7px' }}>
                                <div >
                                    {/* <SearchIcon /> */}
                                </div>
                                <InputBase
                                    id='invSearch'
                                    placeholder="Invoice Search"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search ' }}
                                    onChange={e => setInvoiceNo(e.target.value)}
                                    onKeyDown={(e) => handleSearch(e)}
                                    style={{ width: '100%', 'padding-left': '10px' }}
                                />
                                {/* <div className='search-icon' style={{position: 'relative', display: 'flex', 'margin-top': '3px', 'margin-right': '4px' }}><SearchIcon onClick={handleIconSearch}/></div>    */}
                            </div>
                            {rolApi !== "Client" ?
                                <FormControl style={{
                                    backgroundColor: 'white',
                                    padding: '1px 1px',
                                    borderRadius: '3px',
                                    // border: '1px solid black',
                                    margin: '0px 15px 5px 15px',
                                    width: '160px',
                                    textTransform: 'uppercase'
                                }}>
                                    <InputLabel>Status</InputLabel>
                                    <Select value={selectedDropDown} onChange={selectionChangeHandler} style={{ fontSize: "15px" }}>
                                        <MenuItem value={1} onClick={handlePushed} > <TaskAltIcon style={{ fontSize: 20, padding: "2px", color: "green" }} />Pushed</MenuItem>
                                        <MenuItem value={2} onClick={handlePushing}> <HourglassTopIcon style={{ fontSize: 20, padding: "2px", color: "lightskyblue" }} />Pushing</MenuItem>
                                        <MenuItem value={3} onClick={handleUnPushed}><DangerousIcon style={{ fontSize: 20, padding: "2px", color: "grey" }} />Not Pushed</MenuItem>
                                    </Select>
                                </FormControl> : null}
                            <input type='date' style={{
                                backgroundColor: 'white',
                                padding: '3px 1px',
                                borderRadius: '3px',
                                border: '2px solid black',
                                width: '140px',
                                margin: '7px 7px',
                                textTransform: 'uppercase',
                                fontSize: '15px',
                                'margin-left': '10px'
                            }}
                                onChange={handleFromDate}
                                // onChange={e => setFromDate(e.target.value)}
                                id='fromDateSearch'
                            />
                            <Typography style={{ margin: '10px' }} variant="h6" id="tableTitle" component="div" >
                                To
                            </Typography>
                            {/* <TextField type="date" variant="outlined" size="small" onChange={e => setToDate(e.target.value)} /> */}
                            <input type='date' style={{
                                backgroundColor: 'white',
                                padding: '3px 1px',
                                borderRadius: '3px',
                                border: '2px solid black',
                                margin: '7px 7px',
                                width: '140px',
                                textTransform: 'uppercase',
                                fontSize: '15px'
                            }}

                                //onChange={e => setToDate(e.target.value)}
                                onChange={handleToDate}
                                id='toDateSearch'
                            />
                            <div className='search-icon' style={{ position: 'relative', display: 'flex', 'margin-right': '3px', 'margin-left': '5px' }}><Button><SearchIcon onClick={handleIconSearch} /></Button></div>
                            <div className='search-icon' style={{ position: 'relative', display: 'flex' }}><Button><ClearIcon onClick={cancelSearch} /></Button></div>
                            <button id="test-table-xls-button" style={{ padding: 0 }} type="button" onClick={handleXlsDownload}>XLS Download</button>

                        </div>
                    </> :
                    <>
                        <div className={classes.maint}>
                            <div className={classes.search} style={{ 'width': '19%', 'margin-left': '20px', 'position': 'relative', 'float': 'left', 'border-radius': '7px' }}>
                                <div >
                                    {/* <SearchIcon/> */}
                                </div>
                                <InputBase
                                    id='vendorSearch'
                                    placeholder="Vendor Search"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search ' }}
                                    onChange={e => setVendorName(e.target.value)}
                                    onKeyDown={(e) => handleSearch(e)}
                                    style={{ width: '100%', 'padding-left': '10px' }}
                                />
                                {/* <div className='search-icon' style={{position: 'relative', display: 'flex', 'margin-top': '3px', 'margin-right': '4px' }}><SearchIcon onClick={handleIconSearch}/></div>      */}
                            </div>

                            <div className={classes.search} style={{ 'width': '18%', 'margin-left': '10px', 'position': 'relative', 'float': 'left', 'border-radius': '7px' }}>
                                <div >
                                    {/* <SearchIcon /> */}
                                </div>
                                <InputBase
                                    id='invSearch'
                                    placeholder="Invoice Search"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search ' }}
                                    onChange={e => setInvoiceNo(e.target.value)}
                                    onKeyDown={(e) => handleSearch(e)}
                                    style={{ width: '100%', 'padding-left': '10px' }}
                                />
                                {/* <div className='search-icon' style={{position: 'relative', display: 'flex', 'margin-top': '3px', 'margin-right': '4px' }}><SearchIcon onClick={handleIconSearch}/></div>    */}
                            </div>

                            {/* <div className={classes.search} style={{'width': '18%', 'margin-left': '10px', 'position': 'relative', 'float': 'left', 'border-radius': '7px'}}>
                        <div >
                          
                        </div>
                            <InputBase
                                id='revSearch'
                                placeholder="Search By Reviewer"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search ' }}
                                onChange={e => setReviewer(e.target.value)}
                                onKeyDown={(e) => handleSearch(e)}
                                style={{width: '100%', 'padding-left': '10px'}}
                            />
                      </div> */}
                            <FormControl style={{
                                backgroundColor: 'white',
                                padding: '1px 1px',
                                borderRadius: '3px',
                                // border: '1px solid black',
                                margin: '0px 15px 5px 15px',
                                width: '160px',
                                textTransform: 'uppercase'
                            }}>
                                <InputLabel>Status</InputLabel>
                                <Select value={selectedDropDown} onChange={selectionChangeHandler} style={{ fontSize: "15px" }}>
                                    <MenuItem value={1} onClick={handlePushed} ><TaskAltIcon style={{ fontSize: 20, padding: "2px", color: "green" }} />Pushed</MenuItem>
                                    <MenuItem value={2} onClick={handlePushing}><HourglassTopIcon style={{ fontSize: 20, padding: "2px", color: "lightskyblue" }} />Pushing</MenuItem>
                                    <MenuItem value={3} onClick={handleUnPushed}><DangerousIcon style={{ fontSize: 20, padding: "2px", color: "grey" }} />Not Pushed</MenuItem>
                                </Select>
                            </FormControl>
                            <input type='date' style={{
                                backgroundColor: 'white',
                                padding: '3px 1px',
                                borderRadius: '3px',
                                border: '2px solid black',
                                width: '140px',
                                margin: '7px 7px',
                                textTransform: 'uppercase',
                                fontSize: '15px',
                                'margin-left': '10px'
                            }}
                                onChange={handleFromDate}
                                // onChange={e => setFromDate(e.target.value)}
                                id='fromDateSearch'
                            />
                            <Typography style={{ margin: '10px' }} variant="h6" id="tableTitle" component="div" >
                                To
                            </Typography>
                            {/* <TextField type="date" variant="outlined" size="small" onChange={e => setToDate(e.target.value)} /> */}
                            <input type='date' style={{
                                backgroundColor: 'white',
                                padding: '3px 1px',
                                borderRadius: '3px',
                                border: '2px solid black',
                                margin: '7px 7px',
                                width: '140px',
                                textTransform: 'uppercase',
                                fontSize: '15px'
                            }}

                                //onChange={e => setToDate(e.target.value)}
                                onChange={handleToDate}
                                id='toDateSearch'
                            />
                            <div className='search-icon' style={{ position: 'relative', display: 'flex', 'margin-right': '3px', 'margin-left': '5px' }}><Button><SearchIcon onClick={handleIconSearch} /></Button></div>
                            <div className='search-icon' style={{ position: 'relative', display: 'flex' }}><Button><ClearIcon onClick={cancelSearch} /></Button></div>
                            <button id="test-table-xls-button" style={{ padding: 0 }} type="button" onClick={handleXlsDownload}>XLS Download</button>

                        </div>
                    </>
                }

                <EnhancedTableToolbar numSelected={selected.length} selectedId={selected} handleBulkPushInvoice={handleBulkPush} isPushed={isPushed} handleDownloadxls={handleDownloadxls} />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='small'
                        aria-label="enhanced table"
                        id="table-to-xls"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={invdata}
                        />
                        {/* {approveddata === undefined ? (
                            <Box sx={{ display:'flex', position:'absolute', width:'90px', marginLeft:'50vw',marginTop:'10vh' }}>
                                <CircularProgress color="secondary" size={ 70}/>
                            </Box>
                            ) : null} */}
                        {role === 'Client' ?
                            <>
                                <TableBody>
                                    {approveddata !== undefined ? (stableSort(approveddata, getComparator(order, orderBy))
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(row._id);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            const timestamp = row.createdAt;
                                            const uploadedtime = moment(timestamp).utc().local().format('DD-MM-YYYY HH:mm:ss');
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row._id)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row._id}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </TableCell>

                                                    <TableCell component="th" id={labelId} scope="row" align="left" padding="none">
                                                        <Link to={`/results/${row._id}`} target="_blank" style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>

                                                            {row.extractedData.InvoiceId}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell align="left"><Link target="_blank" to={`/results/${row._id}`} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>{row.extractedData.PurchaseOrder}</Link></TableCell>
                                                    <TableCell align="left"><Link target="_blank" to={`/results/${row._id}`} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>{row.extractedData.VendorName}</Link></TableCell>
                                                    <TableCell align="center"><Link target="_blank" to={`/results/${row._id}`} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>{row.extractedData.InvoiceTotal}</Link></TableCell>
                                                    <TableCell align="center"><Link target="_blank" to={`/results/${row._id}`} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>{uploadedtime}</Link></TableCell>
                                                    <TableCell align="center">
                                                        {
                                                            row.is_pushed === "Pushing" ? <Badge pill bg="primary">{row.is_pushed}</Badge> :
                                                                row.is_pushed === "Pushed" ? <Badge pill bg="success" >{row.is_pushed}</Badge> :
                                                                    row.is_pushed === "Failed" ? <Badge pill bg="danger">{row.is_pushed}</Badge> :
                                                                        <Badge pill bg="light" text="dark">Not Pushed</Badge>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })) : (<> <Box sx={{ display: 'flex', position: 'absolute', width: '90px', marginLeft: '50vw', marginTop: '10vh' }}>
                                            <CircularProgress color="secondary" size={70} />
                                        </Box></>)}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 33 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </> :
                            <>
                                <TableBody>
                                    {approveddata !== undefined ? (stableSort(approveddata, getComparator(order, orderBy))
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(row._id);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            const timestamp = row.createdAt;
                                            const uploadedtime = moment(timestamp).utc().local().format('DD-MM-YYYY hh:mm:ss');
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row._id)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row._id}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </TableCell>

                                                    <TableCell component="th" id={labelId} scope="row" align="left" padding="none">
                                                        <Link to={`/results/${row._id}`} target="_blank" style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>

                                                            {row.extractedData.InvoiceId}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell align="left"><Link target="_blank" to={`/results/${row._id}`} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>{row.extractedData.PurchaseOrder}</Link></TableCell>
                                                    <TableCell align="left"><Link target="_blank" to={`/results/${row._id}`} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>{row.extractedData.VendorName}</Link></TableCell>
                                                    <TableCell align="left"><Link target="_blank" to={`/results/${row._id}`} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>{row.extractedData.InvoiceDate}</Link></TableCell>
                                                    <TableCell align="center"><Link target="_blank" to={`/results/${row._id}`} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>{row.extractedData.InvoiceTotal}</Link></TableCell>
                                                    <TableCell align="center"><Link target="_blank" to={`/results/${row._id}`} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>{uploadedtime}</Link></TableCell>
                                                    <TableCell align="center">
                                                        {
                                                            row.is_pushed === "Pushing" ? <Badge pill bg="primary">{row.is_pushed}</Badge> :
                                                                row.is_pushed === "Pushed" ? <Badge pill bg="success" >{row.is_pushed}</Badge> :
                                                                    row.is_pushed === "Failed" ? <Badge pill bg="danger">{row.is_pushed}</Badge> :
                                                                        <Badge pill bg="light" text="dark">Not Pushed</Badge>
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center">{role === 'Client' ? '' : row.reviewerName}</TableCell>
                                                </TableRow>
                                            );
                                        })) : (<><Box sx={{ display: 'flex', position: 'absolute', width: '90px', marginLeft: '50vw', marginTop: '10vh' }}>
                                            <CircularProgress color="secondary" size={70} />
                                        </Box></>)}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 33 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </>
                        }

                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 300]}
                    component="div"
                    count={invdata}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

        </div>

    );
}

const mapStateToProps = (state) => {
    return {
        state: state,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        approvedInvoice: (page, rowsPerPage) => dispatch(unapprovedInvoice(page, rowsPerPage)),
        // approvedinvoicecount:()=>dispatch(approvedinvoicecount())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnApprovedInvoiceTable)