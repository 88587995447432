import React, { useEffect, useState , useRef} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './tableheader.css';
import Allinvoice from '../AllInvoice/allInvoice';
import ApprovedInvoice from '../ApprovedInvoice/approvedInvoice';
import UnApprovedInvoice from '../UnApprovedInvoice/unapprovedinvoice';

import { connect, useDispatch } from 'react-redux';
import { fetchInvoice, approvedInvoice , fetchinvoicesuccess , fetchinvoicecount , fetchinvoicefailure , fetchApprovedinvoice ,fetchUnApprovedinvoice , Approvedinvoicecount , UnApprovedinvoicecount } from "../../redux";
import axios from 'axios';
import { toast } from 'react-toastify';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mr: -1.5 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Tableheader({ state }) {
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState('2021-01-01')
    const [toDate, setToDate] = useState(new Date())
    // useEffect(async () => {
    //     dispatch(InvoiceCount())
    // }, [])
    const toastIdTable = useRef(null)
    const toastIdApproved = useRef(null)

    const RoleApi = localStorage.getItem("UserRole");

    // useEffect(async () => {
    //     if (RoleApi !== 'Client') {
    //      dispatch(approvedInvoice(0, 10,"", "", fromDate, toDate))
    //     }
    // }, [])

    const invoiceData = state.invoice
    const rdatalen = invoiceData && invoiceData.invlength
    const approveddata = invoiceData && invoiceData.ApproveInvoiceCount
    const unApproveddata = invoiceData && invoiceData.UnApproveInvoiceCount
    const [role, setRole] = React.useState("");

    const handleChange = (event, newValue) => {
        setValue(newValue);
        console.log(value)
    };
    
    const allPage = localStorage.getItem('AllPages')
    const allRows = localStorage.getItem('AllRows')

    const approvedPage = localStorage.getItem('ApprovedPages')
    const approvedRows = localStorage.getItem('ApprovedRows')

    React.useEffect(() => {
        setRole(localStorage.getItem("UserRole"));
    }, []);

    const refreshTable = () => {
        let p=localStorage.getItem("pageNumAllInvoices");
        let rowPer = localStorage.getItem("rowsPerPageAllInvoices");
        toastIdTable.current = toast.loading("Refreshing Table ...")
        function ClearFields() {            
            document.getElementById("vendorSearch").value = "";
            document.getElementById("invSearch").value = "";
            document.getElementById("fromDateSearch").value = "";
            document.getElementById("toDateSearch").value = "";
        }
        ClearFields()
        console.log('Refreshing All Invoice Table')
        // dispatch(fetchInvoice(allPage, allRows, "", "", "2021-01-01", new Date(), ""))
        const token = window.localStorage.getItem("token");
        axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/fetchInvoices",{
          params: {
            page: p,
            limit:rowPer,
            "invoiceId" : "",
            "startDate" : "2021-01-01",  
            "endDate" : new Date(),  
            "vendorName" : "", 
            "status" : "",
            "isApproved" : null
          }, 
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        .then(response=>{
          dispatch(fetchinvoicesuccess(response.data))
          dispatch(fetchinvoicecount(response.data.totalRecords))
          toast.update(toastIdTable.current, { render: "Table Refreshed", type: "success", isLoading: false, position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",})
        })
        .catch(error=>{
          const errorMsg=error.message
          dispatch(fetchinvoicefailure(errorMsg))
          toast.update(toastIdTable.current, { render: "Failed To Refresh", type: "error", isLoading: false, position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",})
        })
    }
    

    const refreshApprovedTable = () => {
        let p = localStorage.getItem("pageNumAppInvoices");
        let rowPer = localStorage.getItem("rowsPerPageApprovedInvoices");
        toastIdApproved.current = toast.loading("Refreshing Approved ...")
        if(role === 'Client'){
            function ClearFields() {            
                document.getElementById("invSearch").value = "";
            }
            ClearFields()
        }else{
            function ClearFields() {            
                document.getElementById("vendorSearch").value = "";
                document.getElementById("invSearch").value = "";
                // document.getElementById("revSearch").value = "";
                document.getElementById("fromDateSearch").value = "";
                document.getElementById("toDateSearch").value = "";
            }
            ClearFields()
        }
        console.log('Refreshing Approved Invoice Table')
        // dispatch(approvedInvoice(approvedPage, approvedRows , "", "", "2021-01-01", new Date(), ""))
        const token = window.localStorage.getItem("token");
          axios.get(  process.env.REACT_APP_BACKEND_API + "api/invoice/fetchInvoices", {
            params: {
              page: p,
              limit:rowPer,
              "invoiceId" : "",
              "startDate" : "2021-01-01",  
              "endDate" : new Date(),  
              "vendorName" : "",
              "status" : RoleApi=="Client"?"Pushed":"",   
              "isApproved" : true
            },
            headers: {
              Authorization: `Bearer ${token}`,
            }
          })
          .then(response=>{
            dispatch(fetchApprovedinvoice(response.data))
            dispatch(Approvedinvoicecount(response.data.totalRecords))
            toast.update(toastIdApproved.current, { render: "Approved Refreshed", type: "success", isLoading: false, position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",})
          })
          .catch(error=>{
            const errorMsg=error.message
            dispatch(fetchinvoicefailure(errorMsg))
            toast.update(toastIdApproved.current, { render: "Failed To Refresh", type: "error", isLoading: false, position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",})
          })
    }
    const refreshUnApprovedTable = () => {
        let p = localStorage.getItem("pageNumUnAppInvoices");
        let rowPer = localStorage.getItem("rowsPerPageUnApprovedInvoices");
        toastIdApproved.current = toast.loading("Refreshing UnApproved ...")
        if(role === 'Client'){
            function ClearFields() {            
                document.getElementById("invSearch").value = "";
            }
            ClearFields()
        }else{
            function ClearFields() {            
                document.getElementById("vendorSearch").value = "";
                document.getElementById("invSearch").value = "";
                // document.getElementById("revSearch").value = "";
                document.getElementById("fromDateSearch").value = "";
                document.getElementById("toDateSearch").value = "";
            }
            ClearFields()
        }
        console.log('Refreshing UnApproved Invoice Table')
        // dispatch(approvedInvoice(approvedPage, approvedRows , "", "", "2021-01-01", new Date(), ""))
        const token = window.localStorage.getItem("token");
          axios.get(  process.env.REACT_APP_BACKEND_API + "api/invoice/fetchInvoices", {
            params: {
              page: p,
              limit:rowPer,
              "invoiceId" : "",
              "startDate" : "2021-01-01",  
              "endDate" : new Date(),  
              "vendorName" : "",
              "status" : RoleApi=="Client"?"Pushed":"",   
              "isApproved" : false
            },
            headers: {
              Authorization: `Bearer ${token}`,
            }
          })
          .then(response=>{
            dispatch(fetchUnApprovedinvoice(response.data))
            dispatch(UnApprovedinvoicecount(response.data.totalRecords))
            toast.update(toastIdApproved.current, { render: "UnApproved Refreshed", type: "success", isLoading: false, position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",})
          })
          .catch(error=>{
            const errorMsg=error.message
            dispatch(fetchinvoicefailure(errorMsg))
            toast.update(toastIdApproved.current, { render: "Failed To Refresh", type: "error", isLoading: false, position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",})
          })
    }
    

    return (
        <Box sx={{ width: '100%' }}>
                {role === 'Client' ? 
                <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tab label="Approved" className="showApproved-btn" component={() => (
                        <Button style={{ color: 'black' }} onClick={() => setValue(0)}>
                            <span class="buttontext"> Approved <span class="ellipse">{approveddata}</span></span>
                        </Button>
                    )} />
                    <div className="set-2">
                        <Tab label="Refresh Approved Table" component={() => (
                            <Button style={{ color: 'black', 'margin-left': '10px' }} className="refreshApproved-btn" onClick={() => refreshApprovedTable()}>
                                Refresh Table 
                            </Button>
                        )}/>
                    </div>
                    <TabPanel value={value} index={0}>
                        <ApprovedInvoice />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                    </TabPanel>
                </Box>
                </> : 
                <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Show All" {...a11yProps(0)} value={value} className="showAll-btn"
                            component={() => (
                                <Button style={{ color: 'black' }} onClick={() => setValue(0)}>
                                    <span class="buttontext"> UnApproved<span class="ellipse">{unApproveddata}</span></span>
                                </Button>
                            )} />
                        <Tab label="Approved" className="showApproved-btn" component={() => (
                            <Button style={{ color: 'black' }} onClick={() => setValue(1)}>
                                <span class="buttontext"> Approved <span class="ellipse">{approveddata}</span></span>
                            </Button>
                        )} {...a11yProps(1)} />

                        <Tab label="UnApproved" className="showApproved-btn" component={() => (
                            <Button style={{ color: 'black' }} onClick={() => setValue(2)}>
                                <span class="buttontext"> Show All <span class="ellipse">{rdatalen}</span></span>
                            </Button>
                        )} {...a11yProps(2)} />
                    
                    <div className="set-2">
                        <Tab label="Refresh Table" component={() => (
                            <Button className="refresh-btn" style={{ color: 'black','margin-right': '10px'}} onClick={() => refreshUnApprovedTable()}>
                                Refresh Unapproved
                            </Button>
                        )}/>
                        <Tab label="Refresh Table" component={() => (
                            <Button className="refresh-btn" style={{ color: 'black'}} onClick={() => refreshTable()}>
                                Refresh Show All
                            </Button>
                        )}/>
                        <Tab label="Refresh Approved Table" component={() => (
                            <Button style={{ color: 'black', 'margin-left': '10px' }} className="refreshApproved-btn" onClick={() => refreshApprovedTable()}>
                                Refresh Approved 
                            </Button>
                        )}/>
                    </div>

                </Tabs>
                    <TabPanel value={value} index={0}>
                      <UnApprovedInvoice />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ApprovedInvoice />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                         <Allinvoice />
                    </TabPanel>
                </Box>
                </>
            }
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        state: state,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        // InvoiceCount:()=>dispatch(InvoiceCount()),
        // ApprovedInvoicecount:()=>dispatch(approvedinvoicecount()),
        refreshAllInvoice: (page, limit) => dispatch(fetchInvoice(page, limit)),
        refreshApprovedInvoice: (page, limit) => dispatch(approvedInvoice(page, limit)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tableheader)