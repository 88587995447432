/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */
 
import React, { Component } from "react";
import "./AnalyzingError.css";
import MainImg from "../../../assets/analyzeError.png";
import UploadButton from "../../../components/FileUpload/DocUploadBtn"


class AnalyzingError extends Component {

  render() {
    return (
      <div class="container">
        <img id="mainImg" src={MainImg} alt=""></img>
        <div id="btn">
        <p id="warningMsg">At the moment we are unable to extract data from this document. Be patient, our engineers are working on improvements. While upload a new document and extract data.</p>
          <div id="btnUploadDocError">
          <UploadButton></UploadButton>
          </div>
          
        </div>
      </div>
    );
  }
}

export default AnalyzingError;