// /**
//  * Copycenters © 2021 Sigitek
//  * All centers Reserved
//  *
//  * NOTICE:
//  * All the information contained here are property of Sigitek
//  * The intellectual and technical concepts contained here in are proprietary to Sigitek
//  * Dissemination of this information, reproduction of this material and copying or distribution of this software
//  * is strictly forbidden unless prior written perssion is obtained from Sigitek
//  *
//  * */

import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect, useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import {
  change_field_value,
  fetchsingleInvoice,
  updateInvoice,
} from "../../redux";
import Button from "@mui/material/Button";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import "react-datepicker/dist/react-datepicker.css";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "../Dialog/dialog";
import "./LineItems2.css";

function dateConvert(strDate) {
  if (strDate !== undefined) {
    var date = strDate.split("-");
    if (date.length !== 3) {
      date = strDate.split("/");
    }
    if (date.length !== 3) {
      date = strDate.split(".");
    }
    if (date.length !== 3) {
      return strDate;
    }
    var date_format = new Date(date[2], date[1] - 1, date[0]);

    return date_format;
  } else {
    return null;
  }
}

function Lineitems({ state }) {
  const [dialog, setDialog] = useState({
    message: "",
    isLoading: false,
    nameProduct: "",
  });
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchsingleInvoice(id);
  }, [id]);

  
  let lineitemsdata = state.invoice.invdata.invoice.extractedData;
  if(!('Line_Items2' in lineitemsdata)) {
    lineitemsdata.Line_Items2 = {
      columns: [
        'LOTNO', 'BOXNO', 'SKU', 'INVQTY'
      ],
      data:{
        1:{
        properties: {
          LOTNO:{value: '', content: ''},
          BOXNO: {value: '', content: ''},
          SKU: {value: '', content: ''},
          INVQTY: {value: '', content: ''}
        }
      }}
    };
  }
  const linedata =lineitemsdata.Line_Items2?lineitemsdata.Line_Items2.data:null;
  const objlength =(linedata!=null)? Object.keys(linedata).length:null;
  console.log(lineitemsdata, linedata, objlength, 'LineItems2')
  
  

  const [newColumnIndex, setNewColumnIndex] = React.useState(
  (state.invoice.invdata.invoice.extractedData.Line_Items2) ? (state.invoice.invdata.invoice.extractedData.Line_Items2.columns.length + 1):null);
  const [newColumnName, setNewColumnName] = React.useState("");
  const [extractedTables, setExtractedTables] = React.useState({
    TableHeaders: [],
    TableData: [],
  });

  const columnIndexChangeHandler = (e) => {
    setNewColumnIndex(e.target.value);
  };

  const columnNameChangeHandler = (e) => {
    setNewColumnName(e.target.value);
  };

  const addNewColumnHandler = () => {

    if (newColumnName.toString().length === 0) {
      return;
    }

    var lineItems = state.invoice.invdata.invoice.extractedData.Line_Items2.data  || {};
    console.log(lineItems, 'LineItems2 inside the loop');
    if(Object.keys(lineItems).length === 0){
        state.invoice.invdata.invoice.extractedData.Line_Items2.data =  {1 : {"properties" : {}}} 
        state.invoice.invdata.invoice.extractedData.Line_Items2.data[1].properties[newColumnName] = { value: "", content: "" };
    }else{
      for (var i = 1; i <= Object.keys(lineItems).length; i++) {
        if (
          "properties" in state.invoice.invdata.invoice.extractedData.Line_Items2.data[i]
        ) {
          state.invoice.invdata.invoice.extractedData.Line_Items2.data[i].properties[
            newColumnName
          ] = { value: "", content: "" };
        } else {
          state.invoice.invdata.invoice.extractedData.Line_Items2.data[i]["properties"] =
            {};
          state.invoice.invdata.invoice.extractedData.Line_Items2.data[i].properties[
            newColumnName
          ] = { value: "", content: "" };
        }

      }

    }
    
    var newColumnList = [];
    var ind = 0;

    var columnsL = state.invoice.invdata.invoice.extractedData.Line_Items2.columns;

    if(columnsL.length === 0){
      newColumnList.push(newColumnName);
      
    }else{
      for (let index = 1; index <= columnsL.length + 1; index++) {
        if (newColumnIndex === index) {
          newColumnList.push(newColumnName);
          continue;
        }
        newColumnList.push(columnsL[ind++]);
      }
    }
    

    state.invoice.invdata.invoice.extractedData.Line_Items2.columns = newColumnList;

    setNewColumnName("");

    dispatch(change_field_value(state));
  };

  const handleDialog = (message, isLoading) => {
    setDialog({
      message,
      isLoading,
    });
  };
  const idProductRef = useRef();
  const deleteColumnHandler = (columnName) => {
    handleDialog("Are you sure you want to delete?", true);
    idProductRef.current = columnName;
  };

  const areUSureDelete = (choose) => {
    if (choose) {
      var lineItems = state.invoice.invdata.invoice.extractedData.Line_Items2.data;

      for (var i = 1; i <= Object.keys(lineItems).length; i++) {
        var valueNew = {};
        for (
          let index = 1;
          index <=
          state.invoice.invdata.invoice.extractedData.Line_Items2.columns.length;
          index++
        ) {
          var colName =
            state.invoice.invdata.invoice.extractedData.Line_Items2.columns[index - 1];
          if (idProductRef.current !== colName) {
            valueNew[colName] =
              state.invoice.invdata.invoice.extractedData.Line_Items2.data[
                i
              ].properties[colName];
          }
        }
        lineItems[i].value = valueNew;
      }

      var newColumnList = [];
      for (
        let index = 1;
        index <=
        state.invoice.invdata.invoice.extractedData.Line_Items2.columns.length;
        index++
      ) {
        if (
          idProductRef.current !==
          state.invoice.invdata.invoice.extractedData.Line_Items2.columns[index - 1]
        ) {
          newColumnList.push(
            state.invoice.invdata.invoice.extractedData.Line_Items2.columns[index - 1]
          );
        }
      }
      
      for (var i = 1; i <= Object.keys(lineItems).length; i++) {
        var rowValues = lineItems[i].properties;
        var valueNewR = {};
        for (
          let index = 1;
          index <=
          Object.keys(rowValues).length;
          index++
        ) {
          if(Object.keys(rowValues)[index - 1] !== idProductRef.current){
            valueNewR[Object.keys(rowValues)[index - 1]] = rowValues[Object.keys(rowValues)[index - 1]];
          }
        }
        lineItems[i].properties = valueNewR;
      }

      state.invoice.invdata.invoice.extractedData.Line_Items2.columns = newColumnList;
      state.invoice.invdata.invoice.extractedData.Line_Items2.data = lineItems;

      dispatch(change_field_value(state));
      handleDialog("", false);
    } else {
      handleDialog("", false);
    }
  };

  const valueChangehandler = (e, rowIndex) => {

    var fieldName = e.target.id;
    state.invoice.invdata.invoice.extractedData.Line_Items2.data[
      rowIndex + 1
    ].properties[fieldName].value = e.target.value;
    state.invoice.invdata.invoice.extractedData.Line_Items2.data[
      rowIndex + 1
    ].properties[fieldName].content = e.target.value;
    dispatch(change_field_value(state));
  };

  const dateValueChangehandler = (date, rowIndex, columnName) => {
    var newDate = new Date(date);
    newDate =
      newDate.getDate() +
      "-" +
      (newDate.getMonth() + 1) +
      "-" +
      newDate.getFullYear();
    state.invoice.invdata.invoice.extractedData.Line_Items2.data[
      rowIndex + 1
    ].properties[columnName].value = newDate;
    state.invoice.invdata.invoice.extractedData.Line_Items2.data[
      rowIndex + 1
    ].properties[columnName].content = newDate;
    console.log(
      state.invoice.invdata.invoice.extractedData.Line_Items2.data[rowIndex + 1]
        .properties[columnName]
    );
    dispatch(change_field_value(state));
  };

  const deleteRowHandler = (rowIndex) => {
    var lineItems = state.invoice.invdata.invoice.extractedData.Line_Items2.data;
    var cols = state.invoice.invdata.invoice.extractedData.Line_Items2.columns;

    var newLineItems = {};
    newLineItems[1] = { properties: {} };

    for (var i = 0; i < cols.length; i++) {
      newLineItems[1].properties[cols[i]] = {
        value: "",
        content: "",
      };
    }

    var index = 1;
    for (var i = 1; i <= Object.keys(lineItems).length; i++) {
      if (i === rowIndex + 1) {
        continue;
      }
      newLineItems[index] =
        state.invoice.invdata.invoice.extractedData.Line_Items2.data[i];
      index = index + 1;
    }
    state.invoice.invdata.invoice.extractedData.Line_Items2.data = newLineItems;
    dispatch(change_field_value(state));
  };

  const addRowHandler = (rowIndex) => {
    var lineItems = state.invoice.invdata.invoice.extractedData.Line_Items2.data;
    var newLineItems = JSON.parse("{}");
    var index = 1;
    for (var i = 1; i <= Object.keys(lineItems).length + 1; i++) {
      if (i === rowIndex + 2) {
        let newdata = JSON.parse('{"properties" : {}}');
        state.invoice.invdata.invoice.extractedData.Line_Items2.columns.forEach(
          (key) => {
            newdata.properties[key.toString()] = {
              value: "",
              content: "",
            };
          }
        );
        newLineItems[i] = newdata;
      } else {
        newLineItems[i] =
          state.invoice.invdata.invoice.extractedData.Line_Items2.data[index];
        index = index + 1;
      }
    }
    state.invoice.invdata.invoice.extractedData.Line_Items2.data = newLineItems;
    dispatch(change_field_value(state));
  };

  const invoiceLineItems = state.invoice.invdata.invoice.extractedData.Line_Items2;
  const tableFields =invoiceLineItems? invoiceLineItems.columns:null;

  const newColumnsIndexValues = [];

  for (
    let index = 1;
    index <=
    (state.invoice.invdata.invoice.extractedData.Line_Items2? state.invoice.invdata.invoice.extractedData.Line_Items2.columns.length + 1:2);
    index++
  ) {
    newColumnsIndexValues.push({
      value: index,
      label: index,
    });
  }
  
  extractedTables["TableHeaders"] = [];
  extractedTables["TableData"] = [];

  linedata? tableFields.forEach((field) => {
    if (field in linedata[1].properties) {
      extractedTables["TableHeaders"].push(field);
      var columnValues = [];
      for (let i = 1; i <= objlength; i++) {
        if (field === "Date") {
          columnValues.push(
            field in linedata[i].properties &&
              linedata[i].properties[field].content
              ? dateConvert(linedata[i].properties[field].content)
              : ""
          );
        } else {
          columnValues.push(
            field in linedata[i].properties
              ? linedata[i].properties[field].value
              : ""
          );
        }
      }
      extractedTables["TableData"].push(columnValues);
    }
  }):(<>null</>);

  const columns = [];
  // var col ={};
  // col['id'] = 'serNo';
  // col["label"] = 'serNo'
  // columns.push(col)

  const rows = [];
  for (var i = 0; i < extractedTables.TableHeaders.length; i++) {
    var col = {};
    col["id"] = extractedTables.TableHeaders[i];
    col["label"] = extractedTables.TableHeaders[i];
    columns.push(col);
  }

  for (var j = 0; j < extractedTables.TableData[0]?.length ?? 0; j++) {
    var row = {};
    for (var k = 0; k < extractedTables.TableData.length; k++) {
      row[extractedTables.TableHeaders[k]] = extractedTables.TableData[k][j];
    }
    rows.push(row);
  }

  const handlePop = (e) => {
    e.preventDefault();
    e.target.nextElementSibling.classList.toggle('hide')
  }

  return (<>
    <Paper style={{ width: "100%" }}>
      <TextField
        id="outlined-basic"
        className="newCol"
        label="Column Name"
        disabled={state.invoice.invdata.invoice.is_approved ? "disabled" : ""}
        value={newColumnName || ""}
        onChange={columnNameChangeHandler}
        variant="outlined"
        style={{ margin: "10px" }}
        size="small"
      />
      <TextField
        id="outlined-select-currency"
        select
        label="Index"
        size="small"
        disabled={state.invoice.invdata.invoice.is_approved ? "disabled" : ""}
        style={{ margin: "10px" }}
        value={newColumnIndex}
        onChange={columnIndexChangeHandler}
      >
        {newColumnsIndexValues.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <Button
        variant="outlined"
        style={{ margin: "10px" }}
        disabled={state.invoice.invdata.invoice.is_approved ? "disabled" : ""}
        onClick={addNewColumnHandler}
      >
        Add Column
      </Button>
      <TableContainer style={{ maxHeight: 300 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>      
            <TableRow>
              <TableCell>
                Serial No               
              </TableCell>
              { columns.map((column) => (
                
                <TableCell
                  key={column.id}
                >
                  
                  {column.label}
                  <IconButton
                    aria-label="delete"
                    disabled={state.invoice.invdata.invoice.is_approved ? "disabled" : ""}
                    style={{
                      margin: "0 0 0 0px",
                    }}
                  >
                    <DeleteIcon onClick={() => deleteColumnHandler(column.label)}/>
                  </IconButton>
                  {dialog.isLoading && (
                  <Dialog
                  onDialog={areUSureDelete}
                  message={dialog.message}
                />
              )}
                </TableCell>
                
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows !== undefined ? rows.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell>
                    {index+1}
                  </TableCell>
                  {columns.map((column) => {                     
                    const value = row[column.id];
                    var inputfield =
                      column.id.toString().includes("date") ? (

                        <DatePicker
                          selected={dateConvert(value)}
                          id={column.id}
                          disabled={state.invoice.invdata.invoice.is_approved ? "disabled" : ""}
                          onChange={(date) =>
                            dateValueChangehandler(date, index, column.id)
                          }
                          name="startDate"
                          dateFormat="dd-MM-yyyy"
                        />
                      ) : (
                        
                        <input
                          id={column.id}
                          className="lineitems-input"
                          value={value}
                          size={16}
                          disabled={state.invoice.invdata.invoice.is_approved ? "disabled" : ""}
                          onClick={(e) => handlePop(e)}
                          onChange={(e) => valueChangehandler(e, index)}
                        />
                      );

                    return  <TableCell key={column.id}>
                      <span className="main-input">
                      {inputfield}
                      <span className="hide lineitems-field-span">{value}</span>
                      </span>
                    </TableCell>;
                  })}
                  <TableCell>
                    {" "}
                    <IconButton aria-label="delete" size="large">
                      <DeleteIcon onClick={() => deleteRowHandler(index)} />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton>
                      <AddIcon onClick={() => addRowHandler(index)} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            }):(<><Paper style={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              className="newCol"
              label="Column Name"
              disabled={state.invoice.invdata.invoice.is_approved ? "disabled" : ""}
              value={newColumnName}
              onChange={columnNameChangeHandler}
              variant="outlined"
              style={{ margin: "10px" }}
              size="small"
            />
            <TextField
              id="outlined-select-currency"
              select
              label="Index"
              size="small"
              disabled={state.invoice.invdata.invoice.is_approved ? "disabled" : ""}
              style={{ margin: "10px" }}
              value={newColumnIndex}
              onChange={columnIndexChangeHandler}
            >
              {newColumnsIndexValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <Button
              variant="outlined"
              style={{ margin: "10px" }}
              disabled={state.invoice.invdata.invoice.is_approved ? "disabled" : ""}
              onClick={addNewColumnHandler}
            >
              Add Column
            </Button></Paper></>)}

          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
    
  </>);
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeValues: (state) => dispatch(change_field_value(state)),
    fetchsingleInvoice: (id) => dispatch(fetchsingleInvoice(id)),
    updateInvoice: (id, state) => dispatch(updateInvoice(id, state)),
  };
};

const mapStateToProps = (state) => {
  return {
    state: state,
    // invData: state.invoice
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Lineitems);