import React, { useState } from 'react'
import './userManagement.css'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import UserRegistration from '../UserRegistration/userRegistration';
import UserDisplay from '../Usersdisplay/usersdisplay'


function UserManagement() {
  const [showResults, setShowResults] = useState(true)
  const onClick = () => setShowResults(!showResults)
  return (<>
    <div className='Users'>
      <div className="personicon"><PersonAddIcon fontSize="large" onClick={onClick}  style={{visibility:'hidden'}} />
        <div className='Userregistration'>  <UserRegistration/> </div>
        <div className="usersDisplay"><UserDisplay /></div>
      </div>
    </div>
  </>)
}

export default UserManagement