/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */

import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { authenticateUser } from "../../redux";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link, useHistory } from "react-router-dom"
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import loader from '../../assets/loadingImage.gif'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './Singin.css'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '91.5vh',
  },
  image: {
    backgroundImage: 'url(./imgLoginPage.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    marginTop: '20vh',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignInSide(props) {
  const classes = useStyles();
  let errorsObj = { UserId: '', password: '',CompanyName:'' };
  const [CompanyName,setCompanyName]=useState('');
  const [UserId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setPassword(event.target.value)
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const dispatch = useDispatch();
  let history = useHistory();
  let location = localStorage.getItem("lastPage");

  if (location === "/login") {
    location = "/";
  }

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


  function onLoginClick(e) {
    e.preventDefault();
    let error = false;

    const errorObj = { ...errorsObj };
    if (CompanyName === '') {
      errorObj.CompanyName = 'companyName is Required';
      error = true;
    }

    if (UserId === '') {
      errorObj.UserId = 'UserId is Required';
      error = true;
    }

    if (password === '') {
      errorObj.password = 'Password is Required';
      error = true;
    }

    if (error) {
      setOpen(true);
      return
    }

    dispatch(authenticateUser(CompanyName, UserId, password,history, location))
  }
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      {!props.state.user.validating ?

        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>

          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} onSubmit={onLoginClick} noValidate>
              <TextField
                variant="filled"
                margin="normal"
                required
                fullWidth
                id="company_Name"
                label="Company Name"
                name="Company Name"
                onChange={(e) =>
                  setCompanyName(e.target.value)
                }
                autoComplete="CompanyName"
                autoFocus
              />
              <TextField
                variant="filled"
                margin="normal"
                required
                fullWidth
                id="UserId"
                label="User ID"
                name="UserId"
                onChange={(e) =>
                  setUserId(e.target.value)
                }
                autoComplete="UserId"
                autoFocus
              />

        <FormControl fullWidth required variant="filled"  margin="normal"  autoFocus>
        <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
          <FilledInput
            id="filled-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            label="Password"
            onChange={
              handleChange('password')
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
  </FormControl>
      {/* <button onClick={togglePassword}>Show Password</button> */}

              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Link to="/forgotpassword" variant="body2">
                Forgot Password?
              </Link>
            </form>

          </div>
        </Grid> :
        <Grid item xs={12} sm={8} md={4} elevation={6} square>
          <img src={loader} alt="" id="loadingImg"></img>
        </Grid>
      }
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="error">Fill the required fields for sigin</Alert>
      </Snackbar>
      <Snackbar open={props.state.user.authError} autoHideDuration={6000}>
        <Alert severity="error">Authenticatication Failed. Please Check the Credentials</Alert>
      </Snackbar>
    </Grid>
)
    }
const mapDispatchToProps = (dispatch) => {
  return {
    login: (companyname,username, password) => dispatch(authenticateUser(companyname,username, password)),
  };
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInSide);