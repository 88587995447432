import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import './EmailHeader.css';
import EmailTableheader from '../EmailTableHeader/EmailTableHeader';
import {uploadInvoice, Email_Invoice_Success , emailinvoicecountsuccess , Email_Invoice_Failure , allLocalInvoice, allEmailInvoice , Local_Invoice_Success , localinvoicecountsuccess , Local_Invoice_Failure  } from "../../redux";
import { Button } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState , useRef} from 'react'
import { toast } from 'react-toastify';
import axios from "axios";

//import Helmet from 'react-helmet'

function Item(props) {
  useEffect(() => {
    document.title = "Split PDF"
  }, [])
  const {sx, ...other } = props;
  return (
      <Box
        sx={{
          color: 'black',
          p: 1,
          m: 1,
          borderRadius: 1,
          textAlign: 'center',
          fontSize: '1rem',
          fontWeight: '700',
          ...sx,
        }}
        {...other}
      />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

function FlexDirection(props) {
  const dispatch = useDispatch();
   const fileHandler=(e)=>{
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = async() => {
      if (reader.readyState === 2) {
        dispatch(uploadInvoice(e.target.files[0],reader.result, props.state.user.token));
        //window.location.reload(false);
      }
    }
   } 
  const page = localStorage.getItem('localPages')
  const limit = localStorage.getItem('localRows')
	const [role, setRole] = useState('');
  const pageEmail = localStorage.getItem('emailPages')
  const limitEmail = localStorage.getItem('emailRows')
  const toastId = useRef(null)
  const toastIdLocal = useRef(null)
  const [fromDate, setFromDate] = useState('2021-01-01')
    const [toDate, setToDate] = useState(new Date())

  useEffect(() => {
		setRole(localStorage.getItem('UserRole'));
	}, []);

  const refreshLocal = async() =>{
    console.log('Refreshing')
    let p = localStorage.getItem("pageNumLocalInvoices");
    let rowPer = localStorage.getItem("rowsPerPageLocalInvoices");
    // toast("Refreshing Local")
    // dispatch(allLocalInvoice(page,limit))
    const token = window.localStorage.getItem("token");
    toastIdLocal.current = toast.loading("Refreshing Local ...")
   await axios.get( process.env.REACT_APP_BACKEND_API + "api/invoice/fetchlocalinvoice/", {
      params: {
        page: p,
        limit:rowPer,
        startDate: fromDate,
        endDate: toDate,
        status: "",
        FileName: "",
      }, 
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response=>{
      dispatch(Local_Invoice_Success(response.data.data))
      dispatch(localinvoicecountsuccess(response.data.totalRecords))
      toast.update(toastIdLocal.current, { render: "Local Refreshed", type: "success", isLoading: false, position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",})
    })
    .catch(error=>{
      const errorMsg=error.message
      dispatch(Local_Invoice_Failure(errorMsg))
      toast.update(toastIdLocal.current, { render: "Failed To Refresh", type: "error", isLoading: false, position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",})
    })
    
  }

  const refreshEmail = () =>{
    // dispatch(allEmailInvoice(pageEmail,limitEmail))
    let p=localStorage.getItem("pageNumEmailInvoices");
    let rowPer = localStorage.getItem("rowsPerPageEmailInvoices");

    toastId.current = toast.loading("Refreshing Email ...")
    const token = window.localStorage.getItem("token");
    axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/fetchemailinvoice/", {
      params: {
        page: p,
        limit:rowPer,
        startDate: fromDate,
        endDate: toDate,
        status: "",
        FileName: "",
      }, 
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response=>{
      dispatch(Email_Invoice_Success(response.data.data))
      dispatch(emailinvoicecountsuccess(response.data.totalRecords))
      toast.update(toastId.current, { render: "Email Refreshed", type: "success", isLoading: false, position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",})
    })
    .catch(error=>{
      const errorMsg=error.message
      dispatch(Email_Invoice_Failure(errorMsg))
      toast.update(toastId.current, { render: "Failed To Refresh", type: "error", isLoading: false, position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",})
    })
  }

  return (<>
  <div className="header-btn"><Button
          variant="outlined"
          color="primary"
          component="label"
          className='upload-btn'
					disabled={role === 'ReviewerW' || role === 'Reviewer' ? 'none' : '' }
        >
          Upload
          <input
            type="file"
            name="file-upload"
            id="input"
            onChange={fileHandler}
            hidden
          />
        </Button>
          <Button
              variant="outlined"
              color="primary"
              component="label"
              onClick={refreshLocal}
              className='local-btn'
            >
              Refresh Local
          </Button>
          <Button
              variant="outlined"
              color="primary"
              component="label"
              onClick={refreshEmail}
              className='email-btn'
            >
              Refresh Email
          </Button>
          </div>
    <div style={{ width: '100%', marginTop:20 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
        }}
      >
        <EmailTableheader/>
      </Box>
    </div>
      {/* <Helmet>
        <title>Split PDF </title>
      </Helmet> */}
 </> );
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadInvoice: (file, fileUrl, token,state) =>
      dispatch(uploadInvoice(file, fileUrl, token,state)),
    refreshLocal: (page, limit) =>
      dispatch(allLocalInvoice(page, limit)),
    refreshEmail: (page, limit) =>
    dispatch(allEmailInvoice(page, limit)),
  };
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlexDirection)
