/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */
 
import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  USER_LOGIN,
  USER_LOGOUT,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAILURE,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  UPDATED_SUCCESS,
} from "./userAuthTypes";
import axios from "axios";

export const user_login = () => {
  return {
    type: USER_LOGIN,
  };
};

export const user_logout = (history) => {
  history.push("/login");
  localStorage.removeItem("token");
  localStorage.removeItem("pageNumAllInvoices");
  localStorage.removeItem("rowsPerPageAllInvoices");
  return {
    type: USER_LOGOUT,
  };
};

export const login_success = (token) => {
  return {
    type: LOGIN_SUCCESS,
    token: token,
  };
};

export const login_fail = () => {
  return {
    type: LOGIN_FAILURE,
  };
};
export const registration_success = (data) => {
  return {
    type: REGISTRATION_SUCCESS,
    data: data,
  };
};
export const userUpdated = (data) => {
  return {
    type: UPDATED_SUCCESS,
    data: data,
  };
};
export const registration_fail = () => {
  return {
    type: REGISTRATION_FAILURE,
  };
};
export const fetchuserssuccess=(users)=>{
  return{
    type:FETCH_USERS_SUCCESS,
    users:users
  }
}
export const fetchusersfailure=(err)=>{
  return {
    type:FETCH_USERS_FAILURE,
    err:err
  }
}
export const authenticateUser = (companyName,userId, password, history, location) => {
 
  return (dispatch) => {
    dispatch(user_login());
    const user = {
      companyName:companyName,
      userID: userId,
      pwd: password,
    };
    let url = process.env.REACT_APP_BACKEND_API + "api/users/login";
    
    axios
      .post(url, { user })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("modelType", res.data.subscription);
        localStorage.setItem("UserRole",res.data.role);
        localStorage.setItem("UserId",res.data.userId);
        localStorage.setItem("companyName",res.data.company_Name);
        const now = new Date();
        const expirationDate = now.getTime() + res.data.expiresIn * 1000;
        localStorage.setItem("expiresIn", expirationDate);
        dispatch(login_success(res.data.token));
        //runLogoutTimer(dispatch, res.data.expiresIn * 1000, history);
        history.push(location.toString())
        window.location.reload();
      })
      .catch((error) => {
        dispatch(login_fail());
      });
  };
};

function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(user_logout(history));
  }, timer);
}

export function autoLogin(dispatch, history) {
  const authInformation = getAuthData();
  localStorage.setItem("lastPage", history.location.pathname);
  if (!authInformation) {
    dispatch(user_logout(history));
    return;
  }
  const now = new Date();
  console.log(authInformation.expiration.getTime())
  console.log(now.getTime())
  const expiresIn = authInformation.expiration.getTime() - now.getTime();
  if (expiresIn > 0) {
    dispatch(login_success(authInformation.token));
    //runLogoutTimer(dispatch, expiresIn, history);
  } else {
    dispatch(user_logout(history));
    return;
  }
}

export function getAuthData() {
  const token = window.localStorage.getItem("token");
  const expiration =window.localStorage.getItem("expiresIn");

  if (!expiration || !token) {
    return;
  }

  return {
    token: token,
    expiration: new Date(parseInt(expiration)),
  };
}
export const registerUser = (FirstName,lastName,email,userId, password,role) => {
  const token = window.localStorage.getItem("token");
  return (dispatch) => {
    const user = {
      FirstName:FirstName,
      lastName:lastName,
      email:email,
      userID: userId,
      pwd: password,
      role:role,
    };
    let url =process.env.REACT_APP_BACKEND_API + "api/storeuser/userdetails";
    axios
      .post(url, { user },{
        headers: {
        Authorization: `Bearer ${token}`,
      }})
      .then((res) => {
        // localStorage.setItem("token", res.data.token);
        // localStorage.setItem("modelType", res.data.subscription);
        // const now = new Date();
        // const expiratsionDate = now.getTime() + res.data.expiresIn * 1000;
        // localStorage.setItem("expiresIn", expirationDate);
        dispatch(registration_success(res.data));
        dispatch(GetUsers())
      })
      .catch((error) => {
        dispatch(registration_fail());
      });
  };
};

export const GetUsers=()=>{
  const token = window.localStorage.getItem("token");
  return (dispatch)=>{
    axios.get( process.env.REACT_APP_BACKEND_API + "api/storeuser/getusers",{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response=>{
      // const invoice=response.data
      console.log(response.data)
      dispatch(fetchuserssuccess(response.data))
    })
    .catch(error=>{
      const errorMsg=error.message
      dispatch(fetchusersfailure(errorMsg))
    })
  }
}

// UPDATE PROFILE
export const updateUserProfile = (id, name, password, role) => {
  console.log("name , password:-", name, password, role);
  const token = window.localStorage.getItem("token");
  return (dispatch) => {
    const user = {
      userId: name,
      pwd: password,
      role: role,
      _id: id,
    };

    let url = process.env.REACT_APP_BACKEND_API + "api/storeuser/updateuser";
    axios
      .put(url, user, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(userUpdated(res.data));
        console.log("successfullll");
        window.location.reload();
      })
      .catch((error) => {
        dispatch(registration_fail());
      });
  };
};
 //delete user
export const deleteUser = (id) => {
  const token = window.localStorage.getItem("token");
  return (dispatch)=> {axios
    .delete(process.env.REACT_APP_BACKEND_API + `api/storeuser/deleteuser`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { id },
    })
    .then((response) => {
      dispatch(GetUsers())
    })
    .catch((error) => {
      console.log(error);
    });
  }
}