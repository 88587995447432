/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */

import React, { useState, Fragment } from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CancelIcon from '@mui/icons-material/Cancel';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import DownloadIcon from '@mui/icons-material/Download';
import './FileDisplay.css'
import Magnifier from "react-magnifier";
import { PDFDocument } from 'pdf-lib'
import axios from 'axios';
import { toast } from 'react-toastify';
import Alert from '@mui/material/Alert';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function FileDisplay({ data , Id} ) {
  let numPages = data ? data.length : null
  // const { mgShape, mgShowOverflow, } = this.props;
  const [pageNumber, setPageNumber] = useState(1);
  const [zooms, setzooms] = useState(true)
  const [loadingDown, setLoadingDown] = useState(false)
  // const [magnifier, setmagnifier] = useState(false)
  console.log(numPages)
  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }
  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  let currentImage = data ? data[pageNumber - 1] : null;

  let rotation = 0;
  function rotateImg() {
    rotation += 90; // add 90 degrees, you can change this as you want
    if (rotation === 360) {
      // 360 means rotate back to 0
      rotation = 0;
    }
    document.querySelector("#iFrameFileDisplay").style.transform = `rotate(${rotation}deg)`;
  }

  const zoombtn = () => {
    setzooms(true)
  }
  const magnifyebtn = () => {
    setzooms(false)
  }
  //   // const pdf = new jsPDF("portrait", "pt", "a4");
   //   // const data = await document.querySelector(".imageContainer");
   //   // // pdf.addImage(currentImage);
   //   // pdf.html(data).then(() => {
   //   //   pdf.save("invoice.pdf");
   //   // });
  
   const onButtonClick = async() => {
    try {
      setLoadingDown(true)
      const token = window.localStorage.getItem("token");
      await axios.get(process.env.REACT_APP_BACKEND_API + `api/invoice/splitlocalfile/${Id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(response=> {
            handleShowDialog(response.data.fileUrl, response.data.invoice.file_Name)
            })
            .catch(error=>{
              setLoadingDown(false)
              toast.error("Oops! this image cant be downloaded")
              })
    } catch (error) {
      setLoadingDown(false)
      toast.error("Oops! this image cant be downloaded")
    }
   }
 
const handleShowDialog = async(fileUrl,fileName) => {
try {
  const selected = ['1']
  const url1 = fileUrl;
  const firstDonorPdfBytes = await fetch(url1).then(res => res.arrayBuffer());
  const firstDonorPdfDoc = await PDFDocument.load(firstDonorPdfBytes);
  const pdfDoc = await PDFDocument.create();
  const [firstDonorPage] = await pdfDoc.copyPages(firstDonorPdfDoc, [
    selected - 1,
   ]);
   pdfDoc.addPage(firstDonorPage);
  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = "FileName" + new Date() + ".pdf";
  link.click();
  link.remove();
  setLoadingDown(false)
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
} catch (error) {
  setLoadingDown(false)
  toast.error("Oops! this image cant be downloaded")
}
   };

  if (zooms) {

    return (


      <div className="main">
          {
                loadingDown && (<div style={{
                    color:'lightblue',
                    position: 'absolute',
                    top: '100px',
                    left: '40vw',
                    display: 'flex',
                    justifyContent: 'center',
                    zIndex:'22'
                }}>
                    <Alert severity="info">Hang on downloading in progress . . .</Alert>
                </div>)
            }
<TransformWrapper 
          initialScale={1}
          initialPositionX={0}
          initialPositionY={0}
          limitToBounds={false} 
          >
          {
            ({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <Fragment>

                <div className="zoom">
                  <div className="pagec">
                    Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                  </div>
                  <div className="buttonc">
                    <button
                      type="button"
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                      className="Pre"
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                      className="nxt"
                    >
                      Next
                    </button>
                    <button className='zoomin' onClick={() => zoomIn()}>
                      <ZoomInIcon />
                    </button>
                    <button className='zoomout' onClick={() => zoomOut()}>
                      <ZoomOutIcon />
                    </button>
                    <button className='cancel' onClick={() => resetTransform()}>
                      <CancelIcon />
                    </button>
                    <button className='rotate' onClick={() => rotateImg()}><Rotate90DegreesCcwIcon/> </button>
                    <button className= 'download-btn' onClick={onButtonClick}><DownloadIcon /></button>
                    {/* <button className='zoomBtn' onClick={zoombtn}>Zoom</button>
                    <button className='magnifyeBtn' onClick={magnifyebtn}>Magnify</button> */}
                  </div> 
                </div>
                <TransformComponent>
                  <div className="imageContainer">
                    <img
                      id="iFrameFileDisplay"
                      title="Invoice"
                      src={currentImage}
                      alt=""
                    />
                  </div>
                </TransformComponent>
              </Fragment>
            )
          }
        </TransformWrapper>
      </div >
    );
  }



  return (


    <div className="main">



      <Fragment>

        <div className="zoom">
          <div className="pagec">
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </div>
          <div className="buttonc">
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
              className="Pre"
            >
              Previous
            </button>
            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              className="nxt"
            >
              Next
            </button>






            {/* <button className='zoomin' onClick={() => zoomIn()}>
                  <ZoomInIcon />
                </button>



                <button className='zoomout' onClick={() => zoomOut()}>
                  <ZoomOutIcon />
                </button>
                <button className='cancel' onClick={() => resetTransform()}>
                  <CancelIcon />
                </button> */}

            {/* <button className='zoomBtn' onClick={zoombtn}>Zoom</button> */}
            {/* <button className='magnifyeBtn' onClick={magnifyebtn}>Magnify</button> */}


            {/* <button onClick={() => rotateImg()}><Rotate90DegreesCcwIcon /></button> */}
          </div>
        </div>
        <div className='magnify'>
          <Magnifier
            id="iFrameFileDisplay"
            title="Invoice"
            src={currentImage}
            alt=""
            mgShape="square"
            mgWidth={250}
            mgHeight={200}
            // mgMouseOffsetX={100}
            zoomFactor={1}
          />
        </div>
      </Fragment>
    </div >
  )




}


