/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */
 
import React, { Component } from "react";
import "./Home.css";
import Invoicetables from '../../components/InvoiceTables/invoicetables';
import Header from '../../components/Header/header'



//import MainImg from "../../assets/test.gif";
//import fileImg from "../../assets/fileGirl.svg";
//import UploadButton from "../../components/FileUpload/DocUploadBtn"
//import ModelType from "../../components/ModelType/ModelType"

function HomeContainer(){
  return (<>
    <div className="headertab">
<Header/>
  {/* <Invoicetables/> */}
  </div>
 </> )
  
}

// class HomeContainer extends Component {

//   render() {
//     return (
//       <div className="container">
//         <img id="mainImg" src={MainImg} alt=""></img>
//         <div id="btn">
//           <img id="fileGirl" src={fileImg} alt=""></img>
//           <ModelType></ModelType>
//           <div id="btnUploadDoc">
//           <UploadButton></UploadButton>
//           </div>
          
//         </div>
//       </div>
//     );
//   }
// }

export default HomeContainer; 