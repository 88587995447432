import { React, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./AllocateInvoice.css";
import { GetUsers } from "../../redux";
import {
  fetchUserDetails,
  allocatesuccess,
  Allocate,
  AllocateCount,
  allocatecountsuccess,
} from "../../redux";
import { connect, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

function Users({ state }) {
  console.log(state, "state");
  const userReportData = state.invoice.userDetails;
  const dispatch = useDispatch();
  const userData = state.user;
  const AllocateData = state.invoice.AllocateInvoice;
  const AllocateCountData = state.invoice.AllocateCountInvoice;
  const todaysAllocate = AllocateCountData && AllocateCountData.todaysAllocate;
  const unPushedData = AllocateCountData && AllocateCountData.unPushedData;
  const NotAssignedCount = AllocateData && AllocateData.afterAllocation;
  const rows = userData && userData.users;
  const [allocateNumber, setAllocateNumber] = useState("");
  const [selectedDropDown, setSelectedDropDown] = useState("");
  const [userId, setuserId] = useState("");
  const [errorMessage , setErrorMessage] = useState("")
  const [loadingDown, setLoadingDown] = useState(false);
  const [allocateLoading, setaAllocateLoading] = useState(false);
  const [ ButtonView,setButtonView] =useState(false);

  const selectionChangeHandler = (event) => {
    let val = event.target.value;
    console.log("Invoking", val);
    setSelectedDropDown(val);
    var filteredValue = rows.filter((element, index) => {
      return element.userId === event.target.value;
    });
    let userId = filteredValue[0]._id;
    console.log(userId, "userId");
    setuserId(userId);
  };
  let userNames = [];
  let userIds = [];

  for (let i = 0; i < rows.length; i++) {
    if (rows[i].role === "ReviewerW" || rows[i].role === "Reviewer") {
      userNames.push(rows[i].userId);
      userIds.push(rows[i]._id);
    }
  }

  // useEffect(()=>{
  //   dispatch(GetUsers());
  // },[]);

  useEffect(() => {
    const fetchingAPIs = async () => {
       dispatch(GetUsers());
      // dispatch(fetchUserDetails());
      dispatch(Allocate());
    };
    fetchingAPIs();
  }, []);

  //this get called when the rows.length changes its not called on the first time
  // useEffect(() => {
  //   if (userNames.length > 0) {
  //     dispatch(AllocateCount(userNames, userIds));
  //   }
  // }, [rows.length]);
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  let headCells = [
    {
      id: "AllUsers.",
      numeric: true,
      disablePadding: true,
      label: "All Users",
    },
    // {
    //   id: "FromYesterdaysBalance",
    //   numeric: true,
    //   disablePadding: true,
    //   label: "From Yesterdays Balance",
    // },
    {
      id: "TodayAssignedCount",
      numeric: true,
      disablePadding: true,
      label: "Todays Assigned Count",
    },
    {
      id: "unpushedCount",
      numeric: true,
      disablePadding: true,
      label: "Total Pending Count",
    },
  ];

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    table: {
      maxWidth: 800,
      border: "1px solid black",
    },
  }));

  const handleChange = (event) => {
    setAllocateNumber(event.target.value);
  };

  function EnhancedTableHead(props) {
    return (
      <TableHead>
        <TableRow></TableRow>
        <TableRow>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? "center" : "center"}
              padding={headCell.disablePadding ? "normal" : "normal"}
            >
              {headCell.label}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const classes = useStyles();

  const  AllocateCountF = async (event) =>{
    //await dispatch(GetUsers());
    await dispatch(fetchUserDetails()); 
    await dispatch(AllocateCount(userNames, userIds));
    setButtonView(true);
  }

  const AllocateInvoice = async (event) => {
    if(allocateNumber < 1 || selectedDropDown == "" ){
      if(allocateNumber < 1){
        setErrorMessage(" *Required field")
      }
      event.preventDefault();
    }else{
    const token = window.localStorage.getItem("token");
    setLoadingDown(true);
    let data = {
      requestCount: allocateNumber,
      reviewerName: selectedDropDown,
      reviewer: userId,
    };
    const req = {
      params: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    console.log(req);
    await axios
      .get(process.env.REACT_APP_BACKEND_API + "api/invoice/Allocate", req)
      .then((response) => {
        console.log(response.data.Remaining, "Remaining");
        let Remaining = response.data.Remaining;
        if (Remaining > 0) {
          toast(`${Remaining} invoices were not Allocated`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        document.getElementById("formatted-numberformat-input").value = "";
        setAllocateNumber("")
        setSelectedDropDown("")
        setLoadingDown(false);
        dispatch(allocatesuccess(response.data));
        // dispatch(AllocateCount(userNames, userIds));
      });
      //AllocateCountSearch(userNames, userIds)
    }
  };

  const AllocateCountSearch = async(userNames, userIds) => {
    const token = window.localStorage.getItem("token");
    setaAllocateLoading(true)
            const req = {
                params: {userNames , userIds}, 
                headers: {
                Authorization: `Bearer ${token}`,
                }
            }
        console.log(req)
        await axios.get(process.env.REACT_APP_BACKEND_API + "api/invoice/Allocatecount", req).then(response=> {
          dispatch(allocatecountsuccess(response.data))
          setaAllocateLoading(false)
        })
}
  return (
    <>
      <div class="containerResults">
        {loadingDown && (
          <div
            style={{
              position: "absolute",
              top: "100px",
              left: "40vw",
              display: "flex",
              justifyContent: "center",
              zIndex: 22,
            }}
          >
            <Alert severity="info">
              Please wait Allocating {allocateNumber} invoice to {selectedDropDown} in progress . . .
            </Alert>
          </div>
        )}
        <div className="Users">
          <TableContainer>
          <Button
              style={{ marginTop: 15, marginLeft: 10 ,marginBottom:10 }}
              variant="contained"
              onClick={AllocateCountF}
            >
              Fetch Allocate Table
            </Button>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
              id="table-to-xls"
            >
              <EnhancedTableHead classes={classes} />

              {ButtonView && rows.map((val, key) => {
                if(val.role === "ReviewerW" || val.role === "Reviewer"){
                if(userReportData){
                  for(let i = 0; i<userReportData.length ; i++){
                    if(userReportData[i].userName ==  val.userId ){
                      var Balance =
                      userReportData[i].data[0].assignedCount - userReportData[i].data[0].pushedCount;
                      if (Balance < 0) {
                        Balance = 0;
                      }
                    }
                }}
                if (todaysAllocate) {
                  for (let i = 0; i < todaysAllocate.length; i++) {
                    if (todaysAllocate[i].username == val.userId) {
                      var assignedCount = todaysAllocate[i].todaysCount;
                    }
                  }
                }
                if (unPushedData) {
                  for (let i = 0; i < unPushedData.length; i++) {
                    if (unPushedData[i].userNames == val.userId) {
                      var unPushedDataCount = unPushedData[i].unPushedData;
                    }
                  }
                }

              
                return (
                  <TableRow key={key}>
                    <TableCell align="center">{val.userId}</TableCell>
                    {/* <TableCell align="center">{Balance}</TableCell> */}
                    <TableCell align="center">
                      {todaysAllocate == undefined || allocateLoading ? (
                        <Box>
                          <CircularProgress color="secondary" size={20} />
                        </Box>
                      ) : (
                        assignedCount + Balance
                      )}
                    </TableCell>
                    {/* <TableCell>
                      {Balance}
                    </TableCell> */}
                    <TableCell align="center">
                      {unPushedData == undefined ? (
                        <Box>
                          <CircularProgress color="secondary" size={20} />
                        </Box>
                      ) : (
                        unPushedDataCount
                      )}
                    </TableCell>
                  </TableRow>
                );
          }
        })}
            </Table>

          </TableContainer>
        </div>
        <div class="formDetails">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <ToastContainer />
          <div className="notAssigned">
            <>
              {NotAssignedCount ? (
                `Total Not assigned = ${NotAssignedCount}`
              ) : NotAssignedCount == 0 ?  `Total Not assigned = ${NotAssignedCount}`: (
                <Box>
                  <CircularProgress color="secondary" size={20} />
                </Box>
              )}
            </>
          </div>
          <div className="fields">
            <select
              id="dropdown-basic-button"
              value={selectedDropDown}
              onChange={(e) => selectionChangeHandler(e)}
              style={{
                backgroundColor: "white",
                borderRadius: "3px",
                margin: "9px 15px 5px 15px",
                width: "150px",
                height: "55px",
                textTransform: "uppercase",
              }}
            >
              <option value=""> All User's </option>
              {rows.map((val, key) => (
                <>
                  {val.role === "ReviewerW" || val.role === "Reviewer" ? (
                    <option value={val.userId}>{val.userId}</option>
                  ) : null}
                </>
              ))}
            </select>
            <TextField
             helperText={errorMessage}
            error={!!errorMessage}
              style={{ width: 140, margin: "0px 30px" }}
              label="Allocate Invoice"
              variant="outlined"
              name="numberformat"
              id="formatted-numberformat-input"
              inputProps={{ type: "number" , minLength :1 }}
              onChange={handleChange}
            />

            <Button
              style={{ marginTop: 15, marginLeft: 23 }}
              variant="contained"
              onClick={AllocateInvoice}
              // disabled={todaysAllocate == undefined || allocateLoading ? "disabled": ""}
            >
              Allocate
            </Button>

          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getusers: () => dispatch(GetUsers()),
  };
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
