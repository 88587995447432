import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux'
import { fetchInvoice } from "../../redux"
import {Link} from 'react-router-dom'



const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



const headCells = [
  { id: 'InvoiceID', numeric: false, disablePadding: true, label: 'Invoice ID' },
  { id: 'PurchaseOrder', numeric: true, disablePadding: false, label: 'Purchase Order' },
  { id: 'VendorName', numeric: false, disablePadding: false, label: 'Vendor Name' },
  { id: 'InvoiceDate', numeric: true, disablePadding: false, label: 'Invoice Date' },
  { id: 'InvoiceTotal ', numeric: false, disablePadding: false, label: 'Invoice Total' }
  // { id: 'is_approved', numeric: false, disablePadding: false, label: 'Approved' }
 
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead>
      {/* <TableRow>
            <StyledTableCell />
            <StyledTableCell align="center" colSpan={2}>
             Show all ({rowCount})
            </StyledTableCell>
            <StyledTableCell align="center" colSpan={3}>
              Approved 
            </StyledTableCell>
          </TableRow> */}
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            style={{ color: 'white' }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },

}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">

        </Typography>
      )}

    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  table: {
    minWidth: 450,
    marginTop: 40
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  searchInput: {
    width: '30%',
    marginTop: '40px',
    marginLeft: 10
  },
  button: {
    float: 'right',
    background: '#0d6efd',
    marginTop: '40px',
    marginRight: 20
  }
}));

function InvoiceTable({ invoiceData, fetchInvoice }) {
  const classes = useStyles();
  const [order, setOrder] =useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] =useState(5);
  
  
  
console.log(invoiceData)


  useEffect(() => {
    fetchInvoice()
    
  }, [])

  const invdata=invoiceData && invoiceData.invoice && invoiceData.invoice.length
  console.log(invdata)
  const rData=invoiceData && invoiceData.invoice
  console.log(invoiceData && invoiceData.invoice )
  
  // console.log(rData.map((inv=>inv.is_approved)))

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds =rData.map((n) => n.data.map((d => d.InvoiceId)));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;

const emptyRows = rowsPerPage - Math.min(rowsPerPage, (invdata) - page * rowsPerPage);
console.log(emptyRows)

  return invoiceData.loading ? (
    <h2>Loading</h2>
    ):invoiceData.error?(
    <h2>{invoiceData.error}</h2>
    ):(
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={invdata}
            />
            <TableBody>
              
              {invoiceData && invoiceData.invoice.map((inv,index)=>{
              const row = inv.extractedData
              const isItemSelected = isSelected(row.InvoiceId);
              const labelId = `enhanced-table-checkbox-${index}`;
              const approved=inv.is_approved
              console.log(approved)

              return (
              <TableRow
                hover
                onClick={(event) => handleClick(event, row.InvoiceId)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.InvoiceId}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell align="right" component="th" id={labelId} scope="row" padding="none">
                <Link to={`/results/${inv._id}`}>    {row.InvoiceId}</Link>
                </TableCell>
                <TableCell align="right">{row.PurchaseOrder}</TableCell>
                <TableCell align="right">{row.VendorName}</TableCell>
                <TableCell align="right">{row.InvoiceDate}</TableCell>
                <TableCell align="right">{row.InvoiceTotal}</TableCell>
             
              </TableRow>
              );
                  
              })} 
                   
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={invdata}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

    </div>


  );
}

const mapStateToProps = (state) => {
  return {
    invoiceData: state.invoice,
   
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchInvoice: () => dispatch(fetchInvoice())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceTable)

