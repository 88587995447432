/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */

 import {
  ANALYSING_COMPLETED_FAILURE,
  ANALYSING_COMPLETED_SUCCESS,
  UPLOAD_INVOICE,
  ANALYSING_INVOICE,
  CHANGE_MODEL_TYPE,
  CHANGE_FIELD_VALUE,
  APPROVE_INVOICE,
  APPROVE_PUSH,
  FETCH_INVOICE_REQUEST,
  FETCH_INVOICE_SUCCESS,
  FETCH_INVOICE_FAILURE,
  FETCH_SINGLE_INVOICE_REQUEST,
  FETCH_SINGLE_INVOICE_SUCCESS,
  FETCH_SINGLE_INVOICE_FAILURE,
  UPDATE_USER,
  FETCH_APPROVED_INVOICE,
  FETCH_UNAPPROVED_INVOICE,
  FETCH_PUSHED_INVOICE,
  REMOVE_INVOICE,
  PUSH_INVOICE,
  FETCH_INVOICE_COUNT,
  BULK_PUSH_INVOICE_SUCCESS,
  BULK_APPROVE_INVOICE_SUCCESS,
  APPROVE_INVOICE_COUNT_SUCCESS,
  UNAPPROVE_INVOICE_COUNT_SUCCESS,
  APPROVE_INVOICE_COUNT_FAILURE,
  EMAIL_INVOICE_SUCCESS,
  EMAIL_INVOICE_FAILURE,
  EMAIL_INVOICE_COUNT_SUCCESS,
  EMAIL_INVOICE_COUNT_FAILURE,
  FILE_DISPLAY_WITH_ID_FAILURE,
  FILE_DISPLAY_WITH_ID_SUCCESS,
  SPLIT_INVOICE,
  UPLOADING_COMPLETED_SUCCESS,
  UPLOADING_COMPLETED_FAILURE,
  LOCAL_INVOICE_COUNT_SUCCESS,
  LOCAL_INVOICE_COUNT_FAILURE,
  LOCAL_INVOICE_SUCCESS,
  LOCAL_INVOICE_FAILURE,
  LOCAL_FILE_DISPLAY_WITH_ID_FAILURE,
  LOCAL_FILE_DISPLAY_WITH_ID_SUCCESS,
  DISAPPROVE_INVOICE,
  FETCH_INVOICE_REPORTS_SUCCESS,
  FETCH_INVOICE_REPORTS_FAILURE,
  REMOVE_INVOICE_FROM_SPLIT,
  FETCH_USER_DETAILS_SUCCESS,
  ALLOCATE_SUCCESS,
  ALLOCATE_COUNT_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  DOWNLOAD_FORMATE_TYPE,
  UPLOAD_FETCH_SUCCESS,
} from "./invoiceTypes";

const initialState = {
  fileUrl: "",
  file: "",
  uploadFileType: "",
  error: "",
  data: {},
  extractedData:{},
  isAnalyzing: false,
  extractedTables: "",
  originalTables: "",
  modelType: "",
  is_approved: {},
  is_pushed: {},
  invoice: [],
  invdata: [],
  approvedinvoice: [],
  filteredboxes: [],
  PushData: [],
  Bulkinvoice: [],
  invlength: 0,
  loading: true,
  Bulkapprove: [],
  emaildata: [],
  filewithId: [],
  ApproveInvoiceCount: 0,
  emailInvoiceCount: 0,
  is_splitted: {},
  LocalInvoiceCount: 0,
  Localinvoice: [],
  localfileId:[],
  reports:[],
  userDetails:[],
  AllocateInvoice:[],
  AllocateCountInvoice:[],
  splitInvoiceToDelete:[],
  DownloadType:"WorkBook",
  UploadedFiles:{}
};

export const invoiceReducer = (state = initialState, action) => {
  console.log(action.type);
  switch (action.type) {
    case ANALYSING_INVOICE:
      return {
        ...state,
        isAnalyzing: true,
      };
    case ANALYSING_COMPLETED_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isAnalyzing: false,
        fileUrl: "",
        file: "",
        uploadFileType: "",
        invoice: [],
        invdata: [],
        emaildata: [],
        invlength: 0,
      };
    case ANALYSING_COMPLETED_FAILURE:
      return {
        ...state,
        error: action.payload || "Error Occured",
        fileUrl: "",
        file: "",
        uploadFileType: "",
        data: "",
      };
    case UPLOAD_INVOICE:
      return {
        fileUrl: action.fileUrl,
        isAnalyzing: false,
        file: action.file,
        uploadFileType: action.fType,
        data: "",
        error: "",
        originalTables: "",
        extractedTables: "",
        is_approved: false,
      };
    case CHANGE_MODEL_TYPE:
      localStorage.setItem("modelType", action.payload);
      return {
        ...state,
        modelType: action.payload,
      };
    case CHANGE_FIELD_VALUE:
      return {
        ...state,
        // data: action.payload.invoice.data,
        extractedData: action.payload.invoice.extractedData,
      };
    case APPROVE_INVOICE:
      return {
        ...state,
        is_approved: action.payload,
      };
      case APPROVE_PUSH:
			return {
				...state,
				is_pushed: action.payload,
			};
    case FETCH_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_INVOICE_SUCCESS:
      return {
        ...state,
        invoice: action.payload,
        loading: false,
        error: "",
      };
    case FETCH_INVOICE_FAILURE:
      return {
        loading: false,
        invoice: [],
        error: action.payload,
      };
    case FETCH_SINGLE_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SINGLE_INVOICE_SUCCESS:
      return {
        ...state,
        invdata: action.payload,
        loading: false,
        error: "",
      };
    case FETCH_SINGLE_INVOICE_FAILURE:
      return {
        loading: false,
        invdata: [],
        error: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        loading: false,
      };
    case FETCH_APPROVED_INVOICE:
      return {
        ...state,
        approvedinvoice: action.payload,
        loading: false,
        error: "",
      };
    case FETCH_UNAPPROVED_INVOICE:
      return {
        ...state,
        unapprovedinvoice: action.payload,
        loading: false,
        error: "",
      };
    case FETCH_PUSHED_INVOICE:
    return {
      ...state,
      pushedinvoice: action.payload,
      loading: false,
      error: "",
    };
    case REMOVE_INVOICE:
      return {
        ...state,
        filteredboxes: action.payload,
        loading: false,
        error: null,
      };
    case PUSH_INVOICE:
      return {
        ...state,
        PushData: action.payload,
        loading: false,
        error: null

      }
    case FETCH_INVOICE_COUNT:
      return {
        ...state,
        invlength: action.payload,
        loading: false,
        error: null,
      };
    case BULK_PUSH_INVOICE_SUCCESS:
      return {
        ...state,
        Bulkinvoice: action.payload,
        loading: false,
        error: null,
      };
    case BULK_APPROVE_INVOICE_SUCCESS:
      return {
        ...state,
        Bulkapprove: action.payload,
        loading: false,
        error: null,
      };
    case APPROVE_INVOICE_COUNT_SUCCESS:
      return {
        ...state,
        ApproveInvoiceCount: action.payload,
        loading: false,
        error: null,
      };
    case UNAPPROVE_INVOICE_COUNT_SUCCESS:
      return {
        ...state,
        UnApproveInvoiceCount: action.payload,
        loading: false,
        error: null,
      };
    case APPROVE_INVOICE_COUNT_FAILURE:
      return {
        loading: false,
        ApproveInvoiceCount: [],
        error: action.payload,
      };
    case EMAIL_INVOICE_SUCCESS:
      return {
        ...state,
        emaildata: action.payload,
        loading: false,
        error: null,
      };
    case EMAIL_INVOICE_FAILURE:
      return {
        loading: false,
        emaildata: [],
        error: action.payload,
      };
    case EMAIL_INVOICE_COUNT_SUCCESS:
      return {
        ...state,
        emailInvoiceCount: action.payload,
        loading: false,
        error: null,
      };
    case EMAIL_INVOICE_COUNT_FAILURE:
      return {
        loading: false,
        emailInvoiceCount: [],
        error: action.payload,
      };

    case FILE_DISPLAY_WITH_ID_SUCCESS:
      return {
        ...state,
        filewithId: action.payload,
        loading: false,
        error: null,
      };
    case FILE_DISPLAY_WITH_ID_FAILURE:
      return {
        loading: false,
        filewithId: [],
        error: action.payload,
      };
    case LOCAL_FILE_DISPLAY_WITH_ID_SUCCESS:
      return {
        ...state,
        localfileId: action.payload,
        loading: false,
        error: null,
      };
    case LOCAL_FILE_DISPLAY_WITH_ID_FAILURE:
      return {
        loading: false,
        localfileId: [],
        error: action.payload,
      };
    case SPLIT_INVOICE:
      return {
        ...state,
        is_splitted: action.payload,
      };
    case UPLOADING_COMPLETED_SUCCESS:
      return {
        ...state,
        fileUrl: action.fileUrl,
        file: action.file,
        is_splitted: false,
      };
    case UPLOADING_COMPLETED_FAILURE:
      return {
        ...state,
        error: action.payload || "Error Occured",
        fileUrl: "",
        file: "",
      };
    case LOCAL_INVOICE_COUNT_SUCCESS:
      return {
        ...state,
        LocalInvoiceCount: action.payload,
        loading: false,
        error: null,
      };
    case LOCAL_INVOICE_COUNT_FAILURE:
      return {
        loading: false,
        LocalInvoiceCount: [],
        error: action.payload,
      };
    case LOCAL_INVOICE_SUCCESS:
      return {
        ...state,
        Localinvoice: action.payload,
        loading: false,
        error: null,
      };
    case LOCAL_INVOICE_FAILURE:
      return {
        loading: false,
        Localinvoice: [],
        error: action.payload
      }
      case DISAPPROVE_INVOICE:
        return {
          ...state,
          is_approved: action.payload
        }
        case FETCH_INVOICE_REPORTS_SUCCESS:
          return {
          ...state,
          reports:action.payload.data,
          loading:false,
          error:null
        }
        case FETCH_INVOICE_REPORTS_FAILURE:
          return {
          loading:false,
          reports:[],
          error:action.payload,
        }
        case FETCH_USER_DETAILS_SUCCESS:
          return {
          ...state,
          userDetails:action.payload.data,
          loading:false,
          error:null
        }
        case ALLOCATE_SUCCESS:
          return {
          ...state,
          AllocateInvoice:action.payload,
          loading:false,
          error:null
        }
        case ALLOCATE_COUNT_SUCCESS:
          return {
          ...state,
          AllocateCountInvoice:action.payload,
          loading:false,
          error:null
          }
          case DOWNLOAD_FORMATE_TYPE:
          return {
          ...state,
          DownloadType:action.payload,
          loading:false,
          error:null
          }
          case UPLOAD_FETCH_SUCCESS:
            return {
            ...state,
            UploadedFiles:action.payload,
            Total: 0,
            loading:false,
            error:null
            } 
        case FETCH_USER_DETAILS_FAILURE:
          return {
          loading:false,
          reports:[],
          error:action.payload,
        }
        case REMOVE_INVOICE_FROM_SPLIT:
          return {
            ...state,
            splitInvoiceToDelete: action.payload,
            loading: false,
            error: null,
          }
    default:
      return state;
  }
};
