/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */
 
 import React, { Component } from "react";
 import { connect } from "react-redux";
 import Register from "../../components/UserRegistration/userRegistration";
 
 class Registration extends Component {
   render() {
     return(
     <Register></Register>
     )
   }
 }
 
 const mapDispatchToProps = (dispatch) => {
   return {};
 };
 
 const mapStateToProps = (state) => {
   return {
     state: state,
   };
 };
 
 export default connect(mapStateToProps, mapDispatchToProps)(Registration);
 