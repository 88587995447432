/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */

import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { registerUser } from "../../redux";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Box from "@material-ui/core/Box";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import { Link, useHistory } from "react-router-dom"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import loader from '../../assets/loadingImage.gif'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ToastContainer, toast } from 'react-toastify';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './userRegistration.css'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  paper: {
    margin: '5% 5%',
    display: 'flex',
    marginTop: '10vh',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    // Fix IE 11 issue.

  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backbtn: {
    marginleft: "67%"
  }
}));

function Register(props) {
  console.log("register")

  const classes = useStyles();
  let errorsObj = { FirstName: '', lastName: '', email: '', role: '', UserId: '', password: '' };
  const [FirstName, setFirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [UserId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const dispatch = useDispatch();
  let history = useHistory();
  // const admin = errorsObj.role
  // console.log(admin);
  let admin = role
  console.log(admin);

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


  function onRegistrationClick(e) {
    e.preventDefault();
    let error = false;

    const errorObj = { ...errorsObj };
    if (FirstName == '') {
      errorObj.FirstName = 'FirstName is Required';
      error = true;
    }
    if (lastName == '') {
      errorObj.lastName = 'lastName is Required';
      error = true;
    }
    if (email == '') {
      errorObj.email = 'email is Required';
      error = true;
    }
    if (role == '') {
      errorObj.role = 'role is Required';
      error = true;
    }
    if (UserId === '') {
      errorObj.UserId = 'UserId is Required';
      error = true;
    }

    if (password === '') {
      errorObj.password = 'Password is Required';
      error = true;
    }

    if (error) {
      setOpen(true);
      return
    }
    dispatch(registerUser(FirstName, lastName, email, UserId, password, role, history))
    toast.success("Registered Successfully!");
    setFirstName('');
    setlastName('');
    setEmail('');
    setRole('');
    setUserId('');
    setPassword('');


  }
  const handleChange = (prop) => (event) => {
    setPassword(event.target.value)
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  return (
    <ExpansionPanel>
    <ExpansionPanelSummary
      expandIcon={<ExpandMoreIcon />}
      id="additional-actions1-header"
    >
      <FormControlLabel
        onClick={event => event.stopPropagation()}
        onFocus={event => event.stopPropagation()}
        control={<PersonPinIcon style={{visibility:'hidden' }}/>}
        // label=" User Registration"
      />
      <p id='label'> <PersonPinIcon style={{color:'black', fontSize:30,marginRight:10}}/>User Registration</p>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
    <Grid container component="main" className={classes.root}  style={{ justifyContent:'center' }}>

      <CssBaseline />
      {/* <Grid item xs={false} sm={4} md={8} className={classes.image} /> */}
      {!props.state.user.validating ?

        <Grid item xs={6} component={Paper} square>

          <div className={classes.paper}>
            {/* <Button className={classes.backbtn}>back</Button>  */}
            <Avatar className={classes.avatar}>
              <PersonAddAltIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Create User
            </Typography>
            <form className={classes.form} onSubmit={onRegistrationClick} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="FirstName"
                label="FirstName"
                name="FirstName"
                onChange={(e) =>
                  setFirstName(e.target.value)
                }
                autoComplete="FirstName"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="lastName"
                label="lastName"
                name="lastName"
                onChange={(e) =>
                  setlastName(e.target.value)
                }
                autoComplete="lastName"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="email"
                name="email"
                onChange={(e) =>
                  setEmail(e.target.value)
                }
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="UserId"
                label="User ID"
                name="UserId"
                onChange={(e) =>
                  setUserId(e.target.value)
                }
                autoComplete="UserId"
                autoFocus
              />
              {/* <TextField 
                variant="outlined"
                margin="normal"
                required
                fullWidth
                onChange={(e) =>
                  setPassword(e.target.value)
                }
                name="password"
                label="Password"
                type="password"
                id="password"
              /> */}
              
              <FormControl fullWidth required variant="outlined"  margin="normal"  >
                  <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                    <FilledInput style={{ minHeight: '60px' }}
                      id="filled-adornment-password"
                      type={values.showPassword ? 'text' : 'password'}
                      value={values.password}
                      label="Password"
                      onChange={
                        handleChange('password')
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
               </FormControl>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Role
                  </InputLabel>
                  <NativeSelect
                    onChange={(e) =>
                      setRole(e.target.value)
                    }
                    inputProps={{
                      name: 'Role',
                      id: 'role',
                    }}
                  >
                    <option value="Admin">Admin</option>
                    {/* <option value="Approver">Approver</option> */}
                    <option value="Reviewer">Reviewer With Push Access</option>
                    <option value="ReviewerW">Reviewer Without Push Access</option>
                    <option value="Client">Client Access</option>
                    <option value="Viewer">Viewer</option>
                  </NativeSelect>
                </FormControl>
              </Box>

              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Register
              </Button>
            </form>

          </div>
        </Grid> :
        <Grid item xs={12} sm={6} md={6} elevation={6} square>
          <img src={loader} alt="" id="loadingImg"></img>
        </Grid>
      }
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="error">Fill the required fields for sigin</Alert>
      </Snackbar>
      <Snackbar open={props.state.user.authError} autoHideDuration={6000}>
        <Alert severity="error">Authenticatication Failed. Please Check the Credentials</Alert>
      </Snackbar>
    </Grid>
    
     </ExpansionPanelDetails>
     </ExpansionPanel>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (firstname, lastname, email, username, password, role) => dispatch(registerUser(firstname, lastname, email, username, password, role)),
  };
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Register)