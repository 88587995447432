import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { singleIdLocalFileDisplay, sendForAnalyze, updateSplitting, sendForAnalyzeSplitPdf  } from "../../redux"
import { connect, useDispatch } from 'react-redux'
import { useParams, useHistory } from "react-router-dom";
import './splitpdflocalfile.css';
import { PDFDocument } from 'pdf-lib'
import { Button } from "@material-ui/core";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from 'axios';
import moment from "moment";
import Badge from 'react-bootstrap/Badge';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from "../Dialog/dialog";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const useStyles = makeStyles({
    tableRow: {
        height: 10
    },
    tableCell: {
        padding: "0px 10px"
        }
    });
    function LinearProgressWithLabel(props) {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                props.value
              )}%`}</Typography>
            </Box>
          </Box>
        );
      }
      
      LinearProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired,
      };
function Splitfile({ invData, singleIdLocalFileDisplay }) {
    const classes = useStyles();

    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [selected, setSelected] = useState([]);
    const [progress, setProgress] = useState(10);

    const [isprocessed,setisprocessed]=useState(false)
    const [isSingleprocessed,setisSingleprocessed]=useState(false)
    const [data, setData] = useState('')
    const [checkboxState, setCheckboxState] = useState({});
    const [isChecked, setIsChecked] = useState(false);
    const companyName =localStorage.getItem("companyName")

    useEffect(() => {
        // Check the checkbox if the company name is 'ceat'
        if (companyName === "Ceat" || companyName =="CEAT" || companyName =="SAPPHIRE" || companyName =="Sapphire") {
          setIsChecked(true);
          let newArray =[...selected,'1']
          setSelected(newArray);
        }
      }, [companyName]);

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    //console.log(invData)

    let status=invData && invData.data && invData.data.data && invData.data.data.status
    //console.log(status)
    // let rData = invData && invData.localfileId && invData.localfileId.invoice && invData.localfileId.invoice.splittedInvoices && invData.localfileId.invoice.splittedInvoices[0]
    // console.log(rData,'1111')
    // const entries = Object.entries(rData)
    // console.log(entries)
    
    useEffect(async () => {
        await singleIdLocalFileDisplay(id)
    },[id])

    const fileUrl = invData && invData.localfileId && invData.localfileId.fileUrl
    const fileName = invData && invData.localfileId && invData.localfileId.invoice && invData.localfileId.invoice.file_Name
    console.log(fileName)
    const url = fileUrl
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        //console.log(numPages)
    }


    const handleChange = (e) => {
        console.log("change")
        var item = e.target.id;
        const checkboxC = e.target.checked;
        //console.log(item)
        //new code
        if (item == 1 && (companyName =="Ceat" || companyName =="CEAT" || companyName =="SAPPHIRE" || companyName =="Sapphire")) {
            setIsChecked(!isChecked);
          } else {
                //console.log("if is running");
                setCheckboxState({
                  ...checkboxState,
                  [item]: checkboxC
                });
          }
          //old code
        let newArray = [...selected];
        //console.log(newArray)
        if (selected.includes(e.target.id)) {
            //console.log("paramsif")
            newArray = selected.filter(pageno => pageno !== e.target.id);
        }else{
            newArray = [...selected, e.target.id]
            newArray.sort()
        }


        setSelected(newArray)
    }
    const pages = selected.toString();
    console.log(pages, id, 'Params')
    console.log(selected)


    async function copyPages() {
        if(rDataStatus > 0){
			handleDialog("Already Processed, Do you want to Process again?", true);
        }else{
            try{
                setisprocessed(true)
                const url1 = fileUrl
                const firstDonorPdfBytes = await fetch(url1).then(res => res.arrayBuffer())
                const firstDonorPdfDoc = await PDFDocument.load(firstDonorPdfBytes)
                const pdfDoc = await PDFDocument.create();
                for (let i = 0; i < selected.length; i++) {
                    console.log(selected[i])
                    const [firstDonorPage] = await pdfDoc.copyPages(firstDonorPdfDoc, [selected[i] - 1])
                    pdfDoc.addPage(firstDonorPage)
                }
                const pdfBytes = await pdfDoc.save()
                console.log(pdfBytes)
        
                const blob = new Blob([pdfBytes], { type: "application/pdf" })
                // const file=new File([blob],fileName)
                blob.lastModifiedDate = new Date();
                blob.name = fileName
                console.log(blob)
                const blobUrl = URL.createObjectURL(blob)
                console.log(blobUrl)
                // window.open(blobUrl)
                const token = window.localStorage.getItem("token")
        
                dispatch(sendForAnalyzeSplitPdf(    
                    blob,
                    blobUrl,
                    token,
                    true,
                    id,
                    pages
                ))
                dispatch(updateSplitting(id));
                const img = document.createElement('img');
                img.src = blobUrl;
                document.body.appendChild(img)
            } catch{
                alert('This Pdf is Encrypted Please try to downlaod first and then Process it')
            }            
        }
    }

    const handleShowDialog = async () => {
        console.log("cliked");
        console.log(selected)
        try{
            const url1 = fileUrl
            const firstDonorPdfBytes = await fetch(url1).then(res => res.arrayBuffer())
            const firstDonorPdfDoc = await PDFDocument.load(firstDonorPdfBytes)
            const pdfDoc = await PDFDocument.create();
            const [firstDonorPage] = await pdfDoc.copyPages(firstDonorPdfDoc, [selected - 1])
            pdfDoc.addPage(firstDonorPage)
    
            const pdfBytes = await pdfDoc.save()
            console.log(pdfBytes)
    
            const blob = new Blob([pdfBytes], { type: "application/pdf" })
            blob.lastModifiedDate = new Date();
            blob.name = fileName
            const blobUrl = URL.createObjectURL(blob)
    
            let windowFeatures = "left=300,top=200,width=620,height=620";
            console.log(blobUrl)
            window.open(blobUrl,'mozillaWindow',windowFeatures)
        } catch{
            let windowFeatures = "left=300,top=200,width=620,height=620";
            window.open(fileUrl,'mozillaWindow',windowFeatures)
        }
    };
    const backSubmit = () => {
        history.push('/')
    }

    async function singleProcess() {
        try{
            setisSingleprocessed(true)
        const url1 = fileUrl
        const firstDonorPdfBytes = await fetch(url1).then(res => res.arrayBuffer())
        const firstDonorPdfDoc = await PDFDocument.load(firstDonorPdfBytes)
        for (let i = 0; i < selected.length; i++) {
            const pdfDoc = await PDFDocument.create();
            console.log(selected[i])
            const [firstDonorPage] = await pdfDoc.copyPages(firstDonorPdfDoc, [selected[i] - 1])
            pdfDoc.addPage(firstDonorPage)
            const pdfBytes = await pdfDoc.save()
            console.log(pdfBytes)
            const blob = new Blob([pdfBytes], { type: "application/pdf" })
            // const file=new File([sblob],fileName)
            blob.lastModifiedDate = new Date();
            blob.name = fileName
            console.log(blob)
            const blobUrl = URL.createObjectURL(blob)
            console.log(blobUrl)

            // window.open(blobUrl)
            const token = window.localStorage.getItem("token")
            dispatch(sendForAnalyzeSplitPdf(
                blob,
                blobUrl,
                token,
                true,
                id,
                pages
            ));
            dispatch(updateSplitting(id));
            const img = document.createElement('img');
            img.src = blobUrl;
            document.body.appendChild(img)
        }
        }  catch{
            alert('This Pdf is Encrypted Please try to downlaod first and then Process it')
        }

    }
    useEffect(() => {
        const timer = setInterval(() => {
          setProgress((prevProgress) =>
            prevProgress >= 90 ? 90 : prevProgress == 0 ? 0 : prevProgress + 10
          );
        }, 2000);
        return () => {
          clearInterval(timer);
        };
      }, []);
    useEffect(() => {
        const token = window.localStorage.getItem("token");
        const initData = async () => {
            await axios.get(process.env.REACT_APP_BACKEND_API + `api/invoice/splitlocalfile/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                })
                .then(res=>{
                    setData(res.data)
                })
        }
        initData();
    }, [id])

    const rData = data.invoice && data.invoice.splittedInvoices
    console.log(rData,'howl')
    
    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        nameProduct: ""
    });

    const handleDialog = (message, isLoading, nameProduct) => {
        setDialog({
            message,
            isLoading,
            nameProduct
        });
    };
    let rDataStatus = 0
    for(let i = 0; i < rData?.length; i++){
        if((data?.invoice && data?.invoice?.splittedInvoices && data?.invoice?.splittedInvoices[i] && data?.invoice?.splittedInvoices[i]?.status === 'Processed') || (data?.invoice && data?.invoice?.splittedInvoices && data?.invoice?.splittedInvoices[i] && data?.invoice?.splittedInvoices[i]?.status === 'Processing')) {
            rDataStatus++;
        }else{
        }
    }
    console.log(rDataStatus,'Processed And Processing Status');
    const areUSureProcess = async (choose) => {
        try{
            if (choose) {
                setisprocessed(true)
                const url1 = fileUrl
                const firstDonorPdfBytes = await fetch(url1).then(res => res.arrayBuffer())
                const firstDonorPdfDoc = await PDFDocument.load(firstDonorPdfBytes)
                const pdfDoc = await PDFDocument.create();
                for (let i = 0; i < selected.length; i++) {
                    console.log(selected[i])
                    const [firstDonorPage] = await pdfDoc.copyPages(firstDonorPdfDoc, [selected[i] - 1])
                    pdfDoc.addPage(firstDonorPage)
                }
                const pdfBytes = await pdfDoc.save()
                console.log(pdfBytes)
    
                const blob = new Blob([pdfBytes], { type: "application/pdf" })
                // const file=new File([blob],fileName)
                blob.lastModifiedDate = new Date();
                blob.name = fileName
                console.log(blob)
                const blobUrl = URL.createObjectURL(blob)
                console.log(blobUrl)
                // window.open(blobUrl)
                const token = window.localStorage.getItem("token")
    
                dispatch(sendForAnalyzeSplitPdf(    
                    blob,
                    blobUrl,
                    token,
                    true,
                    id,
                    pages
                ))
                dispatch(updateSplitting(id));
                const img = document.createElement('img');
                img.src = blobUrl;
                document.body.appendChild(img)
                    handleDialog("", false);
                } else {
                    handleDialog("", false);
                }
        } catch {
            alert('This Pdf is Encrypted Please try to downlaod first and then process it')
        }
    };

    useEffect(() => {
        if(status === 'Success'){
            window.location.reload(false);
        }
    },[status])

    const closeTab = () =>{
        window.close();
    }


    return (
        <>
            <div className="containerSplit">
                <div className="container">

                <div className='btn'>
                    <Button className="close-tab-Btn" variant="contained" color="primary" onClick={closeTab}>Close Tab</Button>
                    
                    <Button className="processBtn" variant="contained" color="primary"  onClick={copyPages}>{isprocessed?(status===undefined)?"Processing":status:"process"}
                    </Button>
                    <Button className="gobackBtn" variant="contained" color="primary" onClick={backSubmit}>Go Back</Button>
                    <Button className="single-file-btn" variant="contained" color="primary" onClick={singleProcess}>{isSingleprocessed?(status===undefined)?"Processing":status:"single file process"}</Button>
                    
                    </div>
                    <Document
                        file={url}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {Array.apply(null, Array(numPages))
                            .map((x, i) => i + 1)
                            .map((page, index) => {
                                // console.log(page)
                                return (<>
                                    <div className="imgcontainer">
                                        <Page pageNumber={page} onClick={handleShowDialog} />
                                        <input type="checkbox"
                                            className="checkbox1" id={page} onChange={handleChange} checked={page === 1 && (companyName=="CEAT" || companyName=="Ceat" || companyName =="SAPPHIRE" || companyName =="Sapphire") ? isChecked : checkboxState[page]}/>
                                    </div>
                                </>)
                            }
                            )}

                        {/* <Page pageNumber={pageNumber} /> */}
                    </Document>
                    <div style={{display: 'flex', position: 'obsolute', float: 'left', width: '100%', 'margin-top': '-111px', 'margin-left': '28px'}}>
                    <TableContainer style={{'box-shadow': 'none',}}>
                        <Table aria-label="simple table" size='small' className={classes.table} style={{width: '53%'}}>
                            <TableHead>
                            <TableRow className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>Pages</TableCell>
                                <TableCell className={classes.tableCell}>Last Process Date / Time</TableCell>
                                <TableCell className={classes.tableCell}>Status</TableCell>
                                <TableCell className={classes.tableCell}>Progress</TableCell>
                            </TableRow>
                            </TableHead>
                            {data.loading ? (
                                    <div className="spinerr">
                                        <CircularProgress />
                                    </div>
                                ) : null}
                                <TableBody>
                            {rData !== undefined ? (
                                rData.map((row, index) => {
                                    const timeStamp = row.lastProcessedDate
                                    const lastProcessedDate = moment(timeStamp).utc().local().format('DD-MM-YYYY hh:mm:ss');
                                    const finalTime = lastProcessedDate.split(' ').join('   /   ');
                                    var prevProgress = 0;
                                    if (row.status && row.status == "processed") {
                                      prevProgress = 100;
                                    }
                                    if (row.status && row.status == "Failed") {
                                      prevProgress = 0;
                                    }
                                    return (
                                        <TableRow className={classes.tableRow}>
                                            <TableCell className={classes.tableCell}>{row.pages || '0'}</TableCell>
                                            <TableCell className={classes.tableCell}>{finalTime || 'NA'}</TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {
                                                    row.status === "Processing" ? <Badge pill bg="primary">
                                                        {row.status}
                                                    </Badge> :
                                                    row.status === "Processed" ? <Badge pill bg="success" >{row.status}</Badge> :
                                                    row.status === "Failed" ? <Badge pill bg="danger">{row.status}</Badge> :
                                                    <Badge pill bg="light" text="dark">{row.status}</Badge>
                                                }
                                            </TableCell>
                                            <TableCell align="right">
                                              {row.status && row.status == "Failed" ? (
                                                <Box>
                                                  <LinearProgressWithLabel
                                                    color="secondary"
                                                    value={0}
                                                  />
                                                </Box>
                                              ) : 
                                              row.status && row.status == "Processed" ?
                                              (
                                                <Box>
                                                  <LinearProgressWithLabel
                                                    value={100}
                                                  />
                                                </Box>
                                              ):
                                              (
                                                <Box>
                                                  <LinearProgressWithLabel
                                                    value={
                                                      prevProgress  || progress
                                                    }
                                                  />
                                                </Box>
                                              )
                                            }
                                            </TableCell>
                                        </TableRow>
                                    );
                            })) : (<></>)}
                        </TableBody>
                        </Table>
                        </TableContainer>
                        </div>
                        {dialog.isLoading && (
        <Dialog
          onDialog={areUSureProcess}
          message={dialog.message}
        />
      )}
                </div>
            </div>
        </>
    );

}
const mapStateToProps = (state) => {
    return {
        invData: state.invoice,
    };
};
                                

const mapDispatchToProps = dispatch => {
    return {
        singleIdLocalFileDisplay: (id, state) => dispatch(singleIdLocalFileDisplay(id, state)),
        sentToAnalyze: (file, fileUrl, token, isFromSplit, splitRecordId, pages) =>
        dispatch(sendForAnalyzeSplitPdf(file, fileUrl, token, isFromSplit, splitRecordId, pages)),
        updateSplitting: (id, state) => dispatch(updateSplitting(id, state))
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Splitfile) 