import { updateUserProfile } from "../../redux/user/userAuthActions.js";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@material-ui/core/Box";
import NativeSelect from "@mui/material/NativeSelect";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { toast } from "react-toastify";
import { margin } from "@mui/system";

const EditForm = (props) => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (props.currentUser) {
      console.log("current user: ", props.currentUser.userId);
      setName(props.currentUser.userId);
      setRole(props.currentUser.role);
    }
  }, [props]);

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Password does not match");
    } else {
      setPassword(password);
      dispatch(updateUserProfile(props.currentUser._id, name, password, role));
      toast.success("Profile Updated");
    }
  };

  return props.trigger ? (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "  #f8f9fa",
      }}
    >
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          background: "white",
          padding: "20px",
          borderRadius: "10px",
          width: "60%",
        }}
      >
        {/* Go Back */}
        <div className="container text-center">
          <div className="row d-flex flex-column">
            <div className="col-md-6 mx-auto shadow p-5">
              <Avatar
                style={{
                  left: "46%",
                  background: "green",
                }}
              >
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Update Details
              </Typography>

              <form
                className="row  form-container my-3"
                onSubmit={submitHandler}
              >
                <div className="col-md-6-my-3">
                  <div className="form">
                    <label for="account-fn">New UserName</label>
                    <input
                      style={{ position: "relative", width: 250, left: "15%" }}
                      className="form-control "
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-md-6-my-3 mt-3">
                  <div className="form">
                    <label for="account-pass">New Password</label>
                    <input
                      style={{ position: "relative", width: 250, left: "15%" }}
                      className="form-control"
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      placeholder="Enter new password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6-my-3 mt-3">
                  <div className="form">
                    <label for="account-pass">confirmPassword</label>
                    <input
                      style={{ position: "relative", width: 250, left: "15%" }}
                      className="form-control"
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      placeholder="Enter new confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>

                <Box sx={{ minWidth: 120 }} className="my-5">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Role
                    </InputLabel>
                    <NativeSelect
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      inputProps={{
                        name: "Role",
                        id: "role",
                      }}
                    >
                      <option value="Reviewer">
                        Reviewer With Push Access
                      </option>
                      <option value="ReviewerW">
                        Reviewer Without Push Access
                      </option>
                      <option value="Viewer">Viewer</option>
                    </NativeSelect>
                  </FormControl>
                </Box>
                <div className="form-group d-flex align-items-center justify-content-between my-2">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => props.setTrigger(false)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
};

export default EditForm;