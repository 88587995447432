import { React, useEffect, useState, useRef } from 'react';
import Table from '@mui/material/Table';
import { Button } from "@material-ui/core";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { fetchReportsInvoice } from "../../redux";
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Box from '@mui/material/Box';
import CircularProgress from "@material-ui/core/CircularProgress";
import './reports.css'
const useStyles = makeStyles((theme) => ({
  reports: {
    display: "flex",
    margin: "10px 30px"
  },
}))

function Reports({ props, state }) {
  console.log(state)
  console.log(props);
  const roleApi = localStorage.getItem("UserRole");
  const classes = useStyles();
  const [rowdata, setRowdata] = useState([])
  const [fromDate, setFromDate] = useState('09/21/2021')
  const [toDate, setToDate] = useState(new Date())
  const dispatch = useDispatch()
  const history = useHistory()
  const invoiceData = state.invoice
  const repoData = invoiceData && invoiceData.reports
  // console.log(repoData)
  const tableRef = useRef(null);
  useEffect(async () => {
    const Reportsdata = await dispatch(fetchReportsInvoice())
    console.log(Reportsdata)
    setRowdata(Reportsdata)
    console.log(rowdata)
  }, [])
  const handleFromDate = (e) => {
    var myPastDate = new Date(e.target.value);
    myPastDate.setDate(myPastDate.getDate() - 1);
    setFromDate(myPastDate)
  }
  const min = Date.parse(fromDate);

  const max = Date.parse(toDate);

  const backBtn = () => {
    history.push("/analysing")
  }
  const today = new Date()
  const formDate = () => {

  }


  return (<>
    <div className={classes.reports}>

      <input type='date' style={{
        backgroundColor: 'white',
        padding: '3px 1px',
        borderRadius: '3px',
        border: '2px solid black',
        width: '140px',
        margin: '10px 10px',
        textTransform: 'uppercase',
        fontSize: '15px'
      }}
        onChange={handleFromDate}
      // onChange={e => setFromDate(e.target.value)}
      />
      <Typography style={{ margin: '10px' }} variant="h6" id="tableTitle" component="div" >
        To
      </Typography>
      {/* <TextField type="date" variant="outlined" size="small" onChange={e => setToDate(e.target.value)} /> */}
      <input type='date' style={{
        backgroundColor: 'white',
        padding: '3px 1px',
        borderRadius: '3px',
        border: '2px solid black',
        margin: '10px 10px',
        width: '140px',
        textTransform: 'uppercase',
        fontSize: '15px'
      }}

        onChange={e => setToDate(e.target.value)}
      />
      {/* <Button variant="contained" color="primary" style={{
        padding: '3px 1px',
        borderRadius: '3px',
        border: '2px solid black',
        width: '140px',
        margin: '10px 10px',
        textTransform: 'uppercase',
        fontSize: '15px'
      }}> Export excel </Button> */}


      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename="Sigixtract"
        sheet="tablexls"
        buttonText="Download as XLS" />

      {/* <Button variant="contained" className="goButton" color="primary" onClick={backBtn}>Back</Button>  */}
    </div>
    <TableContainer component={Paper}>

      <Table sx={{ minWidth: 650 }} aria-label="simple table" ref={tableRef} id="table-to-xls">
        <TableHead>
          <TableRow>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Email Invoice</TableCell>
            <TableCell align="center">Local Invoice</TableCell>
            <TableCell align="center">Processed Invoices</TableCell>
            {roleApi !=="Client"?<TableCell align="center">Processed Pages</TableCell>:null}
            {roleApi !=="Client"?<TableCell align="center">Processing Invoices</TableCell>:null}
            <TableCell align="center">Pushed Invoices</TableCell>
            <TableCell align="center">Pushed Pages</TableCell>
            {roleApi !=="Client"?<TableCell align="center">UN_Pushed Invoice</TableCell>:null}
            {roleApi !=="Client"?<TableCell align="center">Failed Invoice</TableCell>:null}
          </TableRow>
        </TableHead>
        {repoData <= 0 ? (
         <Box sx={{ display:'flex', position:'absolute', width:'90px', marginLeft:'50vw',marginTop:'10vh' }}>
            <CircularProgress color="secondary" size={ 70}/>
         </Box>
         ) : null}
        <TableBody>
          {repoData.filter(daterange => {
            const uploadedDate = moment(daterange.date).utc().local().format('MM/DD/YYYY');
            return (Date.parse(uploadedDate) >= min) && ((Date.parse(uploadedDate)) <= max)
          }).map((row) => {
            const timestamp = row.date;
            const uploadedtime = moment(timestamp).utc().local().format('DD-MM-YYYY');
            let unPushed = row.processedInvoices - row.pushedInvoices
            if(unPushed < 0){
              unPushed = 0;
            }
            let ProcessingInvoices = row.recievedLocalInvoice + row.recievedEmailInvoice - row.processedInvoices;
            if (ProcessingInvoices < 0){
              ProcessingInvoices = 0
            }
            return (<TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center">{uploadedtime}</TableCell>
              <TableCell align="center">{row.recievedEmailInvoice}</TableCell>
              <TableCell align="center">{row.recievedLocalInvoice}</TableCell>
              <TableCell align="center">{row.processedInvoices}</TableCell>
              {roleApi !=="Client"?<TableCell align="center">{row.numPages}</TableCell>:null}
              {roleApi !=="Client"?<TableCell align="center">{ProcessingInvoices}</TableCell>:null}
              <TableCell align="center">{row.pushedInvoices}</TableCell>
              <TableCell align="center">{row.pushedPages}</TableCell>
              {roleApi !=="Client"?<TableCell align="center">{unPushed}</TableCell>:null}
              {roleApi !=="Client"?<TableCell align="center"> </TableCell>:null}
            </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </>);
}

const mapDispatchToProps = dispatch => {
  return {
    fetchReportsInvoice: () => dispatch(fetchReportsInvoice()),
  };
};

const mapStateToProps = state => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
