import { React, useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { connect, useDispatch } from "react-redux";
import { UploadedFile } from "../../redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import { Button } from "@material-ui/core";
import CachedIcon from "@mui/icons-material/Cached";
import Badge from 'react-bootstrap/Badge';
import "./UploadStatus.css";

const useStyles = makeStyles((theme) => ({
  userdetails: {
    display: "flex",
    margin: "10px 30px",
  },
}));
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function UploadStatus({ props, state }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const rows =
    state &&
    state.invoice &&
    state.invoice.UploadedFiles &&
    state.invoice.UploadedFiles.files;
  const TotalCount =
    state &&
    state.invoice &&
    state.invoice.UploadedFiles &&
    state.invoice.UploadedFiles.Total != undefined
      ? state &&
        state.invoice &&
        state.invoice.UploadedFiles &&
        state.invoice.UploadedFiles.Total
      : 0;

  const [progress, setProgress] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    // dispatch(UploadedFile(page, rowsPerPage));
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 90 ? 90 : prevProgress == 0 ? 0 : prevProgress + 10
      );
    }, 2000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(async () => {
    dispatch(UploadedFile(page, rowsPerPage));
  }, [page, rowsPerPage , TotalCount]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const refresh = () => {
    dispatch(UploadedFile(page, rowsPerPage));
  };
  return (
    <>
      <ToastContainer />
      <Paper className={classes.paper}>
        <Button style={{ float: "right", marginTop: 10 }} onClick={refresh}>
          Refresh
          <CachedIcon />
        </Button>
        <TableContainer component={Paper} style={{ marginTop: 30 }}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            ref={tableRef}
            id="table-to-xls"
          >
            
            <TableHead style={{ backgroundColor: "#2196f3" }}>
              <TableRow>
                <TableCell style={{ color: "white" }} align="center">
                  Batch name
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  Upload Time
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  Completion Time
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  Status
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                 Overall Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

            {rows != undefined ? (
              rows.map((val, key) => {
                const timestamp = val.createdAt;
                const uploadedtime = moment(timestamp)
                  .utc()
                  .local()
                  .format("DD-MM-YYYY HH:mm:ss");
                const updatedtimestamp = val.updatedAt;
                const updatedTime = moment(updatedtimestamp)
                  .utc()
                  .local()
                  .format("DD-MM-YYYY HH:mm:ss");
                var prevProgress = 0;
                if (val.status && val.status == "processed") {
                  prevProgress = 100;
                }
                if (val.status && val.status == "Failed") {
                  prevProgress = 0;
                }
                return (
                  <TableRow key={key}>
                    <TableCell align="center">{val.Batch_name}</TableCell>
                    <TableCell align="center">{uploadedtime}</TableCell>
                    <TableCell align="center">{updatedTime}</TableCell>
                    <TableCell align="right">
                      {val.status && val.status == "Failed" ? (
                        <Box>
                          <LinearProgressWithLabel
                            color="secondary"
                            value={0}
                          />
                        </Box>
                      ) : (
                        <Box>
                          <LinearProgressWithLabel
                            value={
                              prevProgress  || progress
                            }
                          />
                        </Box>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {
                        val.status === "Failed" ? <Badge pill bg="danger" >Failed</Badge> :
                        val.status === "processing" ? <Badge pill bg="primary">Processing</Badge> :
                       <Badge pill bg="success">Processed</Badge>
                      }</TableCell>

                  </TableRow>
                );
              })
            ) : (
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  width: "90px",
                  marginLeft: "50vw",
                  marginTop: "10vh",
                }}
              >
                <CircularProgress color="secondary" size={70} />
              </Box>
            )}
            </TableBody>

          </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 300]}
          component="div"
          count={TotalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </TableContainer>

      </Paper>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(UploadStatus);
