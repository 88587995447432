import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './EmailTableHeader.css';
import Emailinvoice from '../EmailInvoiceTable/EmailInvoiceTable';
import { connect, useDispatch } from 'react-redux';
import { emailinvoicecount,LocalInvoiceCount } from "../../redux";
import Localinvoice from '../LocalInvoiceTable/localInvoicetable'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mr: -1.5 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Tableheader({ state }) {
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();

    // useEffect(async () => {
    //     dispatch(emailinvoicecount())
    // }, [])

    // useEffect(async () => {
    //     dispatch(LocalInvoiceCount())
    // }, [])

    

    const invoiceData = state.invoice
    const rdatalen = invoiceData && invoiceData.emailInvoiceCount
    const ldatalen=invoiceData && invoiceData.LocalInvoiceCount

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Show All" {...a11yProps(0)} value={value}
                        component={() => (
                            <Button style={{ color: 'black' }} onClick={() => setValue(0)}>
                                <span class="buttontext"> Email<span class="ellipse">{rdatalen}</span></span>
                            </Button>
                        )} />
                    <Tab label="Approved" component={() => (
                        <Button style={{ color: 'black' }} onClick={() => setValue(1)}>
                            <span class="buttontext"> Local<span class="ellipse">{ldatalen}</span></span>
                        </Button>
                    )} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Emailinvoice />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Localinvoice/>
            </TabPanel>
            <TabPanel value={value} index={2}>
            </TabPanel>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        state: state,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        emailinvoicecount:()=>dispatch(emailinvoicecount()),
        LocalInvoiceCount:()=>dispatch(LocalInvoiceCount())
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tableheader)