/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */
 
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import HomeContainer from '../../modules/HomeModules/Home';
import Analyzing from '../../modules/AnalyzedResults/IsAnalyzing/Analyzing';
import Error from '../../modules/AnalyzedResults/AnalyzingError/AnalyzingError';
import Results from '../../modules/AnalyzedResults/Results/Results'

function IsAnalyzing(props) {
  if (props.file === "") {
     return <Redirect to="/"></Redirect>;;
  } else if(props.error !== ""){
    return <Error></Error>
  }
  else if (props.file !== "" && props.dataF === "") {
    return <Analyzing></Analyzing>;
  } else {
    return <Results></Results>;
  }
 
}


function AnalyzeResults(props){
    // return <HomeContainer></HomeContainer>;
    
    return (
      <IsAnalyzing
        file={props.state.invoice.fileUrl}
        dataF={props.state.invoice.data}
        error={props.state.invoice.error}
      ></IsAnalyzing>
    );
  
}


const mapDispatchToProps = (dispatch) => {
    return {};
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyzeResults);


