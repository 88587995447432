/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */
 
import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  USER_LOGIN,
  USER_LOGOUT,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAILURE,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE
} from "./userAuthTypes";

const initialState = {
  validating: false,
  authError: false,
  token: "",
  data:[],
  users:[]
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        validating: true,
      };
    case USER_LOGOUT:
      return {
        ...state,
        token: "",
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        validating: false,
        token: action.token,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        validating: false,
        authError: true,
      };
      case REGISTRATION_SUCCESS:
      return {
        ...state,
        validating: false,
        data: action.data,
      };
    case REGISTRATION_FAILURE:
      return {
        ...state,
        validating: false,
        authError: true,
      };
      case FETCH_USERS_SUCCESS:
        return {
          ...state,
          validating: false,
          users: action.users,
        };
        case FETCH_USERS_FAILURE:
      return {
        ...state,
        validating: false,
        authError: true,
      };
    default:
      return state;
  }
};
