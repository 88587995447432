import React, { useState, useEffect } from 'react';
import { singleIdLocalFileDisplay, sendForAnalyze, updateSplitting, sendForAnalyzeSplitPdf } from "../../redux"
import { connect, useDispatch } from 'react-redux'
import { useParams, useHistory } from "react-router-dom";
import './imageDisplay.css';
import { Button } from "@material-ui/core";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from 'axios';
import moment from "moment";
import Badge from 'react-bootstrap/Badge';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from "../Dialog/dialog";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const useStyles = makeStyles({
    tableRow: {
        height: 10
    },
    tableCell: {
        padding: "0px 10px"
        }
    });

    function LinearProgressWithLabel(props) {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                props.value
              )}%`}</Typography>
            </Box>
          </Box>
        );
      }
      
      LinearProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired,
      };

function ImageDisplay({ invData, singleIdLocalFileDisplay }) {
    const classes = useStyles();
    const [numPages, setNumPages] = useState(null);
    const [selected, setSelected] = useState([]);
    const [isprocessed,setisprocessed]=useState(false)
    const [progress, setProgress] = useState(10);
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    console.log(invData)
    const status=invData && invData.data && invData.data.data && invData.data.data.status
    console.log(status,'Hello Kitten')
    const rData = invData && invData.localfileId && invData.localfileId.invoice && invData.localfileId.invoice.splittedInvoices
    console.log(rData,'Splitted Invoice')

    useEffect(() => {
        const timer = setInterval(() => {
          setProgress((prevProgress) =>
            prevProgress >= 90 ? 90 : prevProgress == 0 ? 0 : prevProgress + 10
          );
        }, 2000);
        return () => {
          clearInterval(timer);
        };
      }, []);
    useEffect(() => {
        singleIdLocalFileDisplay(id)
    }, [id])

    const fileUrl = invData && invData.localfileId && invData.localfileId.fileUrl
    console.log(fileUrl)
    const fileName = invData && invData.localfileId && invData.localfileId.invoice && invData.localfileId.invoice.file_Name
    console.log(fileName)

    
    const backSubmit = () => {
        history.push('/')
    }
    
    //For Dialouge Box
    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        nameProduct: ""
    });

    const handleDialog = (message, isLoading, nameProduct) => {
        setDialog({
            message,
            isLoading,
            nameProduct
        });
    };

    let rDataStatus = 0
    for(let i = 0; i < rData?.length; i++){
        if((rData[i].status === 'Processing') || (rData[i].status === "Processed")){
            rDataStatus++
        }
    }
    console.log(rDataStatus,'Processed And Processing Status');

    

    async function process() {
        if(rDataStatus > 0){
			handleDialog("Already Processed, Do you want to Process again?", true);
        }else{
            setisprocessed(true)
            const token = window.localStorage.getItem("token")
            let blob=await fetch(fileUrl).then(r=>r.blob()).then(blobFile=>new File([blobFile],fileName,{type:"image/png"}));
            let pages = 1
            console.log(blob)
            dispatch(sendForAnalyzeSplitPdf(
                blob,
                fileUrl,
                token,
                true,
                id,
                pages
            ));
            dispatch(updateSplitting(id));
        }
    }

    const areUSureProcess = async (choose) => {
        if (choose) {
            setisprocessed(true)
            const token = window.localStorage.getItem("token")
            let blob=await fetch(fileUrl).then(r=>r.blob()).then(blobFile=>new File([blobFile],fileName,{type:"image/png"}));
            let pages = 1
            console.log(blob)
            const blobUrl = URL.createObjectURL(blob)
            dispatch(sendForAnalyzeSplitPdf(
                blob,
                fileUrl,
                token,
                true,
                id,
                pages
            ));
            dispatch(updateSplitting(id));
        const img = document.createElement('img');
        img.src = blobUrl;
        document.body.appendChild(img)
			handleDialog("", false);
        } else {
			handleDialog("", false);
        }
    };

    const handleShowDialog=()=>{
        window.open(fileUrl)
    }
    
    useEffect(() => {
        if(status === 'Success'){
            window.location.reload(false);
        }
    },[status])
    
    const closeTab = () =>{
        window.close();
    }

    return (
        <>
            <div className="containerimage">
            <div className="container">
                <div className='btn'>
                    <Button className="close-tab-Btn" variant="contained" color="primary" onClick={closeTab}>Close Tab</Button>

                    <Button className="processBtn" variant="contained" color="primary" onClick={process}>{isprocessed?(status===undefined)?"Processing":status:"process"}
                    </Button>
                    <Button className="gobackBtn" variant="contained" color="primary" onClick={backSubmit}>Go Back</Button>
                </div>
                <div className="img1container">
                    <img className='imagedisplay' width="200" height="200" src={fileUrl} onClick={handleShowDialog} alt=""/>
                    <input type="checkbox" className="checkbox1" /> 
                </div>
                <div style={{display: 'flex', position: 'obsolute', float: 'left', width: '100%', 'margin-top': '-111px', 'margin-left': '28px'}}>
                <TableContainer style={{'box-shadow': 'none',}}>
                        <Table aria-label="simple table" size='small' className={classes.table} style={{width: '53%'}}>
                            <TableHead>
                            <TableRow className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>Pages</TableCell>
                                <TableCell className={classes.tableCell}>Last Process Date / Time</TableCell>
                                <TableCell className={classes.tableCell}>Status</TableCell>
                                <TableCell className={classes.tableCell}>Progress</TableCell>
                            </TableRow>
                            </TableHead>
                            {invData.loading ? (
                                    <div className="spinerr">
                                        <CircularProgress />
                                    </div>
                                ) : null}
                                <TableBody>
                            {rData !== undefined ? (
                                rData.map((row, index) => {
                                    const timeStamp = row.lastProcessedDate
                                    const lastProcessedDate = moment(timeStamp).utc().local().format('DD-MM-YYYY hh:mm:ss');
                                    const finalTime = lastProcessedDate.split(' ').join('   /   ');
                                    var prevProgress = 0;
                                    if (row.status && row.status == "processed") {
                                      prevProgress = 100;
                                    }
                                    if (row.status && row.status == "Failed") {
                                      prevProgress = 0;
                                    }
                                    return (
                                        <TableRow className={classes.tableRow}>
                                            <TableCell className={classes.tableCell}>{row.pages || '0'}</TableCell>
                                            <TableCell className={classes.tableCell}>{finalTime || 'NA'}</TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {
                                                    row.status === "Processing" ? <Badge pill bg="primary">
                                                        {row.status}
                                                    </Badge> :
                                                    row.status === "Processed" ? <Badge pill bg="success" >{row.status}</Badge> :
                                                    row.status === "Failed" ? <Badge pill bg="danger">{row.status}</Badge> :
                                                    <Badge pill bg="light" text="dark">{row.status}</Badge>
                                                }
                                            </TableCell>
                                            <TableCell align="right">
                                              {row.status && row.status == "Failed" ? (
                                                  <Box>
                                                  <LinearProgressWithLabel
                                                    color="secondary"
                                                    value={0}
                                                  />
                                                </Box>
                                              ) : 
                                              row.status && row.status == "Processed" ?
                                              (
                                                  <Box>
                                                  <LinearProgressWithLabel
                                                    value={100}
                                                  />
                                                </Box>
                                              ):
                                              (
                                                <Box>
                                                  <LinearProgressWithLabel
                                                    value={
                                                      prevProgress  || progress
                                                    }
                                                  />
                                                </Box>
                                              )
                                            }
                                            </TableCell>
                                        </TableRow>
                                    );
                            })) : (<></>)}
                        </TableBody>
                        </Table>
                        </TableContainer>

                </div>
                {dialog.isLoading && (
        <Dialog
          onDialog={areUSureProcess}
          message={dialog.message}
        />
      )}
            </div>
            </div>
        </>
    );

}
const mapStateToProps = (state) => {
    return {
        invData: state.invoice,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        singleIdLocalFileDisplay: (id, state) => dispatch(singleIdLocalFileDisplay(id, state)),
        sentToAnalyze: (file, fileUrl, token, isFromSplit, splitRecordId, pages) =>
        dispatch(sendForAnalyzeSplitPdf(file, fileUrl, token, isFromSplit, splitRecordId, pages)),
        updateSplitting: (id, state) => dispatch(updateSplitting(id, state))
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(ImageDisplay) 