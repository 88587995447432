/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */

import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import Dialog from "../../../components/Dialog/dialog";
import '../../../modules/AnalyzedResults/FileDisplay/FileDisplay.css';

import {
	sendForAnalyze,
	upload_invoice,
	updateDisApproval,
	fetchsingleInvoice,
	PushInvoice,
	userUpdated,
	approve_invoice,
	updateInvoice,
	updateApproval,
	change_field_value,
	updatePush,
} from '../../../redux';
import LineItems from '../../../components/ResultDisplay/ResultDisplay';
import ResultsContent from '../../../components/ResultDisplay/ResultDisplay';
import { push_invoice_success  } from '../../../redux/invoice/invoiceActions'
import Edit from '../../../components/Edit/edit';
import './Results.css';
import { css } from 'glamor';
import FileDisplay from '../../../modules/AnalyzedResults/FileDisplay/FileDisplay';
import { ContactSupportOutlined } from '@material-ui/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
//const jwt_decode = require("jwt-decode");

function HomeContainer({ invData, fetchsingleInvoice }) {
	console.log('Results Page');
	// console.log(state)
	const [is_approved, setApproved] = useState(false);
	const [isPushed, setisPushed] = useState(false);
	const [role, setRole] = useState('');
	const [disable, setDisable] = useState(false);
	const history = useHistory();
	const currentRoute = useHistory().location.pathname.toLowerCase();
	let companyname = localStorage.getItem("companyName");

	// console.log("currentRoute", currentRoute);
	const fileHandler = e => {
		const reader = new FileReader();
		reader.readAsDataURL(e.target.files[0]);
		reader.onload = () => {
			if (reader.readyState === 2) {
				invData.upload(reader.result, e.target.files[0]);
				invData.sentToAnalyze(e.target.files[0], reader.result);
			}
		};
		history.push('/');
	};
	const { id } = useParams();
	const dispatch = useDispatch();
	// console.log(id)
	useEffect(() => {
		fetchsingleInvoice(id);
	}, [id]);

	useEffect(() => {
		setRole(localStorage.getItem('UserRole'));
	}, []);
	const invoiceImages = invData && invData.invdata && invData.invdata.fileUrl;

	// console.log(invData.is_approved)
	// var isApproved = invData.is_approved;
	// console.log(isApproved)
	const tApproved =
		invData &&
		invData.invdata &&
		invData.invdata.invoice &&
		invData.invdata.invoice.is_approved;
	console.log(tApproved);
	const [update, setUpdate] = useState(false)
	// console.log(isApproved)
	const updatedinvoice = invData && invData.invdata && invData.invdata.invoice;
	console.log(updatedinvoice, 'Updated Invoice');
	const pushedStatus = invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.is_pushed;
	console.log(pushedStatus,'Pushed Status'); 
	const pushedData = invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.push_data;
	console.log(pushedData,"pushedData");
	const message=invData && invData.PushData && invData.PushData.message
	const pushsuccess= invData && invData.PushData && invData.PushData.success
	console.log(pushsuccess, "Hello There");

	if(pushedData){
		var newpush = pushedData.some(e => e.status === "Pushed");
		console.log(newpush,"newpush");
	}
	const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        nameProduct: ""
    });

	const handleDialog = (message, isLoading, nameProduct) => {
        setDialog({
          message,
          isLoading,
          nameProduct
        });
      };

	const handlePush = e => {
		e.preventDefault();
		var pushdata =
			invData &&
			invData.invdata &&
			invData.invdata.invoice &&
			invData.invdata.invoice.extractedData;
		console.log(pushdata);
		if(pushedStatus === 'Pushed'){
			handleDialog("Already Pushed, Do you want to Push again?", true);
		}else{
			// dispatch(PushInvoice(id, pushdata));
			PushInvoiceHandler(id, pushdata)
		}
		setDisable(true)
	};

	const PushInvoiceHandler = async(id,pushdata) => {
			const token = window.localStorage.getItem("token");
            let companyname = localStorage.getItem("companyName");

			const pushDataMadrich = {}
			if(companyname =='Medreich'){
				//console.log("if is working")
				for (const key in pushdata) {
					const newKey = key.replace(/_/g, '');
					if (typeof pushdata[key] === "number") {
						pushDataMadrich[newKey] = pushdata[key].toString();
					}else {
					 pushDataMadrich[newKey] = pushdata[key];
					}
				  }
				 // console.log(pushDataMadrich)
				  pushdata=pushDataMadrich
				//Object.assign(pushdata, pushDataMadrich);
			}
			const t = toast.loading("Pushing Please wait...")
			//console.log(pushdata);
				await axios.post(process.env.REACT_APP_BACKEND_API + `api/invoice/pushInvoice/${id}`,pushdata, {
							headers: {
							  Authorization: `Bearer ${token}`,
							},
						  }).then(response=> {
							dispatch(push_invoice_success(response.data))
							toast.update(t, {render: "Pushed Successfully!", type: "success", isLoading: false,autoClose: 5000,closeOnClick: true,});
				})
				.catch(error=>{
					//toast.error("Push invoice unsuccessfull")
					toast.update(t, {render: "Push invoice unsuccessful", type: "error", isLoading: false ,autoClose: 5000,closeOnClick: true,theme: "light"})
				})

		}
	const areUSurePush = (choose) => {
		var pushdata =
		invData &&
		invData.invdata &&
		invData.invdata.invoice &&
		invData.invdata.invoice.extractedData;
        if (choose) {
			PushInvoiceHandler(id, pushdata)
			handleDialog("", false);
        } else {
			handleDialog("", false);
        }
    };
	const extractedData = invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.extractedData
	const sourceFile= invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.sourceFile


		// console.log(extractedData, 'Download Json');
	var data =
			'text/json;charset=utf-8,' +
			encodeURIComponent(
				JSON.stringify(
					extractedData
				)
			);
		// console.log(invData.invdata.invoice.data)
	var downl = 'data:' + data;

	var dFileName =
		invData &&
		invData.invdata &&
		invData.invdata.invoice &&
		invData.invdata.invoice.file_Name &&
		invData.invdata.invoice.file_Name[0].split('.')[0] + ' - SigiXtract.json';
		
	var invdate = invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.extractedData && invData.invdata.invoice.extractedData.InvoiceDate
	// var lrDate = invData && invData.invdata && invData.invdata.invoice && invData.invdata.invoice.extractedData && invData.invdata.invoice.extractedData.LRDate	


	const updateInvoice=(id,data)=>{
		const token = window.localStorage.getItem("token");
		// return (dispatch)=>{
		const t = toast.loading("Updating Please wait...")
		  axios.put(process.env.REACT_APP_BACKEND_API + `api/invoice/update/${id}`,data, {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  })
		  .then(res=>{
			console.log(res.data)
			toast.update(t, {render: "Updated Successfully!", type: "success", isLoading: false,autoClose: 5000,closeOnClick: true,});
			dispatch(userUpdated(res.data))
		  })
		  .catch(error=>{
			toast.update(t, {render: "Update unsuccessful", type: "error", isLoading: false ,autoClose: 5000,closeOnClick: true,theme: "light"});
			console.log(error)
		  })
		//}
	  }
	const handleSubmit = e => {
		e.preventDefault();
		
		let invDateError=invdate.toString().length
			if(invDateError!==10){
				toast.error("update Invoice Date to dd-mm-yyyy format")
			}
			else{
				updateInvoice(id, updatedinvoice);
			}
		setUpdate(true);
	};

	const updateApproval=(id,data)=>{
		console.log("msg 239",id)
		const token = window.localStorage.getItem("token");
		//return (dispatch)=>{
		  const t = toast.loading("Approving Please wait...")
		  axios.put( process.env.REACT_APP_BACKEND_API + `api/invoice/updateapproved/${id}`,data, {
			headers: {
			  Authorization: `Bearer ${token}`,
			},

		  })
		  .then(res=>{
			//console.log(res.data)
			dispatch(approve_invoice(res.data))
			fetchsingleInvoice(id)
			toast.update(t, {render: "Approved Successfully!", type: "success", isLoading: false,autoClose: 3000,closeOnClick: true,});
		  })
		  .catch(error=>{
			toast.update(t, {render: "Approval unsuccessful", type: "error", isLoading: false ,autoClose: 5000,closeOnClick: true,theme: "light"});
			console.log(error)
		  })
		//}
	  }

	const ApprovedSubmit = () => {
		updateApproval(id);
	};
	const UnlockSubmit = () => {
		dispatch(updateDisApproval(id));
	};
	const backSubmit = () => {
		//history.push('/')
		window.close();
	};

	console.log(role);
	return (
		<>
			<div class="containerResults">
				<FileDisplay data={invoiceImages} Id={sourceFile}></FileDisplay>
				<div class="formDetails">
					<div id="itemTable">
						<ResultsContent></ResultsContent>
					</div>
					{
						(role === "Viewer") || role === 'Client' ? 
						<>{(companyname === "CEAT" || companyname === "Ceat")?<>
						{
						tApproved ? (
							<div id="buttonRow">
								{/* <Button id="downloadBtn" variant="contained" color="secondary">
									<a href={downl} id="downloadLink" download={dFileName}>
										Download JSON
									</a>
								</Button> */}
								<Button
									id="pushDataBtn"
									onClick={handlePush}
									disabled={role === 'ReviewerW' ? 'disabled' : (role === 'Reviewer' && newpush === true ) ? 'disabled' : (role === 'Reviewer' || role === 'Admin') ? disable : '' }
									variant="contained"
									color="secondary"
								>
									Push invoice
									<ToastContainer />
								</Button>
								{/* <Button
								style={{marginLeft:"2px",backgroundColor:"red"}}
								id="pushDataBtn"
								onClick={UnlockSubmit}
								variant="contained"
								color="primary"
								disabled={!tApproved ? 'disabled' : ''}
							>
								Unlock
							</Button> */}
							<Button
								style={{marginLeft:"8px",backgroundColor:"blue"}}
								id="pushDataBtn"
								onClick={backSubmit}
								variant="contained"
								color="secondary"
								disabled={!tApproved ? 'disabled' : ''}
							>
								Close Page
							</Button>
							{/* <Button id="pushDataBtn"  onClick={backSubmit}>
							Close Page
						</Button> */}
							</div>
						) : (
							<div id="buttonRow">
								<Button
									id="approveBtn"
									onClick={handleSubmit}
									variant="contained"
									color="secondary"
								>
									Update
									<ToastContainer />
								</Button>
								<Button
									id="approveBtn"
									onClick={ApprovedSubmit}
									variant="contained"
									color="secondary"
									disabled={!update ? 'disabled' : ''}
								>
									Approve
								</Button>
								<Button
									id="approveBtn"
									style={{marginLeft:"8px",backgroundColor:"blue"}}
									onClick={backSubmit}
									variant="contained"
									color="secondary"
									// disabled={!update ? 'disabled' : ''}
								>
									Close Page
								</Button>
							</div>
						)
						}
						</>:null}</> :
						<>
						{
						(role === 'Admin' || 'Reviewer' || 'ReviewerW') && tApproved ? (
							<div id="buttonRow">
								<Button id="downloadBtn" variant="contained" color="secondary">
									<a href={downl} id="downloadLink" download={dFileName}>
										Download JSON
									</a>
								</Button>
								<Button
									id="pushDataBtn"
									onClick={handlePush}
									disabled={role === 'ReviewerW' ? 'disabled' : (role === 'Reviewer' && newpush === true ) ? 'disabled':  (role === 'Reviewer' || role === 'Admin') ? disable : '' }
									variant="contained"
									color="secondary"
								>
									Push invoice
									<ToastContainer />
								</Button>
							</div>
						) : (
							<div id="buttonRow">
								<Button
									id="approveBtn"
									onClick={handleSubmit}
									variant="contained"
									color="secondary"
								>
									Update
									<ToastContainer />
								</Button>
								<Button
									id="approveBtn"
									onClick={ApprovedSubmit}
									variant="contained"
									color="secondary"
									disabled={!update ? 'disabled' : ''}
								>
									Approve Invoice
								</Button>
							</div>
						)
						}
						</>

					}
				
					<div id=" buttonRow">
					{(role !== "reviewer" && !(role === "Client" && (companyname === "CEAT" || companyname === "Ceat"))) ? <Button  className={role === 'Client' ? 'close-btn' : 'gobackbtn'} onClick={backSubmit}>
							Close Page
						</Button>:null
					}
						{role === 'Viewer' || role === 'Client' ? 
							companyname=="CEAT" || companyname=="Ceat"?<Button
							id="UnlockBtn"
							onClick={UnlockSubmit}
							variant="contained"
							color="secondary"
							disabled={!tApproved ? 'disabled' : ''}
						>
							Unlock
						</Button>:null
						: <Button
								id="UnlockBtn"
								onClick={UnlockSubmit}
								variant="contained"
								color="secondary"
								disabled={!tApproved ? 'disabled' : ''}
							>
								Unlock
							</Button>
						}
					</div>
				</div>
				{dialog.isLoading && (
        <Dialog
          onDialog={areUSurePush}
          message={dialog.message}
        />
      )}
			</div>
		</>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		sentToAnalyze: (file, fileUrl, state) =>
			dispatch(sendForAnalyze(file, fileUrl, state)),
		upload: (file, fileUrl, state) =>
			dispatch(upload_invoice(file, fileUrl, state)),
		// approve : () => dispatch(approve_invoice()),
		fetchsingleInvoice: id => dispatch(fetchsingleInvoice(id)),
		updateInvoice: (id, state) => dispatch(updateInvoice(id, state)),
		updateApproval: (id, state) => dispatch(updateApproval(id, state)),
		PushInvoice: state => dispatch(PushInvoice(state)),
	};
};

const mapStateToProps = state => {
	return {
		invData: state.invoice,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
// export default HomeContainer
