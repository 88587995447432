import React, { Component } from "react";
import { connect } from "react-redux";
import Forgotpassword from "../../components/Forgotpassword/forgotpassword";

function forgetpassword() {
 
    return(
    <Forgotpassword></Forgotpassword>
    )
  
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(forgetpassword);