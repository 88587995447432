/**
 * Copyrights © 2021 Sigitek
 * All Rights Reserved
 *
 * NOTICE:
 * All the information contained here are property of Sigitek
 * The intellectual and technical concepts contained here in are proprietary to Sigitek
 * Dissemination of this information, reproduction of this material and copying or distribution of this software
 * is strictly forbidden unless prior written perssion is obtained from Sigitek
 *
 * */

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import Home from "../pages/Home/HomePage";
import Navbar from "../components/Common/NavBar/NavBar";
import Footer from "../components/Common/Footer/Footer";
import Results from "../pages/AnalyzeResults/AnalyzeResults";
import Login from "../pages/Signin/Singin";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { autoLogin } from "../redux/user/userAuthActions";
import { getAutoModelType } from "../redux/invoice/invoiceActions";
import { connect } from "react-redux";
import forgotpassword from "../pages/Forgotpassword/forgetpassword";
import HomeContainer from "../modules/AnalyzedResults/Results/Results";
import splitFileDisplay from "../components/SplitFiledisplay/splitFileDisplay";
import EmailHeader from "../components/EmailHeader/EmailHeader";
import SplitLocalfile from "../components/splitPdfLocalfile/splitpdflocalfile";
import RegisterScreen from "../pages/Register/register";
import GetUserslist from "../components/Usersdisplay/usersdisplay";
import UserManagement from "../components/UserManagement/userManagement";
import AllocateInvoice from "../components/AllocateInvoice/AllocateInvoice"
import ImageDisplay from "../components/ImageDisplay/imageDisplay";
import Setting from "../components/Settings/Setting";

function AppRoutes(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loggedIn, setLoggedIn] = useState(null);
const companyName = localStorage.getItem("companyName");

  useEffect(() => {
    autoLogin(dispatch, history);
    getAutoModelType(dispatch);
    const loggedUser = localStorage.getItem("token");
    setLoggedIn(Boolean(loggedUser));
  }, [dispatch, history]);

  let routes = (
    <Switch>
      <Route path="/login" component={Login} exact />
      <Route path="/forgotpassword" component={forgotpassword} exact />
      <Redirect to="/login" />
    </Switch>
  );

  const userrole = localStorage.getItem("UserRole");

  if (loggedIn) {
    if (userrole === "Viewer") {
      routes = (
        <Switch>
          <Route path="/pageview/:id" component={HomeContainer} />
          <Route path="/login" component={Login} exact />
          <Route path="/" component={HomeContainer} />
        </Switch>
      );
    }else if (userrole === "Admin" ){
      routes = (
        <Switch>
          <Route path="/analysing" component={Results} />
          <Route path="/results/:id" component={HomeContainer} />
          <Route path="/display/:id" component={splitFileDisplay} />
          <Route path="/splitpdf" component={EmailHeader} title="Split Page" exact="true"/>
          <Route path="/splitlocalpdf/:id" component={SplitLocalfile} title="Processing Page" exact="true"/>
          <Route path="/register" component={RegisterScreen} exact />
          <Route path="/getusers" component={GetUserslist} />
          <Route path="/usermanagement" component={UserManagement} />
          <Route path="/Settings" component={Setting} />
          <Route path="/imageProcessing/:id" component={ImageDisplay} />
          <Route path="/login" component={Login} exact />
          {companyName === "TestDevelopers" || companyName === "CEAT" ? <Route path="/AllocateInvoice" component={AllocateInvoice} />: <Route path="/AllocateInvoice"  />}
          <Route path="/" component={Home} title="Home Page" />
        </Switch>
      );
    }else {
      routes = (
        <Switch>
          <Route path="/analysing" component={Results} />
          <Route path="/results/:id" component={HomeContainer} />
          <Route path="/display/:id" component={splitFileDisplay} />
          <Route path="/splitpdf" component={EmailHeader} title="Split Page" exact="true"/>
          <Route path="/splitlocalpdf/:id" component={SplitLocalfile} title="Processing Page" exact="true"/>
          <Route path="/register" component={RegisterScreen} exact />
          <Route path="/getusers" component={GetUserslist} />
          <Route path="/imageProcessing/:id" component={ImageDisplay} />
          <Route path="/login" component={Login} exact />
          <Route path="/" component={Home} title="Home Page" />
        </Switch>
      );
    }
  }

  return (
    <div>
      <Navbar />
      <div>{routes}</div>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default withRouter(connect(mapStateToProps)(AppRoutes));
